import {
    BdAlert,
    BdAlertType,
    BdButton,
    BdButtonType,
    BdCol,
    BdGrid,
    BdRow, useBdModal
} from "bundledocs.common.client/modules/bd-components";
import { BdIconDisappointed, BdIconIoList } from "bundledocs.common.client/modules/bd-graphics";
import { BdMargin } from "bundledocs.common.client/modules/bd-style";
import React, { useEffect, useState } from "react";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdAccountNumberBean } from "../../../models/providers/bean/bd-account-number-bean/bd-enterprise-license-bean";
import BdEnterpriseLicenseSearchList from "./bd-account-numbers-search-list";
import { BdAccountNumberCreateModal } from "../bd-account-numbers-actions/bd-account-numbers-create";

export type BdAccountNumbersSearchProps = React.HTMLAttributes<HTMLElement>

//object interface used to manage the alert information
interface AlertData {
    type: BdAlertType,
    show: boolean,
    message: string
}

/**
 * Component that allows users to find and view all accounts in a table
 * @param param0
 */
export default function BdAccountNumbersSearch({
    ...otherProps
}: BdAccountNumbersSearchProps): JSX.Element {
    const _bundleDocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();
    const [btnSearchWorking, setBtnSearchWorking] = useState<boolean>(true);
    const [accountNumbers, setAccountNumbers] = useState<BdAccountNumberBean[]>([]);

    // we use it in create account modal
    const defaultBdAccountNumberBean: BdAccountNumberBean = {
        AccountNumber: "",
        SubscriptionType: 0,
        SubscriptionCaseLimit: 0,
        SubscriptionUserLimit: 0,
        Name: "",
        Company: "",
        Email: "",
        Notes: "",

        IsActive: true,
        PartitionKey: "",
        RowKey: "",
    };

    // display the account by default rendering
    useEffect(() => {
        _handleSearch();
    }, []);

    const [alertData, setAlertData] = useState<AlertData>({
        type: BdAlertType.Warning,
        show: false,
        message: ""
    });

    //show the alert
    const _openAlert = (type: BdAlertType, message: string) => {
        setAlertData({
            ...alertData,
            type: type,
            message: message,
            show: true
        });
    };

    //hide the alert
    const _closeAlert = () => {
        setAlertData({
            ...alertData,
            show: false
        });
    };

    //executes the search
    const _handleSearch = async () => {
        try {
            _closeAlert();

            const result: BdAccountNumberBean[] = await _bundleDocsApiInstance.AccountNumbers.GetAll();

            // need to clean old data before assigning the data
            setAccountNumbers([]);
            setAccountNumbers(result);

            if (!result[0]) {
                _openAlert(BdAlertType.Warning, "No accounts found.");
            }
        }
        catch (error: any) {
            setAccountNumbers([]);
            _openAlert(BdAlertType.Danger, error.message);
        }

        setBtnSearchWorking(false);
    };

    //modal managers
    const accountNumberCreateModal = useBdModal();

    const _handleCreateAccountNumber = (): void => {
        accountNumberCreateModal.Show();
    };

    const _onAccountNumbersCreate = (account: BdAccountNumberBean) => {
        // insert new value in front of list of accounts for correct displaying
        accountNumbers.unshift(account);
        setAccountNumbers(accountNumbers);
    };

    const _onAccountNumberUpdates = (accounts: BdAccountNumberBean[]) => {
        setAccountNumbers(accounts);
    };

    return (
        <BdGrid {...otherProps}>
            <BdRow style={{ justifyContent: "end" }}>
                <BdCol xl={3} lg={4} md={12} sm={12}>
                    <BdButton type="button"
                        bdType={BdButtonType.Success}
                        bdText="New Account Number"
                        bdBlock={true}
                        disabled={btnSearchWorking}
                        onClick={_handleCreateAccountNumber}
                        bdIcon={<BdIconIoList />}
                    />
                    <BdAccountNumberCreateModal {...accountNumberCreateModal.BdModalProps}
                        bdTitle="Create Account Number"
                        createAction={true}
                        onUpdate={_onAccountNumbersCreate}
                        account={defaultBdAccountNumberBean}></BdAccountNumberCreateModal>
                </BdCol>
            </BdRow>

            <BdRow>
                <BdCol xl={12} lg={12} md={12} sm={12}>
                    {accountNumbers[0] && <BdEnterpriseLicenseSearchList accountNumbers={accountNumbers}
                        onAccountNumberUpdates={_onAccountNumberUpdates}
                        style={{ marginTop: BdMargin.Normal }} />}
                </BdCol>
            </BdRow>

            <BdAlert bdType={alertData.type} bdDissmisable={false} bdShow={alertData.show} onClose={_closeAlert} style={{ marginTop: BdMargin.Normal }}
                bdTitle={
                    <><BdIconDisappointed style={{ marginRight: BdMargin.Small }} />Oops!</>
                }>
                <p>{alertData.message}</p>
            </BdAlert>
        </BdGrid>
    );
}
