import { BdAlert, BdAlertType, BdCol, BdCopyClipboardButton, BdModal, BdRow, useBdToast } from "bundledocs.common.client/modules/bd-components";
import { EnumHelper } from "bundledocs.common.client/modules/bd-helpers";
import { BdMargin } from "bundledocs.common.client/src";
import BdGrid from "bundledocs.common.client/src/components/bd-layout/bd-row";
import * as React from "react";
import { useState } from "react";
import { AdminRole, UserAdminDetailsBean } from "../../../models/providers/bean/user-portal-bean/bd-user-admin-bean";
import { UserPortalBean } from "../../../models/providers/bean/user-portal-bean/user-portal-bean";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";
import bdStyle from "./bd-user-admin.module.css";

interface IBdUserAdminModalProps {
    bdUser: UserPortalBean;
    onCancel?: Function;
    bdShow: boolean;
}

/** Modal to show user admin/owner details */
export const BdUserAdminModal = ({
    bdUser,
    onCancel,
    bdShow
}: IBdUserAdminModalProps) => {
    //services
    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    //admin details
    const [adminDetails, setAdminDetails] = useState<UserAdminDetailsBean[]>([]);

    //toast functions
    const { toastError } = useBdToast();

    React.useEffect(() => {
        _handleGetAdminDetails();
    }, [bdUser]);

    //open admin details modal
    const _handleGetAdminDetails = async () => {
        if (bdUser?.RowKey) {
            const response = await _bundledocsApiInstance.Users.GetUserAdminDetails(bdUser.RowKey);

            try {
                if (response) {
                    setAdminDetails(response);
                }
                else {
                    toastError("No owners or admins found for this user");
                }
            } catch (error: any) {
                toastError(error);
            }
        }
    };

    /** close on ok */
    const _handleConfirm = async (): Promise<boolean> => {
        onCancel?.();
        return true;
    };

    return (
        <BdModal bdTitle="User Owners and Admins" bdShow={bdShow} onConfirm={_handleConfirm} onCancel={onCancel} bdShowCancel={false} bdConfirmText="Close">
            <>
                {adminDetails.length == 0 &&
                    <BdAlert bdType={BdAlertType.Info}>
                        <>{bdUser.Email} is not a member of a Management Group</>
                    </BdAlert>
                }
                {adminDetails.length > 0 &&
                    <BdGrid className={bdStyle.bdUserAdminDetailsGrid}>
                        {adminDetails?.map((adminDetail: UserAdminDetailsBean) =>
                            <>
                                <BdRow style={{ justifyContent: "start" }} key={adminDetail.Email}>
                                    <BdCol sm={12}>
                                        <BdAlert bdType={BdAlertType.Info} bdDissmisable={false} bdShow={true} style={{ marginTop: BdMargin.Small }}>
                                            <div className={bdStyle.bdUserAdminDetailsAlert}>
                                                <div className={bdStyle.bdUserAdminDetailsEmail}>
                                                    <BdCopyClipboardButton bdText={adminDetail.Email} />
                                                </div>
                                                <div className={bdStyle.bdUserAdminDetailsAdminRole}>
                                                    <small>{EnumHelper.getFriendlyName(AdminRole, adminDetail.AdminRole)}</small>
                                                </div>
                                            </div>
                                        </BdAlert>
                                    </BdCol>
                                </BdRow>
                            </>
                        )}
                    </BdGrid>
                }
            </>
        </BdModal>
    );
};
