import { BdSelector, ISelectorProps } from "bundledocs.common.client/modules/bd-components";
import { Currencies } from "bundledocs.common.client/modules/bd-constants";

/*
 * Select Currency props
 */
export type BdSelectCurrencyProps = ISelectorProps

/** Currency selection component */
export const BdSelectCurrency = ({
    ...otherProps
}: BdSelectCurrencyProps) => {
    return (
        <BdSelector
            bdOptions={Currencies}
            {...otherProps}
        />
    );
};
