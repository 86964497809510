import { BdBundledocsPortalApi } from "../services/portal-api/bd-bundledocs-portal-api";

/**
 * Provides the Bundledocs services needed by this project, like the Bundledocs Portal Api wrapper
 * */
export default class BundledocsServicesProvider {
    private static _bundledocsPortalApi: BdBundledocsPortalApi = new BdBundledocsPortalApi(
        (process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV) ?? ""
    );

    static BundledocsPortalApiInstance(): BdBundledocsPortalApi {
        return BundledocsServicesProvider._bundledocsPortalApi;
    }
}
