import { BdCol, BdDatePicker, BdModal, BdRadioInput, BdRow, BdTextBox, BdTextBoxType, useBdToast } from "bundledocs.common.client/modules/bd-components";
import { BdToastFailureMessage } from "bundledocs.common.client/modules/bd-models";
import { BdIconCalendar, BdMargin } from "bundledocs.common.client/src";
import FormatHelper from "bundledocs.common.client/src/helpers/format-helper";
import React, { useEffect, useState } from "react";
import { BdEnterpriseLicenseBean } from "../../../models/providers/bean/bd-enterprise-license-bean/bd-enterprise-license-bean";
import { EnterpriseLicenseOptions } from "../../../models/providers/enterprise-license-context/enterprise-license-options";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";

interface IBdEnterpriseLicenseRenewModalProps {
    bdLicense: BdEnterpriseLicenseBean;
    onUpdate?: (license: BdEnterpriseLicenseBean) => void;
    onCancel?: Function;
    bdShow: boolean;
    renewalMaxDate?: Date;
}

const enum DateToggleEnum {
    ONE_WEEK = 7,
    TWO_WEEK = 14,
    ONE_MONTH = 30,
    TWO_MONTHS = 60,
    SIX_MONTHS = 180,
    ONE_YEAR = 365
}

/** Modal for managing the renew licence process */
export const BdEnterpriseLicenseRenewModal = ({
    bdLicense,
    onUpdate,
    onCancel,
    bdShow,
    renewalMaxDate = new Date()
}: IBdEnterpriseLicenseRenewModalProps) => {
    const [updating, setUpdating] = useState(false);

    //toast functions
    const { toastSuccess, toastError } = useBdToast();

    // date range
    const defaultDateFormat = "yyyy-MM-dd";

    // for first rendering to exclude the error in datepicker need to assign any valid date
    const defaultRenewalDate = bdLicense.RenewalDate ? new Date(bdLicense.RenewalDate) : new Date();
    const maxRenewalDate = FormatHelper.addYearsToDate(defaultRenewalDate, 10);

    const [renewalDate, setRenewalDate] = useState<Date>(defaultRenewalDate);

    // need update RenewalDate after we get correct data from model
    useEffect(() => {
        setRenewalDate(bdLicense.RenewalDate ? new Date(bdLicense.RenewalDate) : new Date());
    }, [bdLicense]);

    const _updateRenewalDate = (date: Date) => {
        setRenewalDate(date);
        setToggleValue("");
    };

    // radio buttons
    const [toggleValue, setToggleValue] = useState<string>("");

    const _onToggleUpdate = (e: any) => {
        const toggleValue = e.target.value;
        setToggleValue(toggleValue);

        let updatedRenewalDate = defaultRenewalDate;

        if (toggleValue.toString() === DateToggleEnum.ONE_WEEK.toString()) {
            updatedRenewalDate = new Date(FormatHelper.addDaysToDate(defaultRenewalDate, 7));
        } else if (toggleValue.toString() === DateToggleEnum.TWO_WEEK.toString()) {
            updatedRenewalDate = new Date(FormatHelper.addDaysToDate(defaultRenewalDate, 14));
        } else if (toggleValue.toString() === DateToggleEnum.ONE_MONTH.toString()) {
            updatedRenewalDate = new Date(FormatHelper.addMonthsToDate(defaultRenewalDate, 1));
        } else if (toggleValue.toString() === DateToggleEnum.TWO_MONTHS.toString()) {
            updatedRenewalDate = new Date(FormatHelper.addMonthsToDate(defaultRenewalDate, 2));
        } else if (toggleValue.toString() === DateToggleEnum.SIX_MONTHS.toString()) {
            updatedRenewalDate = new Date(FormatHelper.addMonthsToDate(defaultRenewalDate, 6));
        } else if (toggleValue.toString() === DateToggleEnum.ONE_YEAR.toString()) {
            updatedRenewalDate = new Date(FormatHelper.addYearsToDate(defaultRenewalDate, 1));
        }

        setRenewalDate(updatedRenewalDate);
    };

    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    /** set updating=false on close */
    useEffect(() => {
        if (!bdShow) {
            setUpdating(false);
        }
    }, [bdShow]);

    /** renew enterprise license */
    const _handleEnterpriseLicenseUpdate = async (licenseOptions: EnterpriseLicenseOptions): Promise<boolean> => {

        let handleResult = true;

        try {
            setUpdating(true);

            const licenceResult: BdEnterpriseLicenseBean = await _bundledocsApiInstance.EnterpriseLicenses.RenewEnterpriseLicense(
                bdLicense.PartitionKey, bdLicense.RowKey, licenseOptions);

            toastSuccess("User's Enterprise License was recreated successfully");

            if (new Date(renewalMaxDate) >= new Date(licenceResult.RenewalDate)) {
                onUpdate?.(licenceResult);
            }

            setToggleValue("");
            onCancel?.();

            handleResult = true;
        }
        catch (error: any) {
            toastError(error as BdToastFailureMessage);
            handleResult = false;
        }
        finally {
            setUpdating(false);
        }

        return handleResult;
    };

    const _handleOnCancel = () => {
        setToggleValue("");
        onCancel?.();
    };

    return (
        <BdModal bdTitle="Renew Enterprise License" bdDisabled={updating} onConfirm={_handleEnterpriseLicenseUpdate}
            onCancel={_handleOnCancel} bdShow={bdShow} >
            <BdTextBox
                name="VersionNumber"
                bdTextBoxType={BdTextBoxType.Hidden}
                bdValue={bdLicense?.PartitionKey}
            />
            <BdTextBox
                bdLabel="Organization"
                name="Organization"
                style={{ marginBottom: "1rem" }}
                bdValue={bdLicense?.Organization}
            />
            <BdTextBox
                bdLabel="Max Users"
                name="MaxUsers"
                style={{ marginBottom: "1rem" }}
                bdValue={bdLicense?.MaxUsers}
                bdTextBoxType={BdTextBoxType.Number}
            />
            <BdTextBox
                bdLabel="Max Briefs"
                name="MaxBriefs"
                style={{ marginBottom: "1rem" }}
                bdValue={bdLicense?.MaxBriefs}
                bdTextBoxType={BdTextBoxType.Number}
            />

            <BdDatePicker
                bdLabel="Renewal Until"
                name="RenewalDate"
                id="dateRangeStart"
                bdIcon={<BdIconCalendar />}
                dateFormat={defaultDateFormat}
                bdValue={renewalDate}
                bdMax={maxRenewalDate}
                wrapperClassName="dateRangeStartPicker"
                onUpdate={_updateRenewalDate}
            />

            <BdRow style={{ marginBottom: BdMargin.Normal }}>
                <BdCol xl={12} lg={12} md={12} sm={12}>
                    <BdRadioInput bdChecked={toggleValue.toString() === DateToggleEnum.ONE_WEEK.toString()}
                        bdValue={DateToggleEnum.ONE_WEEK.toString()}
                        onChange={_onToggleUpdate}
                        bdLabel='1 Week'
                        labelStyles={{ marginRight: BdMargin.Large }}
                    />
                    <BdRadioInput bdChecked={toggleValue.toString() === DateToggleEnum.ONE_MONTH.toString()}
                        bdValue={DateToggleEnum.ONE_MONTH.toString()}
                        onChange={_onToggleUpdate}
                        bdLabel='1 Month'
                        labelStyles={{ marginRight: BdMargin.Large }}
                    />
                    <BdRadioInput bdChecked={toggleValue.toString() === DateToggleEnum.SIX_MONTHS.toString()}
                        bdValue={DateToggleEnum.SIX_MONTHS.toString()}
                        onChange={_onToggleUpdate}
                        bdLabel='6 Months'
                        labelStyles={{ marginRight: BdMargin.Normal }}
                    />
                </BdCol>

                <BdCol xl={12} lg={12} md={12} sm={12}>
                    <BdRadioInput bdChecked={toggleValue.toString() === DateToggleEnum.TWO_WEEK.toString()}
                        bdValue={DateToggleEnum.TWO_WEEK.toString()}
                        onChange={_onToggleUpdate}
                        bdLabel='2 Weeks'
                        labelStyles={{ marginRight: BdMargin.Medium }}
                    />
                    <BdRadioInput bdChecked={toggleValue.toString() === DateToggleEnum.TWO_MONTHS.toString()}
                        bdValue={DateToggleEnum.TWO_MONTHS.toString()}
                        onChange={_onToggleUpdate}
                        bdLabel='2 Months'
                        labelStyles={{ marginRight: BdMargin.Medium }}
                    />
                    <BdRadioInput bdChecked={toggleValue.toString() === DateToggleEnum.ONE_YEAR.toString()}
                        bdValue={DateToggleEnum.ONE_YEAR.toString()}
                        onChange={_onToggleUpdate}
                        bdLabel='1 Year'
                        labelStyles={{ marginRight: BdMargin.Small }}
                    />
                </BdCol>
            </BdRow>

            <BdTextBox
                bdLabel="Email"
                name="Email"
                style={{ marginBottom: "1rem" }}
                bdValue={bdLicense?.Email}
            />
        </BdModal>
    );
};
