import { ActionEvent } from "../../models/providers/bean/common-context/action-event";
import { BdApiResponse, BdServiceBase, BdServiceConfig } from "../bd-service-base";
import IBdServiceEvents from "./interfaces/IBdServiceEvents";

/**
 * Events Service
 * */
export class BdServiceEvents extends BdServiceBase implements IBdServiceEvents {
    constructor(configuration: BdServiceConfig) {
        super(configuration);
    }

    private static ApiUrl = "/api/v1/events";

    All(context: string, batch?: string): Promise<BdApiResponse<ActionEvent>> {
        return super.Get(`${BdServiceEvents.ApiUrl}/${context}/${batch ?? ""}`);
    }

    Cancel(context: string, batch?: string): Promise<any> {
        return super.Delete(`${BdServiceEvents.ApiUrl}/${context}/${batch ?? ""}`);
    }
}
