import { BdServiceBase } from "bundledocs.common.client/modules/bd-services";
import { BdAccountNumberBean } from "../../models/providers/bean/bd-account-number-bean/bd-enterprise-license-bean";

/**
 * Account numbers Service
 * */
export default class BdServiceAccountNumbers extends BdServiceBase {
    private static ApiUrl = "/api/v1/account-numbers";

    /**
     * Returns list of account numbers
     */
    GetAll(): Promise<BdAccountNumberBean[]> {
        const fields = "partitionkey,rowkey,isactive,isactivestring,accountnumber,subscriptiontype,subscriptiontypename,subscriptioncaseLimit,subscriptionuserLimit,name,company,email,notes";

        return super.Get(BdServiceAccountNumbers.ApiUrl + "?$select=" + fields);
    }

    /**
     * Actions for account number
     * @param accountNumberBean: account number object
     * @param actions: actions type
     */
    Actions(accountNumberBean: BdAccountNumberBean, actions: string): Promise<BdAccountNumberBean> {
        return super.Post(`${BdServiceAccountNumbers.ApiUrl}/${actions}`, accountNumberBean);
    }
}
