import cx from "classnames";
import * as React from "react";
import bdStyle from "./bd-left-panel.module.css";

export interface BdPanelLeftProps {
    children?: React.ReactNode
    bdClass?: string
}

const BdPanelLeft = ({
    children,
    bdClass = ""
}: BdPanelLeftProps) => {

    return (
        <div className={cx(bdStyle.bdPanelLeft, bdClass)} children={children} />
    );
};

export default BdPanelLeft;
