import { BdGrid, BdTable } from "bundledocs.common.client/modules/bd-components";
import { BdTableColumn } from "bundledocs.common.client/src/components/bd-data/bd-table";
import React from "react";
import { BdDocumentPortalBean } from "../../models/providers/bean/bd-live-bundles-bean/bd-document-bean";
import bdStyle from "./bd-list-with-summary.module.css";
import cx from "classnames";

export interface BdDocumentsProps extends React.HTMLAttributes<HTMLElement> {
    bdDocuments: BdDocumentPortalBean[];
    bdDocumentsCounter: number;
    bdSectionsCounter: number;
    bdTotalCounter: number;
    bdTotalFileSize: string;
}

/**
 * Components that show a list of documents that receives in  the props
 * @param param0
 */
export default function BdDocumentsListWithSummary({
    bdDocuments,
    bdDocumentsCounter,
    bdSectionsCounter,
    bdTotalCounter,
    bdTotalFileSize,
    ...otherProps
}: BdDocumentsProps): JSX.Element {

    const _columns: BdTableColumn[] = [
        { header: "Created", field: "BriefDocumentCreated", typeof: String },
        { header: "Display Order", field: "DisplayOrder", typeof: String },
        { header: "Page Count", field: "NumberOfPages", typeof: Number },
        { header: "File Size", field: "FileSize", typeof: Number },
        { header: "File Extension", field: "FileExtension", typeof: String }
    ];

    return ( 
        <BdGrid {...otherProps} className={bdStyle.Container} >
            <div className={cx(bdStyle.DocumentsTableContainer,bdStyle.TableContainer)}>
                <BdTable {...otherProps} bdColumns={_columns} bdItems={bdDocuments} />
            </div>
            <div className={bdDocuments.length > 0 ? bdStyle.DisplayDiv : bdStyle.HideDiv}>
                <span><b> Sections  :</b> {bdSectionsCounter}</span>
                <span><b> Documents  :</b> {bdDocumentsCounter}</span>
                <span><b> Total  :</b> {bdTotalCounter}</span>
                <span><b> Total Size  :</b> {bdTotalFileSize}</span>
            </div>
        </BdGrid>       
    );
}
