import { BaseModel } from "../BaseModel";
import { Brief } from "../brief-context/Brief";
import { BriefReceipt } from "../brief-context/BriefReceipt";

export enum UserGroupMemberModuleStatus {
    ENABLED = 0,
    DISABLED = 100
}

export enum UserGroupMemberRole {
    OWNER = 0,
    MEMBER = 1,
    INVITED = 2,
    ADMIN = 3,
}

export interface AddGroupMemberOptions {
    Recipients: string[];
    IsSSOInvite: boolean;
}

export interface UserGroupMember extends BaseModel {
    Role?: string;
    RoleId?: number;

    UserGroupPartitionKey?: string;

    Email?: string;
    LastActiveTimeStamp?: Date;
    BriefBlobStorageAccountName?: string;

    ModuleStatusApp?: UserGroupMemberModuleStatus;
    ModuleStatusAdjust?: UserGroupMemberModuleStatus;
    ModuleStatusEdit?: UserGroupMemberModuleStatus;

    BriefsShared?: Array<Brief>;
    BriefReceipts?: Array<BriefReceipt>;

    BriefsCount?: number
}
