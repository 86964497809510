import { CSSProperties } from "react";
import { BdInputTypes } from "../../constants/bd-enums";
import { useBdForm } from "./bd-form";
import { IFormFieldProps } from "./bd-form-constants";

/* Bootstrap 5 version */
/** props */
interface IInputProps extends IFormFieldProps, React.HTMLAttributes<HTMLLabelElement> {
    bdLabel?: string;
    bdChecked: boolean;
    labelStyles?: CSSProperties | undefined;
}

/**
 * Input field consisting of standard Bundledocs parameters
 * @param props
 */
const BdRadioInput = ({
    //specific properties
    bdLabel = "",
    bdChecked = false,
    labelStyles = undefined,
    //form field properties
    name = "",
    bdValue,
    bdValidations,
    bdShowError = true,
    //other properties
    ...otherProperties
}: IInputProps) => {

    //BdForm data if exists
    const { bdErrorMessage, bdTouched, register } = useBdForm(name, bdValue, bdValidations);

    const isInForm: boolean = !!register && !!name;
    const formProps = isInForm
        ? {
            isValid: bdTouched && !bdErrorMessage,
            isInvalid: bdTouched && bdErrorMessage,
            ...register?.(name)
        }
        : {};

    return (
        <label style={labelStyles}>
            <input
                {...formProps}
                type={BdInputTypes.Radio}
                value={bdValue}
                checked={bdChecked}
                {...otherProperties}
            />
            {bdLabel}
        </label>
    );
};

export default BdRadioInput;
