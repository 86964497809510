/**
 * Class with common methods related with calculating filesize
 * */
export default class FileSizeHelper {
    static toFileSizeLengthFromFileSizeString(fileSize: string): number {
        let fileSizeLength = 0;
        fileSize = fileSize || fileSizeLength.toString();

        try {
            fileSizeLength = fileSize.includes("MB") ? parseFloat(fileSize.replace("MB", "").trim()) * 1000000
                : parseFloat(fileSize.replace("KB", "").trim()) * 1000;
        } catch (ex: any) {            
            fileSizeLength = 0;
        }

        return Math.round(fileSizeLength);
    }

    static toFileSizeStringFromStreamLength(fileSize: number): string {
        const MB_IN_BYTES = 1000000; // 1 MB in bytes

        if (fileSize >= MB_IN_BYTES) {
            const mb = fileSize / MB_IN_BYTES;
            return mb.toFixed(2) + " MB";
        } else {
            return fileSize / 1000 + " KB";
        }
    }
}
