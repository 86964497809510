import { BdApiResponse } from "../services/bd-service-base";
import { ActionEvent } from "../models/providers/bean/common-context/action-event";
import IBdServiceEvents from "../services/core-api/interfaces/IBdServiceEvents";

export enum BdDocumentEventTypes {
    ACTION,
    PROCESS
}

/**
 * Helper to manage events
 * */
export enum BdBriefEventTypes {
    SWAP_BLOB_STORAGE_ACCOUNT_EDITOR,
    SWAP_BLOB_STORAGE_ACCOUNT_BUNDLEDOCS,
    COPY,
    SEAL,
    GENERATE,
    MIGRATION,
    SECTION_COPY
}

export default class BdEventsHelper {
    //starts listening for UAC_BriefDocument events given a context and a batch, and run callbacks depending on the received events
    static startDocumentEventsListener(bdServiceEvents: IBdServiceEvents, context: string, batch: string, type: BdDocumentEventTypes,
        beginHandler?: (begin: ActionEvent) => void,
        successHandler?: (success: ActionEvent) => void,
        failureHandler?: (failure: ActionEvent) => void): NodeJS.Timer | null {
        //stores the last progress, and only notifies events that are newer
        let mostAdvancedState = 0;
        let eventMiddleName = "";

        switch (type) {
            case BdDocumentEventTypes.ACTION:
                eventMiddleName = "BriefDocumentAction";
                break;
            case BdDocumentEventTypes.PROCESS:
                eventMiddleName = "BriefDocumentProcessing";
                break;
        }

        if (!eventMiddleName) {
            return null;
        }

        const actionInterval = setInterval(async () => {
            const events: BdApiResponse<ActionEvent> = await bdServiceEvents.All(context, batch);

            if ((events?.data?.length ?? 0) > 0) {
                //handle global message as a failure indicator
                let indexEvent = events!.data!.findIndex(e => e.name === "GlobalMessage");
                let currentEventState = 100;
                if (indexEvent > -1 && mostAdvancedState < currentEventState) {
                    mostAdvancedState = currentEventState;
                    clearInterval(actionInterval);
                    failureHandler?.(events!.data![indexEvent]);
                    return;
                }
                //handle failure
                indexEvent = events!.data!.findIndex(e => e.name === `UAC_${eventMiddleName}_Fail`);
                currentEventState = 100;
                if (indexEvent > -1 && mostAdvancedState < currentEventState) {
                    mostAdvancedState = currentEventState;
                    clearInterval(actionInterval);
                    failureHandler?.(events!.data![indexEvent]);
                    return;
                }
                //handle success
                indexEvent = events!.data!.findIndex(e => e.name === `UAC_${eventMiddleName}_End`);
                currentEventState = 100;
                if (indexEvent > -1 && mostAdvancedState < currentEventState) {
                    mostAdvancedState = currentEventState;
                    clearInterval(actionInterval);
                    successHandler?.(events!.data![indexEvent]);
                    return;
                }
                //handle begin
                indexEvent = events!.data!.findIndex(e => e.name === `UAC_${eventMiddleName}_Begin`);
                currentEventState = 20;
                if (indexEvent > -1 && mostAdvancedState < currentEventState) {
                    mostAdvancedState = currentEventState;
                    beginHandler?.(events!.data![indexEvent]);
                    return;
                }
            }
        }, 7000);

        return actionInterval;
    }

    //starts listening for UAC_BriefSwapBlobStorageAccount events given a context and a batch, and run callbacks depending on the received events
    static startBriefActionListener(bdServiceEvents: IBdServiceEvents, context: string, batch: string, action: BdBriefEventTypes,
        beginHandler?: (begin: ActionEvent) => void,
        progressHandler?: (progress: ActionEvent) => void,
        successHandler?: (success: ActionEvent) => void,
        failureHandler?: (failure: ActionEvent) => void): NodeJS.Timer | null {
        //stores the last progress, and only notifies events that are newer
        let mostAdvancedState = 0;
        let eventMiddleName = "";

        switch (action) {
            case BdBriefEventTypes.SWAP_BLOB_STORAGE_ACCOUNT_BUNDLEDOCS || BdBriefEventTypes.SWAP_BLOB_STORAGE_ACCOUNT_EDITOR:
                eventMiddleName = "BriefSwapBlobStorageAccount";
                break;
            case BdBriefEventTypes.COPY:
                eventMiddleName = "BriefCopy";
                break;
            case BdBriefEventTypes.SEAL:
                eventMiddleName = "BriefSeal";
                break;
            case BdBriefEventTypes.GENERATE:
                eventMiddleName = "BriefProcessing";
                break;
            case BdBriefEventTypes.MIGRATION:
                eventMiddleName = "BriefMigration";
                break;
            case BdBriefEventTypes.SECTION_COPY:
                eventMiddleName = "SectionCopy";
                break;
            default:
                break;
        }

        if (!eventMiddleName) {
            return null;
        }

        const actionInterval = setInterval(async () => {
            const events = await bdServiceEvents.All(context, batch);

            if ((events?.data?.length ?? 0) > 0) {
                //handle global message as a failure indicator
                let indexEvent = events!.data!.findIndex((e: ActionEvent) => e.name === "GlobalMessage");
                let currentEventState = 100;
                if (indexEvent > -1 && mostAdvancedState < currentEventState) {
                    mostAdvancedState = currentEventState;
                    clearInterval(actionInterval);
                    failureHandler?.(events!.data![indexEvent]);
                    return;
                }
                //handle failure
                indexEvent = events!.data!.findIndex((e: ActionEvent) => e.name === `UAC_${eventMiddleName}_Fail`);
                currentEventState = 100;
                if (indexEvent > -1 && mostAdvancedState < currentEventState) {
                    mostAdvancedState = currentEventState;
                    clearInterval(actionInterval);
                    failureHandler?.(events!.data![indexEvent]);
                    return;
                }
                //handle success
                indexEvent = events!.data!.findIndex((e: ActionEvent) => e.name === `UAC_${eventMiddleName}_End`);
                currentEventState = 100;
                if (indexEvent > -1 && mostAdvancedState < currentEventState) {
                    mostAdvancedState = currentEventState;
                    clearInterval(actionInterval);
                    successHandler?.(events!.data![indexEvent]);
                    return;
                }
                //handle progress
                indexEvent = events!.data!.findIndex((e: ActionEvent) => e.name === `UAC_${eventMiddleName}_Progress`);
                currentEventState = 75;
                if (indexEvent > -1 && mostAdvancedState <= currentEventState) {
                    mostAdvancedState = currentEventState;
                    progressHandler?.(events!.data![indexEvent]);
                    return;
                }
                //handle begin
                indexEvent = events!.data!.findIndex((e: ActionEvent) => e.name === `UAC_${eventMiddleName}_Begin`);
                currentEventState = 20;
                if (indexEvent > -1 && mostAdvancedState < currentEventState) {
                    mostAdvancedState = currentEventState;
                    beginHandler?.(events!.data![indexEvent]);
                    return;
                }
            }

        }, 7000);

        return actionInterval;
    }
}
