import { BaseModel } from "../BaseModel";

export interface BaseSubscription extends BaseModel {
    Type: SubscriptionType;
    Status: SubscriptionStatus;
}

export enum SubscriptionType {
    FREE = 0,

    //paid per bundle
    BASIC = 1,
    PERFORMANCE = 5,
    PROFESSIONAL = 2,
    ULTIMATE = 3,

    //paid per user
    BASIC_V2 = 1000,
    PROFESSIONAL_V2 = 2000,
    ULTIMATE_V2 = 3000,

    //paid per user
    BASIC_V3 = 1001,
    PROFESSIONAL_V3 = 2001,
    ULTIMATE_V3 = 3001,

    GROUP = 6,
    TRIAL_EXPIRED = 9,

    //unlimited
    CORPORATE = 7,
    CORPORATE_GROUP = 8,

    //limited
    MANAGED = 100,
    MANAGED_GROUP = 200
}

export enum SubscriptionStatus {
    ACTIVE = 0,
    INACTIVE = 1,
    SUSPENDED = 2,
    EXPIRED = 3,
    LOCKED = 4
}
