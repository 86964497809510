import React, { useEffect, useState } from "react";
import { Form, FormGroup, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormatHelper from "../../helpers/format-helper";
import { useBdForm } from "./bd-form";
import { IFormFieldProps } from "./bd-form-constants";

/* Bootstrap 5 version */
/** text-box const definitions */
export const enum BdTextBoxType {
    Input = "input",
    Text = "text",
    Password = "password",
    Number = "number",
    Date = "date"
}

/** props */
interface IDatePrickerProps extends IFormFieldProps, React.HTMLAttributes<HTMLDivElement> {
    bdLabel?: string;
    bdIcon?: JSX.Element;
    bdMin?: Date;
    bdMax?: Date;
    bdShowTimeSelect?: boolean;
    bdTimeFormat?: string;
    dateFormat: string;
    wrapperClassName?: string;
    onUpdate?: (value: Date) => void;
}

/**
 * Textbox consisting of standard Bundledocs parameters, with validation
 * @param props
  */
const BdDatePicker = ({
    //specific properties
    dateFormat = "yyyy-MM-dd",
    wrapperClassName = "datePicker",
    bdLabel = "",
    bdIcon = undefined,
    bdMin,
    bdMax,
    bdShowTimeSelect = false,
    bdTimeFormat = "p",
    //form field properties
    name = "",
    bdValue = new Date(),
    bdValidations,
    bdShowError = true,
    //other properties
    style,
    onUpdate,
    ...otherProperties
}: IDatePrickerProps) => {
    //BdForm data if exists
    const { register, bdSetValue } = useBdForm(name, bdValue, bdValidations);

    const formProps = !!register && !!name
        ? { ...register?.(name) }
        : {};

    const [startDate, setStartDate] = useState<Date>(bdValue);
    const [displayDatePicker, setDisplayDatePicker] = useState<boolean>(false);

    const startDateFormatted = FormatHelper.convertDateToFormat(startDate, dateFormat);

    // fix problem with updating input from parent component if we using radio buttons with different date values
    useEffect(() => {
        setStartDate(bdValue);
        setDisplayDatePicker(false);
        bdSetValue?.(FormatHelper.convertDateToFormat(bdValue, dateFormat));
    }, [bdValue]);

    // Check if the value is a valid date
    const isValidDate = (dateString: string | number | Date) => {
        const parsedDate = new Date(dateString);
        return !isNaN(parsedDate.getTime());
    };

    const handleDatePickerChange = (date: Date) => {
        setDisplayDatePicker(!displayDatePicker);

        onUpdate?.(date);
        applyNewDate(date);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDateString: string = event.target.value;

        if (newDateString.length == 10) {//checks if the year entered is a 4 digit year
            const newDate = new Date(newDateString);

            if (isValidDate(newDate)) {
                applyNewDate(newDate);
            }
        }
    };

    const applyNewDate = (date: Date) => {
        setStartDate(date);
        bdSetValue?.(FormatHelper.convertDateToFormat(date, dateFormat));
    };

    /** Main content */
    return (
        <FormGroup id={name} style={style}>
            {bdLabel && <Form.Label>{bdLabel}</Form.Label>}
            <InputGroup>
                <Form.Control
                    {...formProps}
                    type={BdTextBoxType.Text}
                    defaultValue={startDateFormatted}
                    onChange={handleInputChange}
                    autoComplete="off"
                    {...otherProperties}
                />
                {bdIcon && <InputGroup.Text onClick={() => setDisplayDatePicker(!displayDatePicker)}>{bdIcon}</InputGroup.Text>}
            </InputGroup>

            {displayDatePicker ?
                <div style={{ position: "absolute", zIndex: 1000 }}>
                    <DatePicker
                        todayButton="Today"
                        selected={startDate}
                        onChange={handleDatePickerChange}
                        wrapperClassName={wrapperClassName}
                        minDate={bdMin ?? undefined}
                        maxDate={bdMax ?? undefined}
                        value={startDateFormatted}
                        showPopperArrow={true}
                        showTimeSelect={bdShowTimeSelect}
                        timeFormat={bdTimeFormat}
                        timeIntervals={15}
                        dateFormat={dateFormat}
                        inline
                    />
                </div>
                : null
            }
        </FormGroup>
    );
};

export default BdDatePicker;
