/*MenuItem types*/
export const enum MenuItemType {
    Entry = 100,
    Title = 200,
    Divider = 300,
    CustomElement= 400
}

/*MenuItem types*/
export const enum ButtonType {
    Default = 100,
    Warning = 200,
    Danger = 300
}

/* Images */
export const enum BdImageSize {
    Default = 0,
    XS = 10,
    S = 20,
    M = 30,
    L = 40,
    XL = 50,
}

/* Thumbnails */
export const enum BdThumbnailSize {
    SMALL = 20,
    LARGE = 40
}

/* Input */
export const enum BdInputTypes {
    Radio = "radio",
    Text = "text",
    Password = "password",
    Number = "number"
}

/* User Subscription */
export const enum BdUserSubscriptionTypes {
    ALL = -1,
    TRIAL_EXPIRED = 9
}

/* Brief Access Type */
export const enum BdBriefAccessType {
    CASE = 0,
    TEMPLATE = 1,
    ADJUST = 2
}

/* Brief Document Type */
export const enum BdBriefDocumentType {
    DOCUMENT = "DOCUMENT",
    SECTION = "HEADER"
}
