import { Form } from "react-bootstrap";

interface IBdFormErrorMessage {
    bdErrorMessage: string,
    bdShowError: boolean,
}

export function BdFormErrorMessage({
    bdErrorMessage,
    bdShowError
}: IBdFormErrorMessage): JSX.Element {
    return bdShowError && bdErrorMessage
        ? (
            <Form.Control.Feedback type="invalid">
                {bdErrorMessage}
            </Form.Control.Feedback>
        )
        : <></>;
}
