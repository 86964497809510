import { BaseModel } from "../BaseModel";

export enum BriefDocumentAnnotationType {
    COMMUNICATION = 0,
    ATTRIBUTE = 1,

    LAYER = 2
}

export enum BriefDocumentAnnotationContentType {
    TEXT = 0,
    DISPLAY_ORDER_PREFIX = 1,

    REDACT = 2,
    HIGHLIGHT = 3,
    NOTE = 4,
    LABEL = 5,
}

export interface BriefDocumentAnnotation extends BaseModel {
    Title?: string;
    Content?: string;
    PageNumber?: number;

    DocumentReferenceRowKey?: string;
    DocumentReferencePageNumber?: number;
    DocumentReferenceHyperlink?: string;
    DocumentReferenceBorderColor?: string;

    BackgroundColor?: string;
    Opacity?: number;
    FontSize?: string;
    BorderColor?: string;

    IsPrivate?: boolean;
    CreatedBy?: string;

    AnnotationType?: BriefDocumentAnnotationType;
    ContentType?: BriefDocumentAnnotationContentType,
}

export interface BriefDocumentAnnotationCreateOptions extends Omit<BriefDocumentAnnotation, "PartitionKey" | "RowKey"> {
    PartitionKey?: string;
    RowKey?: string;
    BriefDocumentPartitionKey?: string;
    BriefDocumentRowKey?: string;
}

export type BriefDocumentAnnotationDeleteOptions = BriefDocumentAnnotation;

export class BriefDocumentAnnotationContent {
    constructor(
        public id: string,
        public type: string,
        public title: string,
        public content: string,
        public pageNumber: number,
        public scaleFactor: number,
        public pdfArea: BriefDocumentAnnotationContentArea,
        public pageArea: BriefDocumentAnnotationContentArea,
        public isPrivate: boolean,
        public createdBy: string,
        public documentReferenceRowKey: string,
        public documentReferencePageNumber: number,
        public documentReferenceHyperlink: string,
        public documentReferenceBorderColor: string,
        public backgroundColor: string,
        public borderColor: string,
        public opacity: number,
        public fontSize: string,
        public setOpacity: (Opacity: number) => void
    ) {
    }
}

export class BriefDocumentAnnotationContentColors {
    static readonly HighlightBackgroundColor = "#fdfe98";

    static readonly DocumentReferenceBorderColor = "#27aae1";

    static readonly LabelBackgroundColor = "#fcfcfc";
    static readonly LabelBorderColor = "#cacaca";
}

export class BriefDocumentAnnotationContentArea {
    constructor(
        public left: number,
        public bottom: number,
        public right: number,
        public top: number
    ) {
    }

    pageWidth?: number;
    pageHeight?: number;

    pdfLeft?: number;
    pdfBottom?: number;
    pdfRight?: number;
    pdfTop?: number;
}
