import { FileSizeHelper, UrlHelper } from "bundledocs.common.client/modules/bd-helpers";
import { BdAlert, BdAlertType, BdFormFilter, BdGrid, BdIconDisappointed, BdMargin, BdSplitView } from "bundledocs.common.client/src";
import { BdBriefDocumentType } from "bundledocs.common.client/src/constants/bd-enums";
import React, { useState } from "react";
import { BdBundlePortalBean } from "../../models/providers/bean/bd-live-bundles-bean/bd-bundle-bean";
import { BdDocumentPortalBean } from "../../models/providers/bean/bd-live-bundles-bean/bd-document-bean";
import BundledocsServicesProvider from "../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../services/portal-api/bd-bundledocs-portal-api";
import BdBundlesListWithSummary from "./bd-bundles-list-with-summary";
import BdBundlesSearchForm from "./bd-bundles-search-form";
import BdDocumentsListWithSummary from "./bd-documents-list-with-summary";
import bdStyle from "./bd-list-with-summary.module.css";

//object interface used to manage the alert information
interface AlertData {
    type: BdAlertType,
    show: boolean,
    message: string
}

//object interface used to store the documents summary details
interface DocumentsSummaryDetails {
    documentsCounter: number,
    sectionsCounter: number,
    totalDocumentsCounter: number,
    totalDocumentsFileSize: string
}

export type BdBundlesSearchProps = React.HTMLAttributes<HTMLElement>

/**
 * Components that show a list of bundles that receives in  the props
 * @param param0
 */
export default function BdBundlesSearch({
    ...otherProps
}: BdBundlesSearchProps): JSX.Element {
    //summary details of documents 
    const [documentsSummaryDetails, setDocumentsSummaryDetails] = useState<DocumentsSummaryDetails>();

    // full bundle list after API call
    const [bundles, setBundles] = useState<BdBundlePortalBean[]>([]);

    // selected bundle's documents list after API call
    const [documents, setDocuments] = useState<BdDocumentPortalBean[]>([]);

    //executes the search
    const _handleBundleSelected = async (selectedBundle: BdBundlePortalBean) => {
        try {
            setDocuments([]);
            _closeAlert();
            if (bundles.length > 0) {
                const bundleDocuments: BdDocumentPortalBean[] = await _bundledocsPortalApi.LiveBundles.GetDocuments(selectedBundle?.PartitionKey, selectedBundle?.RowKey);
                if (bundleDocuments) {
                    setDocuments(bundleDocuments);
                }

                let sumDocuments = 0;
                let sumSections = 0;
                let sumFileSize = 0;

                for (let i = 0; i < bundleDocuments?.length; i++) {
                    const document: BdDocumentPortalBean = bundleDocuments[i];
                    if (document.Type === BdBriefDocumentType.DOCUMENT) {
                        sumDocuments++;
                    }
                    else if (document.Type !== BdBriefDocumentType.DOCUMENT) {
                        sumSections++;
                    }
                    sumFileSize += FileSizeHelper.toFileSizeLengthFromFileSizeString(document.FileSize);
                }

                const summaryDetails: DocumentsSummaryDetails = {
                    documentsCounter: sumDocuments,
                    sectionsCounter: sumSections,
                    totalDocumentsCounter: bundleDocuments.length,
                    totalDocumentsFileSize: FileSizeHelper.toFileSizeStringFromStreamLength(sumFileSize)
                };

                setDocumentsSummaryDetails(summaryDetails);

                if (!bundleDocuments[0]) {
                    _openAlert(BdAlertType.Warning, "No documents found for this bundle.");
                }
            }
        }
        catch (error: any) {
            setDocuments([]);
            _openAlert(BdAlertType.Danger, error.message);
        }
    };

    /** UI */
    const bundlesListing = (
        <BdBundlesListWithSummary bdBundles={bundles} onRowSelect={_handleBundleSelected} />

    );
    const documentsListing = (
        <BdDocumentsListWithSummary bdDocuments={documents} bdDocumentsCounter={documentsSummaryDetails?.documentsCounter || 0} bdSectionsCounter={documentsSummaryDetails?.sectionsCounter || 0} bdTotalCounter={documentsSummaryDetails?.totalDocumentsCounter || 0} bdTotalFileSize={documentsSummaryDetails?.totalDocumentsFileSize || ""} />
    );

    const [alertData, setAlertData] = useState<AlertData>({
        type: BdAlertType.Warning,
        show: false,
        message: ""
    });

    const _bundledocsPortalApi: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    //show the alert
    const _openAlert = (type: BdAlertType, message: string) => {
        setAlertData({
            ...alertData,
            type: type,
            message: message,
            show: true
        });
    };

    //hide the alert
    const _closeAlert = () => {
        setAlertData({
            ...alertData,
            show: false
        });
    };

    //executes the search
    const _handleBundleSearch = async (searchData: BdFormFilter[]) => {
        if (searchData.length === 0) {
            _openAlert(BdAlertType.Danger, "The information you are trying to search is in the wrong format. Please, check it and try again");
            return;
        }

        try {
            _closeAlert();
            setBundles([]);
            setDocuments([]);

            const filterParameters: string = UrlHelper.createFilterParametersFromBdFormFilters(searchData);
            const resultBundles: BdBundlePortalBean[] = await _bundledocsPortalApi.LiveBundles.Search(filterParameters);

            setBundles(resultBundles);

            if (!resultBundles[0]) {
                _openAlert(BdAlertType.Warning, "No bundles found.");
            }
        }
        catch (error: any) {
            setBundles([]);
            _openAlert(BdAlertType.Danger, error.response.data?.errors[0]?.message ?? error.message);
        }
    };

    return (
        <BdGrid {...otherProps} className={bdStyle.GridContainer} >
            <BdBundlesSearchForm onSearch={_handleBundleSearch}></BdBundlesSearchForm>

            <BdAlert bdType={alertData.type} bdDissmisable bdShow={alertData.show} onClose={_closeAlert} style={{ marginTop: BdMargin.Normal }}
                bdTitle={
                    <><BdIconDisappointed style={{ marginRight: BdMargin.Small }} />Oops!</>
                }>
                <p>{alertData.message}</p>
            </BdAlert>

            <div className={bdStyle.MainContainer} >
                <div className={bdStyle.BodyContainer}>
                    {bundles[0] && (
                        <BdSplitView direction="vertical" panelOneContent={bundlesListing} panelTwoContent={documentsListing} firstSectionSize={"55%"} />
                    )}
                </div>
            </div>
        </BdGrid>
    );
}
