import { BdModal, BdNumberLimiter, BdSelector, BdTextBox, useBdModal, useBdToast } from "bundledocs.common.client/modules/bd-components";
import { MAX_SUBSCRIPTION_CAPACITY_LIMIT } from "bundledocs.common.client/modules/bd-constants";
import { BdToastFailureMessage } from "bundledocs.common.client/modules/bd-models";
import { BdConfirmModal } from "bundledocs.common.client/src/components/bd-common/bd-confirm-modal";
import * as React from "react";
import { useRef, useState } from "react";
import { Country, Currency, UserSubscriptionStatus, UserSubscriptionType } from "../../../constants/bd-enum-types";
import { UserPortalBean } from "../../../models/providers/bean/user-portal-bean/user-portal-bean";
import { UserSubscriptionOptions } from "../../../models/providers/user-context/user-subscription-options";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";

interface IBdUserEditSubscriptionModalProps {
    bdUpdateUsers: UserPortalBean[];
    onUpdate?: (users: UserPortalBean[]) => void;
    onCancel?: Function;
    bdShow: boolean;
}

/** Modal to manage edit user subscription */
export const BdUserEditSubscriptionModal = ({
    bdUpdateUsers,
    onUpdate,
    onCancel,
    bdShow
}: IBdUserEditSubscriptionModalProps) => {
    const [updating, setUpdating] = useState(false);
    const [displayUser, setDisplayUser] = useState<UserPortalBean>({} as UserPortalBean);
    const formDataRef = useRef<UserSubscriptionOptions>();

    //toast functions
    const { toastSuccess, toastError } = useBdToast();

    const isMultipleUpdate: boolean = bdUpdateUsers && bdUpdateUsers.length > 1;

    const confirmModal = useBdModal();

    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    /** set updating=false on close */
    React.useEffect(() => {
        if (!bdShow) {
            setUpdating(false);
        }
    }, [bdShow]);

    //update display data for user when users changes for any reason
    React.useEffect(() => {
        if (bdUpdateUsers && bdUpdateUsers.length > 0) {
            setDisplayUser(bdUpdateUsers[0]);
        }
    }, [bdUpdateUsers]);

    /** update subscription status data */
    const _handleConfirmClick = async (data: UserSubscriptionOptions): Promise<boolean> => {
        formDataRef.current = data;
        if (isMultipleUpdate) {
            confirmModal.Show();
        }
        else {
            _handleUserSubscriptionUpdate();
        }
        return false;
    };

    /** map user object with update return data for UI display updates */
    const _mapUserReturnData = (user: UserPortalBean, updatedUserData: UserSubscriptionOptions): UserPortalBean => {
        user.SubscriptionType = updatedUserData.SubscriptionType;
        user.SubscriptionStatus = updatedUserData.SubscriptionStatus;
        user.SubscriptionCurrencyCode = updatedUserData.CurrencyCode;
        user.SubscriptionUserLimit = updatedUserData.UserLimit;
        user.SubscriptionUserLimitAdjust = updatedUserData.UserLimitAdjust;
        user.SubscriptionUserLimitEdit = updatedUserData.UserLimitEdit;
        user.SubscriptionCaseLimit = updatedUserData.CaseLimit;
        user.SubscriptionDocumentLimit = updatedUserData.DocumentLimit;
        user.AccountNumber = updatedUserData.AccountNumber;
        user.Company = updatedUserData.Company;
        user.CountryCode = updatedUserData.CountryCode;

        return user;
    };

    /** update user subscription data */
    const _handleUserSubscriptionUpdate = async (): Promise<boolean> => {
        try {
            setUpdating(true);

            const options: UserSubscriptionOptions = { ...formDataRef.current as UserSubscriptionOptions };

            if (displayUser && formDataRef.current) {
                const updatedUserList: UserPortalBean[] = [] as UserPortalBean[];

                if (bdUpdateUsers && bdUpdateUsers.length > 0) {
                    for (const user of bdUpdateUsers) {
                        const updatedUserData: UserSubscriptionOptions = await _bundledocsApiInstance.Users.UpdateUserSubscription(user.RowKey, options);

                        const returnUser: UserPortalBean = _mapUserReturnData(user, updatedUserData);

                        updatedUserList.push(returnUser);
                    }

                    toastSuccess(bdUpdateUsers.length > 1 ? `${bdUpdateUsers.length} users have been updated successfully!` : "User's subscription updated successfully!");
                }

                onUpdate?.(updatedUserList);
                onCancel?.();

                formDataRef.current = undefined;
            }
            return true;
        }
        catch (error: any) {
            toastError(error as BdToastFailureMessage);
            return false;
        }
        finally {
            setUpdating(false);
        }
    };

    return (
        <>
            <BdModal bdTitle="Edit Subscription" bdDisabled={updating} onConfirm={_handleConfirmClick} onCancel={onCancel} bdShow={bdShow} bdSaving={updating}>
                <h6>{isMultipleUpdate ? `${bdUpdateUsers.length} users selected` : displayUser.Email}</h6>
                <BdSelector
                    bdOptions={UserSubscriptionType}
                    bdLabel="Type"
                    bdValue={displayUser?.SubscriptionType}
                    name="SubscriptionType"
                />
                <BdSelector
                    bdOptions={UserSubscriptionStatus}
                    bdLabel="Status"
                    bdValue={displayUser?.SubscriptionStatus}
                    name="SubscriptionStatus"
                />
                <BdSelector
                    bdOptions={Currency}
                    bdLabel="Currency"
                    bdValue={displayUser?.SubscriptionCurrencyCode}
                    name="CurrencyCode"
                />
                <BdNumberLimiter
                    bdLabel="User Limit App"
                    name="UserLimit"
                    style={{ marginBottom: "1rem" }}
                    bdMin={0}
                    bdMax={MAX_SUBSCRIPTION_CAPACITY_LIMIT}
                    bdValue={displayUser?.SubscriptionUserLimit ?? 0}
                />
                <BdNumberLimiter
                    bdLabel="User Limit Editor"
                    name="UserLimitAdjust"
                    style={{ marginBottom: "1rem" }}
                    bdMin={0}
                    bdMax={MAX_SUBSCRIPTION_CAPACITY_LIMIT}
                    bdValue={displayUser?.SubscriptionUserLimitAdjust ?? 0}
                />
                <BdNumberLimiter
                    bdLabel="User Limit Edit"
                    name="UserLimitEdit"
                    style={{ marginBottom: "1rem" }}
                    bdMin={0}
                    bdMax={MAX_SUBSCRIPTION_CAPACITY_LIMIT}
                    bdValue={displayUser?.SubscriptionUserLimitEdit ?? 0}
                />
                <BdNumberLimiter
                    bdLabel="Case Limit"
                    name="CaseLimit"
                    style={{ marginBottom: "1rem" }}
                    bdMin={0}
                    bdMax={MAX_SUBSCRIPTION_CAPACITY_LIMIT}
                    bdValue={displayUser?.SubscriptionCaseLimit ?? 0}
                />
                <BdNumberLimiter
                    bdLabel="Document Limit"
                    name="DocumentLimit"
                    style={{ marginBottom: "1rem" }}
                    bdMin={0}
                    bdMax={MAX_SUBSCRIPTION_CAPACITY_LIMIT}
                    bdValue={displayUser?.SubscriptionDocumentLimit ?? 0}
                />
                <BdTextBox
                    bdLabel="Account"
                    name="AccountNumber"
                    style={{ marginBottom: "1rem" }}
                    bdValue={displayUser?.AccountNumber}
                />
                <BdTextBox
                    bdLabel="Company"
                    name="Company"
                    style={{ marginBottom: "1rem" }}
                    bdValue={displayUser?.Company}
                />
                <BdSelector
                    bdOptions={Country}
                    bdLabel="Country"
                    bdValue={displayUser?.CountryCode}
                    name="CountryCode"
                />
            </BdModal>
            {isMultipleUpdate &&
                <BdConfirmModal {...confirmModal.BdModalProps} bdBody={`Are you absolutely sure you want to Edit Subscription Status for ${bdUpdateUsers.length} users`} onConfirm={_handleUserSubscriptionUpdate} />
            }
        </>
    );
};
