import { Activity, AddGroupMemberOptions, Brief, User, UserGroup, UserGroupAuthentication, UserGroupDomain, UserGroupMember, UserGroupSetting } from "../../../modules/bd-models";
import { AnalystDataset } from "../../models/providers/domain/analyst-context/AnalystDataset";
import { BdApiResponse, BdServiceBase, BdServiceConfig } from "../bd-service-base";
import IBdServiceGroups from "./interfaces/IBdServiceGroups";

/**
 * Group Service
 * */
export class BdServiceGroups extends BdServiceBase implements IBdServiceGroups {
    constructor(configuration: BdServiceConfig) {
        super(configuration);
    }

    private static ApiUrl = "/api/v1/groups";

    private readonly _userGroupArea = {
        MemberBriefs: "group.members.briefsshared"
    };

    GetActivities(group: UserGroup): Promise<BdApiResponse<Activity[]>> {
        return super.Get(`${BdServiceGroups.ApiUrl}/${group.PartitionKey}/${group.RowKey}/activitys`);
    }

    AllLazy(): Promise<BdApiResponse<UserGroup[]>> {
        return super.Get(`${BdServiceGroups.ApiUrl}/lazy`);
    }

    GetMembersAnalyst(group: UserGroup): Promise<BdApiResponse<AnalystDataset>> {
        return super.Get(`${BdServiceGroups.ApiUrl}/${group.PartitionKey}/${group.RowKey}/members/analyst`);
    }

    RefreshMembersAnalyst(group: UserGroup): Promise<BdApiResponse<AnalystDataset>> {
        return super.Post(`${BdServiceGroups.ApiUrl}/${group.PartitionKey}/${group.RowKey}/members/analyst`);
    }

    GetMembers(group: UserGroup): Promise<BdApiResponse<UserGroupMember[]>> {
        return super.Get(`${BdServiceGroups.ApiUrl}/${group.PartitionKey}/${group.RowKey}/members`);
    }

    LoadGroupForMember(userGroup: UserGroup, userGroupMember: UserGroupMember): Promise<BdApiResponse<User[]>> {
        return super.Get(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/members/${userGroupMember.PartitionKey}/${userGroupMember.RowKey}`);
    }

    DeleteMember(userGroup: UserGroup, userGroupMember: UserGroupMember): Promise<BdApiResponse<UserGroup>> {
        return super.Delete(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/members/${userGroupMember.RowKey}`);
    }

    ResendMemberInvite(userGroup: UserGroup, userGroupMember: UserGroupMember): Promise<BdApiResponse<UserGroup>> {
        return super.Post(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/members/${userGroupMember.RowKey}/resend`);
    }

    UpdateUserGroupMember(userGroup: UserGroup, userGroupMember: UserGroupMember): Promise<BdApiResponse<UserGroup>> {
        return super.Put(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/members/${userGroupMember.RowKey}`, userGroupMember);
    }

    UpdateUserGroupMemberRole(userGroup: UserGroup, userGroupMember: UserGroupMember, userGroupMemberRole: number): Promise<BdApiResponse<UserGroup>> {
        return super.Put(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/members/${userGroupMember.RowKey}/role/${userGroupMemberRole}`);
    }

    AddDomain(userGroup: UserGroup, userGroupDomain: UserGroupDomain): Promise<BdApiResponse<UserGroup>> {
        return super.Post(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/domains`, userGroupDomain);
    }

    DeleteDomain(userGroup: UserGroup, userGroupDomain: UserGroupDomain): Promise<BdApiResponse<UserGroup>> {
        return super.Delete(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/domains/${userGroupDomain.RowKey}`);
    }

    UpdateDomain(userGroup: UserGroup, userGroupDomain: UserGroupDomain): Promise<BdApiResponse<UserGroup>> {
        return super.Put(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/domains/${userGroupDomain.RowKey}`, {
            IsCreateAccountByEmailEnabled: userGroupDomain.IsCreateAccountByEmailEnabled,
            IsCreateAccountByFederatedDiscoveryEnabled: userGroupDomain.IsCreateAccountByFederatedDiscoveryEnabled
        });
    }

    VerifyDomain(userGroup: UserGroup, userGroupDomain: UserGroupDomain): Promise<BdApiResponse<UserGroup>> {
        return super.Put(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/domains/${userGroupDomain.RowKey}/verify`);
    }

    AddUserGroupAuthentication(userGroup: UserGroup, userGroupAuthentication: UserGroupAuthentication): Promise<BdApiResponse<UserGroup>> {
        return super.Post(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/authentications`, userGroupAuthentication);
    }

    DeleteUserGroupAuthentication(userGroup: UserGroup, userGroupAuthenticationRowKey: string): Promise<BdApiResponse<UserGroup>> {
        return super.Delete(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/authentications/${userGroupAuthenticationRowKey}`);
    }

    UpdateUserGroupAuthentication(userGroup: UserGroup, userGroupAuthentication: UserGroupAuthentication): Promise<BdApiResponse<UserGroup>> {
        return super.Put(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/authentications/${userGroupAuthentication.RowKey}`, userGroupAuthentication);
    }

    AddUserGroupSetting(userGroup: UserGroup, userGroupSetting: UserGroupSetting): Promise<BdApiResponse<UserGroup>> {
        return super.Post(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/settings`, userGroupSetting);
    }

    DeleteUserGroupSetting(userGroup: UserGroup, userGroupSettingRowKey: string): Promise<BdApiResponse<UserGroup>> {
        return super.Delete(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/settings/${userGroupSettingRowKey}`);
    }

    UpdateUserGroupSetting(userGroup: UserGroup, userGroupSetting: UserGroupSetting): Promise<BdApiResponse<UserGroup>> {
        return super.Put(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/settings/${userGroupSetting.RowKey}`, userGroupSetting);
    }

    ApplyUserGroupSetting(userGroup: UserGroup, userGroupSettingRowKey: string): Promise<BdApiResponse<UserGroup>> {
        return super.Put(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/settings/${userGroupSettingRowKey}/apply`);
    }

    LoadGroup(userGroup: UserGroup): Promise<BdApiResponse<UserGroup>> {
        return super.Get(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}?collapse[]=${this._userGroupArea.MemberBriefs}`);
    }

    DeleteGroup(userGroup: UserGroup): Promise<BdApiResponse<UserGroup>> {
        return super.Delete(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}`);
    }

    UpdateGroup(userGroup: UserGroup): Promise<BdApiResponse<UserGroup>> {
        return super.Put(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}`, userGroup);
    }

    CreateGroupBrief(userGroup: UserGroup, brief: Brief): Promise<BdApiResponse<UserGroup>> {
        return super.Post(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/bundles`, brief);
    }

    DeleteGroupBrief(userGroup: UserGroup, groupBriefRowkey: string): Promise<BdApiResponse<UserGroup>> {
        return super.Delete(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/bundles/${groupBriefRowkey}`);
    }

    ApplyGroupBrief(userGroup: UserGroup, groupBriefRowkey: string): Promise<BdApiResponse<UserGroup>> {
        return super.Put(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/bundles/${groupBriefRowkey}/apply`);
    }

    ToggleGroupBriefDefault(userGroup: UserGroup, groupBriefRowkey: string): Promise<BdApiResponse<UserGroup>> {
        return super.Put(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/bundles/${groupBriefRowkey}/toggle/default`);
    }

    AddGroupMember(userGroup: UserGroup, addGroupMemberOptions: AddGroupMemberOptions): Promise<BdApiResponse<UserGroup>> {
        return super.Post(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/members`, addGroupMemberOptions);
    }

    RevokeGroupMemberCredentials(userGroup: UserGroup, userGroupMember: UserGroupMember): Promise<BdApiResponse<any>> {
        return super.Put(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/members/${userGroupMember.RowKey}/revoke-credentials`);
    }

    RevokeGroupMemberSSO(userGroup: UserGroup, userGroupMember: UserGroupMember): Promise<BdApiResponse<any>> {
        return super.Put(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/members/${userGroupMember.RowKey}/revoke-credentials-sso`);
    }

    RevokeGroupMemberTOTP(userGroup: UserGroup, userGroupMember: UserGroupMember): Promise<BdApiResponse<any>> {
        return super.Put(`${BdServiceGroups.ApiUrl}/${userGroup.PartitionKey}/${userGroup.RowKey}/members/${userGroupMember.RowKey}/revoke-credentials-totp`);
    }
}
