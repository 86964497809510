import { BdActionToastContainer, BdError } from "bundledocs.common.client/modules/bd-components";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import BdAccountNumbersSearch from "./components/bd-account-numbers/bd-account-numbers-search/bd-account-numbers-search";
import BdEnterpriseLicenseSearch from "./components/bd-enterprise-license/bd-enterprise-license-search/bd-enterprise-license-search";
import BdBundlesSearch from "./components/bd-live-bundles/bd-bundles-search";
import { BdLeftMenu } from "./components/bd-navigation/bd-left-menu";
import BdNotificationsSearch from "./components/bd-notifications/bd-notifications-search/bd-notifications-search";
import BdReportsList from "./components/bd-reports/bd-reports-list";
import BdSubscriptionAnalysisSearch from "./components/bd-subscription-analysis/bd-subscription-analysis-search";
import BdUsersSearch from "./components/bd-users/bd-users-search/bd-users-search";
import BundledocsServicesProvider from "./providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "./services/portal-api/bd-bundledocs-portal-api";
function App() {
    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    const [expandSidebar, setExpandSidebar] = useState<boolean>(true);
    const [portalUsername, setPortalUsername] = useState<string>("");

    useEffect(() => {
        _initUser();
    });

    const _initUser = async () => {
        try {
            const portalUser: string = await _bundledocsApiInstance.Session.GetPortalUser();
            if (portalUser) {
                setPortalUsername(portalUser);
            }
        }
        catch {
            //ignore error if we cannot retrieve the user
        }
    };
    const _handleExpandSidebarChange = async (expanded: boolean) => {
        setExpandSidebar(expanded);
    };

    return (
        <>
            <BdActionToastContainer />
            <div id="body">
                <div className={expandSidebar ? "sidebar-left-panel-expand" : "sidebar-left-panel-collapse"}>
                    <BdLeftMenu onExpandSidebarChange={_handleExpandSidebarChange} bdExtended={expandSidebar} bdUsername={portalUsername} />
                </div>
                <div className="sidebar-right-panel">
                    <Routes>
                        <Route path="/" element={<BdUsersSearch />} />
                        <Route path="/users" element={<BdUsersSearch />} />
                        <Route path="/licenses" element={<BdEnterpriseLicenseSearch />} />
                        <Route path="/subscriptions" element={<BdSubscriptionAnalysisSearch />} />
                        <Route path="/account-numbers" element={<BdAccountNumbersSearch />} />
                        <Route path="/reports" element={<BdReportsList />} />
                        <Route path="/notifications" element={<BdNotificationsSearch />} />
                        <Route path="/live-bundles" element={<BdBundlesSearch />} />
                        <Route path="*" element={<BdError bdTitle={404} bdSubtitle="Not Found" />} />
                    </Routes>
                </div>
            </div>
            <div hidden>{"Version: 1.1.0"}</div>
        </>
    );
}

export default App;
