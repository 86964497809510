import { UrlHelper } from "bundledocs.common.client/modules/bd-helpers";
import { BdFormFilter } from "bundledocs.common.client/modules/bd-models";
import { BdAlert, BdAlertType, BdButton, BdButtonType, BdCol, BdGrid, BdIconDisappointed, BdIconIoList, BdMargin, BdRow, useBdModal } from "bundledocs.common.client/src";
import FormatHelper from "bundledocs.common.client/src/helpers/format-helper";
import SearchFilterHelper from "bundledocs.common.client/src/helpers/search-filter-helper";
import React, { useEffect, useState } from "react";
import {
    BdEnterpriseLicenseBean
} from "../../../models/providers/bean/bd-enterprise-license-bean/bd-enterprise-license-bean";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";
import { BdEnterpriseLicenseCreateModal } from "../bd-enterprise-license-actions/bd-enteprise-license-create";
import BdEnterpriseLicenseSearchForm from "./bd-enterprise-license-search-form";
import BdEnterpriseLicenseSearchList from "./bd-enterprise-license-search-list";

export type BdEnterpriseLicenseSearchProps = React.HTMLAttributes<HTMLElement>

//object interface used to manage the alert information
interface AlertData {
    type: BdAlertType,
    show: boolean,
    message: string
}

/**
 * Component that allows to search a license by date range, and list the result in a table
 * @param param0
 */
export default function BdEnterpriseLicenseSearch({
    ...otherProps
}: BdEnterpriseLicenseSearchProps): JSX.Element {
    const _bundleDocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    const startDate: Date = FormatHelper.addYearsToDate(new Date(), -1);
    const [endDate, setEndDate] = useState<Date>(FormatHelper.addYearsToDate(new Date(), 1));
    const [btnSearchWorking, setBtnSearchWorking] = useState<boolean>(false);

    // display the licenses by default rendering
    useEffect(() => {
        const data = {
            dateRangeStart: FormatHelper.dateToStringShort(startDate),
            dateRangeEnd: FormatHelper.dateToStringShort(endDate)
        };
        const filters: BdFormFilter[] = SearchFilterHelper.transformDateRangeToBdFormFilters(data);

        setBtnSearchWorking(true);
        _handleSearch(filters);
    }, []);

    const [alertData, setAlertData] = useState<AlertData>({
        type: BdAlertType.Warning,
        show: false,
        message: ""
    });

    //show the alert
    const _openAlert = (type: BdAlertType, message: string) => {
        setAlertData({
            ...alertData,
            type: type,
            message: message,
            show: true
        });
    };

    //hide the alert
    const _closeAlert = () => {
        setAlertData({
            ...alertData,
            show: false
        });
    };

    const [licenses, setLicenses] = useState<BdEnterpriseLicenseBean[]>([]);

    //executes the search
    const _handleSearch = async (searchData: BdFormFilter[]) => {

        if (searchData.length === 0) {
            return;
        }

        try {
            _closeAlert();

            const filterParameters: string = UrlHelper.createFilterParametersFromBdFormFilters(searchData, "renewaldate");
            const result: BdEnterpriseLicenseBean[] = await _bundleDocsApiInstance.EnterpriseLicenses.Search(filterParameters);

            // need to clean old data before assigning the data
            setLicenses([]);
            setLicenses(result);

            if (!result[0]) {
                _openAlert(BdAlertType.Warning, "No licenses found. Try with another date");
            }
        }
        catch (error: any) {
            setLicenses([]);
            _openAlert(BdAlertType.Danger, error.message);
        }

        setBtnSearchWorking(false);
    };

    //modal managers
    const enterpriseLicenseCreateModal = useBdModal();

    const _handleCreateEnterpriseLicense = (): void => {
        enterpriseLicenseCreateModal.Show();
    };

    const _onEnterpriseLicenseCreate = (license: BdEnterpriseLicenseBean) => {
        // insert new value in front of list of licenses for correct displaying
        licenses.unshift(license);
        setLicenses(licenses);
    };

    return (
        <BdGrid {...otherProps}>
            <BdEnterpriseLicenseSearchForm onSearch={_handleSearch} startDate={startDate} endDate={endDate} setEndDate={setEndDate} btnSearchWorking={btnSearchWorking} />
            <BdRow style={{ justifyContent: "end" }}>
                <BdCol xl={3} lg={3} md={12} sm={12}>
                    <div style={{ margin: "0 0.5rem", textAlign: "center" }}>OR</div>
                    <BdButton type="button"
                        bdType={BdButtonType.Success}
                        bdText="New License"
                        bdBlock={true}
                        onClick={_handleCreateEnterpriseLicense}
                        bdIcon={<BdIconIoList />}
                    />
                    <BdEnterpriseLicenseCreateModal {...enterpriseLicenseCreateModal.BdModalProps} onUpdate={_onEnterpriseLicenseCreate} renewalMaxDate={endDate}></BdEnterpriseLicenseCreateModal>
                </BdCol>
            </BdRow>

            <BdRow className="mt-3">
                <BdCol xl={12} lg={12} md={12} sm={12}>
                    {licenses[0] && <BdEnterpriseLicenseSearchList bdEnterpriseLicences={licenses} style={{ marginTop: BdMargin.Normal }} renewalMaxDate={endDate} />}
                </BdCol>
            </BdRow>

            <BdAlert bdType={alertData.type} bdDissmisable={false} bdShow={alertData.show} onClose={_closeAlert} style={{ marginTop: BdMargin.Normal }}
                bdTitle={
                    <><BdIconDisappointed style={{ marginRight: BdMargin.Small }} />Oops!</>
                }>
                <p>{alertData.message}</p>
            </BdAlert>
        </BdGrid>
    );
}
