/** COMMON BUTTON PROPERTIES **/

export enum BdButtonType {
    Default = 100,
    Primary = 200,
    Success = 300,
    Info = 400,
    Warning = 500,
    Danger = 600,
    Link = 700,   
    Light = 800,
    SimpleIcon = 900,
}

export enum BdButtonSize {
    Normal = 100,
    Large = 200,
    Small = 300
}
