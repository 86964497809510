export enum UserNotificationCategory {
    Bundles = 10,
    Bundles_Generate = 11,
    Analyst_Dataset_UserGroup = 20,
    General = 30,
    Feature = 40,
    Section_Copy = 50
}
export enum UserNotificationState {
    New = 0,
    Read = 10,
    Dismissed = 20
}

export enum UserNotificationType {
    None = 0,
    InProgress = 10,
    Success = 20,
    Error = 30,
}

export interface UserNotification {
    PartitionKey: string;
    RowKey: string;
    Title: string;
    Body: string;
    Category: UserNotificationCategory
    ForeignKey?: string;
    ActionUrl?: string;
    ActionText?: string;
    Created: string;
    Type: UserNotificationType
    State: UserNotificationState
    DateUpdated?: string;
    AssociatedPartitionKey?: string;
    Context: string;
    Batch: string;
    Progress: number | null;
}
