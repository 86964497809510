import { WorkData } from "bundledocs.common.client/modules/bd-models";
import { IBdWorkerService } from "bundledocs.common.client/modules/bd-services";
import { DashboardReportBean } from "../../models/providers/bean/bd-reports/dashboard-report-bean";

/**
 * Reports Service
 * */
export default class BdServiceReports {
    private _workerService: IBdWorkerService;
    private static ApiUrl = "/api/v1/reports";

    constructor(workerService: IBdWorkerService) {
        this._workerService = workerService;
    }

    /**
    * start generating user capacity report
    */
    public async CreateUserCapacityReport(): Promise<WorkData> {
        return this._workerService.CreateWorkRequest(`${BdServiceReports.ApiUrl}/user-subscription-capacity-report`);
    }

    /**
    * start generating managed users report
    */
    public async CreateManagedUsersReport(): Promise<WorkData> {
        return this._workerService.CreateWorkRequest(`${BdServiceReports.ApiUrl}/managed-users-report`);
    }

    /**
    * watch report events
    */
    public async WatchReportEvents(workId: string, successHandler?: (success: DashboardReportBean) => void, cancelHandler?: (cancel: boolean) => void) {
        this._workerService.WatchWorkEvents<DashboardReportBean>(`${BdServiceReports.ApiUrl}/report-worker/${workId}`,
            (success: DashboardReportBean | undefined) => {
                if (successHandler) {
                    successHandler(success as DashboardReportBean);
                }
            }, (cancel: boolean) => {
                if (cancelHandler) {
                    cancelHandler(cancel);
                }
            });
    }

    /**
    * cancel report events
    */
    public async CancelReport(workId: string) {
        this._workerService.CancelWorkRequest(`${BdServiceReports.ApiUrl}/report-worker/${workId}`);
    }
}
