import {
    BdDatePicker,
    BdModal,
    BdTextBox,
    BdTextBoxType,
    useBdToast
} from "bundledocs.common.client/modules/bd-components";
import { BdToastFailureMessage } from "bundledocs.common.client/modules/bd-models";
import { BdIconCalendar } from "bundledocs.common.client/src";
import FormatHelper from "bundledocs.common.client/src/helpers/format-helper";
import React, { useEffect, useState } from "react";
import {
    BdEnterpriseLicenseBean
} from "../../../models/providers/bean/bd-enterprise-license-bean/bd-enterprise-license-bean";
import {
    EnterpriseLicenseOptions
} from "../../../models/providers/enterprise-license-context/enterprise-license-options";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";

interface IBdEnterpriseLicenseCreateModalProps {
    onUpdate?: (license: BdEnterpriseLicenseBean) => void;
    onCancel?: Function;
    bdShow: boolean;
    renewalMaxDate?: Date;
}

/** Modal for creating the enterprise license */
export const BdEnterpriseLicenseCreateModal = ({
    onUpdate,
    onCancel,
    bdShow,
    renewalMaxDate = new Date()
}: IBdEnterpriseLicenseCreateModalProps) => {
    const [updating, setUpdating] = useState(false);

    //toast functions
    const { toastSuccess, toastError, toastWarning } = useBdToast();

    // date range
    const defaultDateFormat = "yyyy-MM-dd";
    const maxRenewalDate = FormatHelper.addYearsToDate(new Date(), 10);

    const [renewalDate, setRenewalDate] = useState<Date>(FormatHelper.addYearsToDate(new Date(), 1));

    const _updateRenewalDate = (date: Date) => {
        setRenewalDate(date);
    };

    const defaultBdEnterpriseLicenseBean: BdEnterpriseLicenseBean = {
        Organization: "Bundledocs",
        MaxUsers: 5,
        MaxBriefs: 5,
        RenewalDate: renewalDate.toDateString(),
        Email: "support@bundledocs.com",
        VersionNumber: "1.125.0-bd",
        Licensekey: "",
        PartitionKey: "",
        RowKey: ""
    };

    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    /** set updating=false on close */
    useEffect(() => {
        if (!bdShow) {
            setUpdating(false);
        }
    }, [bdShow]);

    /** create enterprise license */
    const _handleEnterpriseLicenseCreate = async (licenseOptions: EnterpriseLicenseOptions): Promise<boolean> => {

        let handleResult = true;

        try {
            setUpdating(true);

            const licenceResult: BdEnterpriseLicenseBean = await _bundledocsApiInstance.EnterpriseLicenses.CreateEnterpriseLicense(licenseOptions);

            if (licenceResult?.PartitionKey?.length > 0) {
                toastSuccess("User's Enterprise License was created successfully");

                if (new Date(renewalMaxDate) >= new Date(licenceResult.RenewalDate)) {
                    onUpdate?.(licenceResult);
                }

                onUpdate?.(licenceResult);
            }
            else {
                toastWarning("This license already exists");
            }

            onCancel?.();

            handleResult = true;
        }
        catch (error: any) {
            toastError(error as BdToastFailureMessage);
            handleResult = false;
        }
        finally {
            setUpdating(false);
        }

        return handleResult;
    };

    return (
        <BdModal bdTitle="Create Enterprise License" bdDisabled={updating} onConfirm={_handleEnterpriseLicenseCreate}
            onCancel={onCancel} bdShow={bdShow} >
            <BdTextBox
                bdLabel="Organization"
                name="Organization"
                style={{ marginBottom: "1rem" }}
                bdValue={defaultBdEnterpriseLicenseBean?.Organization}
            />
            <BdTextBox
                bdLabel="Max Users"
                name="MaxUsers"
                style={{ marginBottom: "1rem" }}
                bdValue={defaultBdEnterpriseLicenseBean?.MaxUsers}
                bdTextBoxType={BdTextBoxType.Number}
            />
            <BdTextBox
                bdLabel="Max Briefs"
                name="MaxBriefs"
                style={{ marginBottom: "1rem" }}
                bdValue={defaultBdEnterpriseLicenseBean?.MaxBriefs}
                bdTextBoxType={BdTextBoxType.Number}
            />

            <BdDatePicker
                bdLabel="Renewal Date"
                name="RenewalDate"
                id="dateRangeStart"
                bdIcon={<BdIconCalendar />}
                dateFormat={defaultDateFormat}
                bdValue={renewalDate}
                bdMax={maxRenewalDate}
                wrapperClassName="dateRangeStartPicker"
                onUpdate={_updateRenewalDate}
            />

            <BdTextBox
                bdLabel="Version Number"
                name="VersionNumber"
                style={{ marginBottom: "1rem" }}
                bdValue={defaultBdEnterpriseLicenseBean?.VersionNumber}
            />

            <BdTextBox
                bdLabel="Email"
                name="Email"
                style={{ marginBottom: "1rem" }}
                bdValue={defaultBdEnterpriseLicenseBean?.Email}
            />
        </BdModal>
    );
};
