import cx from "classnames";
import React, { ButtonHTMLAttributes } from "react";
import Button from "react-bootstrap/Button";
import { OverlayTriggerType } from "react-bootstrap/esm/OverlayTrigger";
import { Placement } from "react-bootstrap/esm/types";
import { BdConfirmModal } from "../bd-common/bd-confirm-modal";
import BdTooltip from "../bd-common/bd-tooltip";
import { useBdModal } from "../bd-modal/bd-modal";
import { BdButtonSize, BdButtonType } from "./bd-buttons-constants";
import BdButtonStyle from "./buttons.module.scss";

/**
 * All purpose button, it can be composed by another JSX.Element (i.e. icon, image...) and also a text
 * */
export interface BdButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    /** Button properties */
    bdIcon?: JSX.Element,
    bdText?: string,
    bdType?: BdButtonType,
    bdSize?: BdButtonSize,
    bdBlock?: boolean,
    /** Button events */
    onBdClick?: React.MouseEventHandler<HTMLButtonElement>,

    /** Tooltip properties, if not title nor body. Tooltip will not be showed */
    bdTooltipTitle?: string,
    bdTooltipBody?: string,
    bdTooltipPlacement?: Placement,
    bdTooltipDelay?: number,
    bdTooltipTrigger?: OverlayTriggerType[],

    /** Modal Confirm properties */
    bdConfirmTitle?: string,
    bdConfirmMessage?: string
}

/**
 * Base button class
 * @param props
 */
const BdButton = ({
    /** button properties **/
    bdIcon,
    bdText,
    bdType,
    bdSize,
    disabled,
    bdBlock = true,
    onClick,
    /** tooltip properties **/
    bdTooltipTitle = "",
    bdTooltipBody = "",
    bdTooltipDelay = 800,
    bdTooltipPlacement,
    bdTooltipTrigger,
    /** confirm modal properties **/
    bdConfirmTitle,
    bdConfirmMessage,
    /** other properties **/
    className = "",
    ...otherProps
}: BdButtonProps) => {
    const isModalConfirmNeeded: boolean = !!bdConfirmMessage || !!bdConfirmTitle;
    const ButtonConfirmModal = useBdModal();
    /**
     * Handles the click from the button. Launch the modal or the click event
     * @param pointerEvent: click event
     */
    const handleClick = (pointerEvent: any) => {
        if (isModalConfirmNeeded) {
            ButtonConfirmModal.Show();
        }
        else {
            executeOnClick(pointerEvent);
        }
    };

    /**
     * Executes the click event
     * @param pointerEvent
     */
    const executeOnClick = (pointerEvent: any) => {
        if (onClick) {
            onClick(pointerEvent);
        }
    };

    /**
     * Mapping between our types and bootstrap3 types
     * */
    const buttonType = (): string | undefined => {
        switch (bdType) {
            case BdButtonType.Primary:
                return "primary";
            case BdButtonType.Success:
                return "success";
            case BdButtonType.Info:
                return "info";
            case BdButtonType.Warning:
                return "warning";
            case BdButtonType.Danger:
                return "danger";
            case BdButtonType.Link:
            case BdButtonType.SimpleIcon:
                return "link";
            case BdButtonType.Light:
                return "light";
            case BdButtonType.Default:
            default:
                return undefined;
        }
    };

    /**
     * Mapping between our sizes and bootstrap3 types
     * */
    const buttonSize = (): "sm" | "lg" | undefined => {
        switch (bdSize) {
            case BdButtonSize.Large:
                return "lg";
            case BdButtonSize.Small:
                return "sm";
            case BdButtonSize.Normal:
            default:
                return undefined;
        }
    };

    /** base button definition */
    const button = (
        <Button className={cx((bdText) ? className + " " + BdButtonStyle.bdIconWithText : className, BdButtonStyle.bdButtonOverride, bdType === BdButtonType.SimpleIcon ? BdButtonStyle.bdSimpleIcon : "")} aria-label={bdTooltipBody} onClick={handleClick} variant={buttonType()} size={buttonSize()} {...otherProps} disabled={disabled} >
            {bdIcon}
            {bdText &&
                <span>{bdText}</span>}
        </Button>
    );

    let result: JSX.Element = button;

    //set a full width button
    if (bdBlock) {
        result = (
            <div className={cx("d-grid gap-2", bdType === BdButtonType.SimpleIcon ? BdButtonStyle.bdSimpleIconWrapper : "")}>
                {button}
            </div>
        );
    }

    //wrap the button with the tooltip if information is provided 
    if (bdTooltipTitle.trim() || bdTooltipBody.trim()) {
        result = (
            <BdTooltip bdTitle={bdTooltipTitle} bdBody={bdTooltipBody} bdDelay={bdTooltipDelay} bdPlacement={bdTooltipPlacement} >
                {result}
            </BdTooltip>
        );
    }

    //add modal confirmation if needed
    if (isModalConfirmNeeded) {
        result = (
            <>
                {result}
                <BdConfirmModal {...ButtonConfirmModal.BdModalProps} bdTitle={bdConfirmTitle} bdBody={bdConfirmMessage} onConfirm={executeOnClick} />
            </>
        );
    }

    return result;
};

export default BdButton;
