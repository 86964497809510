import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Placement } from "react-bootstrap/esm/types";
import Popover from "react-bootstrap/Popover";
import Tooltip from "react-bootstrap/Tooltip";

export interface BdTooltipProps {
    /** Tooltip properties */
    bdTitle?: string,
    bdBody?: string,
    bdPlacement?: Placement,
    bdDelay?: number,

    /** Element that has the tooltip */
    children: JSX.Element,
}

/**
 * Tooltip for all components. If it has title and body it shows a popover, otherwise it shows a simple tooltip
 * @param param0
 */
export default function BdTooltip({
    bdTitle = "",
    bdBody = "",
    bdPlacement = "auto",
    bdDelay = 0,
    children
}: BdTooltipProps) : JSX.Element {
    const renderTooltip = (
        bdTitle && bdBody
            ? <Popover>
                {bdTitle && <Popover.Header as="h3">bdTitle</Popover.Header>}
                {bdBody && <Popover.Body>{bdBody}</Popover.Body>}
            </Popover>
            : <Tooltip>
                {bdBody ?? bdTitle}
            </Tooltip>
    );

    return (
        <OverlayTrigger
            placement={bdPlacement}
            overlay={renderTooltip}
            delay={{ show: bdDelay, hide: 0 }}
        >
            {children}
        </OverlayTrigger>
    );
}
