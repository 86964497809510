export interface BdEnum {
    name?: string,
    code: string | number,
    description: string,
    groupBy?: string,
    tip?: string,
    icon?: string
}

export type BdEnumArray = Array<BdEnum>;

export class BdEnums {

    static Instance: BdEnums = new BdEnums();

    AccessTypes: BdEnumArray = [
        { name: "CASE", code: 0, description: "BUNDLE", tip: "Choose BUNDLE for new document bundles and e-binders.", },
        { name: "TEMPLATE", code: 1, description: "TEMPLATE", tip: "Advanced. New bundles and e-binders can be created from templates.", }
    ];

    CusomIndexGroup: BdEnumArray = [
        { name: "FIRST", code: 0, description: "First" },
        { name: "SECOND", code: 1, description: "Second" },
        { name: "THIRD", code: 2, description: "Third" },
        { name: "FOURTH", code: 3, description: "Fourth" },
        { name: "FIFTH", code: 4, description: "Fifth" },
    ];

    PlaceholderFileTypes: BdEnumArray = [
        //list of file types that can be used as placeholders
        { name: "EXCEL", code: 11, description: "Excel", tip: "Excel", },
    ];

    SourceAndZipFileTypes: BdEnumArray = [
        //list of file types that can be used with source and zip
        { name: "EXCEL", code: 11, description: "Excel", tip: "Excel", },
        { name: "VIDEO", code: 12, description: "Video", tip: "Video", },
        { name: "AUDIO", code: 13, description: "Audio", tip: "Audio", },
    ];

    FontTypes: BdEnumArray = [
        { name: "COURIER", code: "COURIER", description: "Courier", groupBy: "COURIER" }, //code: 0
        { name: "COURIER_BOLD", code: "COURIER_BOLD", description: "Courier (bold)", groupBy: "COURIER" }, //code: 1
        { name: "COURIER_ITALIC", code: "COURIER_ITALIC", description: "Courier (italic)", groupBy: "COURIER" }, //code: 2

        { name: "TIMES", code: "TIMES", description: "Times", groupBy: "TIMES" }, //code: 3
        { name: "TIMES_BOLD", code: "TIMES_BOLD", description: "Times (bold)", groupBy: "TIMES" }, //code: 4
        { name: "TIMES_ITALIC", code: "TIMES_ITALIC", description: "Times (italic)", groupBy: "TIMES" }, //code:

        { name: "ARIAL", code: "ARIAL", description: "Arial", groupBy: "ARIAL" }, //code: 6
        { name: "ARIAL_BOLD", code: "ARIAL_BOLD", description: "Arial (bold)", groupBy: "ARIAL" }, //code: 7
        { name: "ARIAL_ITALIC", code: "ARIAL_ITALIC", description: "Arial (italic)", groupBy: "ARIAL" }, //code: 8

        { name: "CJK_SANS", code: "CJK_SANS", description: "CJK Sans", groupBy: "CJK SANS" }, //code: 9
        { name: "CJK_SANS_BOLD", code: "CJK_SANS_BOLD", description: "CJK Sans (bold)", groupBy: "CJK SANS" }, //code: 10
        { name: "CJK_SANS_ITALIC", code: "CJK_SANS_ITALIC", description: "CJK Sans (italic)", groupBy: "CJK SANS" }, //code: 11

        { name: "CJK_SERIF", code: "CJK_SERIF", description: "CJK Serif", groupBy: "CJK SERIF" }, //code: 12
        { name: "CJK_SERIF_BOLD", code: "CJK_SERIF_BOLD", description: "CJK Serif (bold)", groupBy: "CJK SERIF" }, //code: 13
        { name: "CJK_SERIF_ITALIC", code: "CJK_SERIF_ITALIC", description: "CJK Serif (italic)", groupBy: "CJK SERIF" }, //code: 14
    ];

    FontSizes: BdEnumArray = [
        { name: "PT10", code: "PT10", description: "10pt", groupBy: "SMALL" }, //code: 0
        { name: "EXTRA_SMALL", code: "EXTRA_SMALL", description: "11.25pt", groupBy: "SMALL" }, //code: 1

        { name: "SMALL", code: "SMALL", description: "12pt", groupBy: "MEDIUM" }, //code: 2
        { name: "PT14", code: "PT14", description: "14pt", groupBy: "MEDIUM" }, //code: 3
        { name: "MEDIUM", code: "MEDIUM", description: "16pt", groupBy: "MEDIUM" }, //code: 4

        { name: "PT18", code: "PT18", description: "18pt", groupBy: "LARGE" }, //code: 5
        { name: "LARGE", code: "LARGE", description: "22pt", groupBy: "LARGE" } //code: 6
    ];

    NetDocumentsDomainEndpoints: BdEnumArray = [
        { name: "api.au.netdocuments.com", code: 0, description: "Australia" },
        { name: "api.eu.netdocuments.com", code: 1, description: "Europe" },
        { name: "api.vault.netvoyage.com", code: 2, description: "North America" }
    ];

    ClioDomainEndpoints: BdEnumArray = [
        { name: "0", code: 0, description: "North America" },
        { name: "10", code: 10, description: "Europe" },
        { name: "20", code: 20, description: "Canada" },
        { name: "30", code: 30, description: "Australia" }
    ];

    IkenDomainEndpoints: BdEnumArray = [
        { name: "https://iken.cloud", code: 0, description: "Iken.Cloud" },
        { name: "https://apidemo.iken.cloud", code: 1, description: "Iken.Cloud (Trial)" }
    ];

    SmokeballDomainEndpoints: BdEnumArray = [
        { name: "Australia", code: 0, description: "Australia" },
        { name: "Europe", code: 10, description: "Europe" },
        { name: "North America", code: 20, description: "North America" },
    ];

    AccessLevels: BdEnumArray = [
        { name: "Analyst", code: 5, description: "Analyst", tip: "Can download converted documents only but cannot upload, update, generate, migrate, or delete", icon: "fa fa-id-card-o" },//Public Download Link
        { name: "Downloader", code: 10, description: "Downloader", tip: "Can download converted documents and completed bundles but cannot upload, update, generate, migrate, or delete", icon: "fa fa-download text-info" },//Public Download Link
        { name: "Contributor", code: 20, description: "Contributor", tip: "Can download, upload, update, and generate but cannot migrate, or delete", icon: "fa fa-users text-default" },//Work at the Document Level
        { name: "Owner", code: 30, description: "Owner", tip: "Can download, upload, update, generate, migrate, and delete", icon: "fa fa-user text-danger" },//Work at the Bundle Level
    ];

    RestrictionTypes: BdEnumArray = [
        { name: "NoRestriction", code: 0, description: "No Restriction" },
        { name: "Date", code: 100, description: "Date Restriction" }
    ];

    UserGroupRoles: BdEnumArray = [
        { name: "TEAM", code: 0, description: "Team", tip: "Bundles are shared with the other team members", icon: "fa fa-users text-default" },
        { name: "MANAGEMENT", code: 1, description: "Management", tip: "Bundles are only visible to the owner", icon: "fa fa-list-ul text-danger" }
    ];

    UserGroupMemberRoles: BdEnumArray = [
        { name: "OWNER", code: 0, description: "Owner" },
        { name: "MEMBER", code: 1, description: "Member" },
        { name: "INVITED", code: 2, description: "Invited" },
        { name: "ADMIN", code: 3, description: "Admin" },
    ];

    UserGroupAuthenticationRoles: BdEnumArray = [
        { name: "SAML2", code: 0, description: "Saml2", tip: "SAML 2.0 enables web-based authentication and authorization scenarios including cross-domain single sign-on (SSO)", icon: "fa fa-users text-danger" }
    ];

    UserGroupCollaborationTypes: BdEnumArray = [
        { name: "STANDARD", code: 0, description: "Standard", tip: "Allow collaborations with internal and external members", icon: "fa fa-users" },
        { name: "MANAGEMENT_GROUP", code: 100, description: "Management Group", tip: "Limit collaborations to management group members", icon: "fa fa-users" }
    ];

    UserGroupSettingRoles: BdEnumArray = [
        { name: "Brief", code: 0, description: "Bundle", tip: "Brief settings role allows for storage of default bundle settings.", icon: "fa fa-users" }
    ];

    UserGroupSettingUserRoles: BdEnumArray = [
        { name: "APPLICATION_USER", code: 0, description: "Application User", tip: "An application user can authenticate with their account password.", icon: "fa fa-users text-default" },
        { name: "FEDERATED_USER", code: 3, description: "Federated User", tip: "A federated user can only authenticate via a group authentication.", icon: "fa fa-users text-default" }
    ];

    BriefReceiptOcrStatus: BdEnumArray = [
        { name: "NO_STATUS", code: 0, description: "No Status" },
        { name: "QUEUED", code: 1, description: "Queued" },
        { name: "IN_PROGRESS", code: 2, description: "In Progress" },
        { name: "COMPLETE", code: 3, description: "Complete" },
        { name: "FAILED", code: 4, description: "Failed" }
    ];

    BriefDocumentAnnotationContentTypes: BdEnumArray = [
        { name: "REDACT", code: 2, description: "Redact", tip: "Redact a portion of the page", icon: "fa fa-eye-slash" },
        { name: "HIGHLIGHT", code: 3, description: "Highlight", tip: "Highlight a portion of the page", icon: "fa fa-eraser" },
        { name: "NOTE", code: 4, description: "Note", tip: "Add a note to the pdf page", icon: "fa fa-pencil" },
        { name: "LABEL", code: 5, description: "Label", tip: "Add a label to the pdf page", icon: "fa fa-circle" }
    ];

    BriefAnnotationExclusionPolicies: BdEnumArray = [
        { name: "GLOBAL", code: 0, description: "Global - all annotations", tip: "Show the annotations of all users", icon: "fa" },
        { name: "ME", code: 2, description: "Me - my annotations", tip: "Do not show the annotations of others", icon: "fa" },
        { name: "POLICY", code: 1, description: "Policy - by user", tip: "Show the annotations of those users included in the annotation rules", icon: "fa" }
    ];

    BriefBlobStorageAccountNames: BdEnumArray = [
        { name: "DEFAULT", code: "briefbuilder", description: "Legacy - standard storage for legacy bundles", tip: "Legacy storage is slower than Accelerated storage", icon: "fa" },
        { name: "BUNDLEDOCS", code: "bundledocs", description: "Accelerated - premium storage with faster retrieval", tip: "Accelerated storage is faster than Legacy storage", icon: "fa" },
        { name: "BUNDLEDOCS_AUSTRALIA_EAST", code: "bundledocs0au0east", description: "Australia East - regional storage with documents at-rest in Australia East", tip: "Australian East storage is slower than Accelerated storage", icon: "fa" },
        { name: "BUNDLEDOCS_AUSTRALIA_CENTRAL", code: "bundledocs0au0central", description: "Australia Central - regional storage with documents at-rest in Australia Central", tip: "Australian Central storage is slower than Accelerated storage", icon: "fa" },
        { name: "BUNDLEDOCS_UK_SOUTH", code: "bundledocs0uk0south", description: "United Kingdom South - regional storage with documents at-rest in United Kingdom South", tip: "United Kingdom South storage is slower than Accelerated storage", icon: "fa" },
        { name: "BUNDLEDOCS_US_EAST", code: "bundledocs0us0east", description: "United States East - regional storage with documents at-rest in United States East", tip: "United States East storage is slower than Accelerated storage", icon: "fa" },
        { name: "BUNDLEDOCS_CANADA_CENTRAL", code: "bundledocs0ca0central", description: "Canada Central - regional storage with documents at-rest in Canada Central", tip: "Canada Central storage is slower than Accelerated storage", icon: "fa" },
    ];

    DocumentDescriptionSources: BdEnumArray = [
        { name: "DESCRIPTION", code: 0, description: "Description", tip: "The description of the associated document.", icon: "fa" },
        { name: "CUSTOM_COLUMN_TEXT", code: 100, description: "Custom Column Text", tip: "The defined custom column text of the associated document.", icon: "fa" },
        { name: "CUSTOM_COLUMN_TEXT_ELSE_DESCRIPTION", code: 200, description: "Custom Column Text (or Description)", tip: "The defined custom column text of the associated document or the description when no custom column text has been applied.", icon: "fa" },
        { name: "DISPLAY_ORDER", code: 300, description: "Display Order", tip: "The defined display order of the associated document.", icon: "fa" },
        { name: "SOURCE_CODE", code: 400, description: "Source Code", tip: "Any combination of keys from the Bundledocs Custom Index vocabulary.", icon: "fa" },
    ];

    BookmarkDescriptionSources: BdEnumArray = [
        { name: "DISPLAY_ORDER_DESCRIPTION", code: 0, description: "@Order @Description", tip: "The order, followed by the description of the associated document.", icon: "fa" },
        { name: "DISPLAY_ORDER_DESCRIPTION_PAGE_NUMBER_START", code: 100, description: "@Order @Description - @PageStart", tip: "The order, followed by the description, followed by the starting page number of the associated document.", icon: "fa" },
        { name: "DISPLAY_ORDER_DESCRIPTION_PAGE_NUMBER_START_DATE", code: 200, description: "@Order @Description - @PageStart (@Date)", tip: "The order, followed by the description, followed by the starting page number, followed by the date of the associated document.", icon: "fa" },
        { name: "SOURCE_CODE", code: 300, description: "Source Code", tip: "Any combination of keys from the Bundledocs Custom Index vocabulary.", icon: "fa" },
    ];

    BookmarkDescriptionSectionSources: BdEnumArray = [
        { name: "STANDARD", code: 0, description: "-standard-source-", tip: "The standard section level bookmark description source.", icon: "fa" },
        { name: "DESCRIPTION", code: 100, description: "@Description", tip: "The description of the associated section, sub-section, or volume.", icon: "fa" },
        { name: "SOURCE_CODE", code: 200, description: "Source Code", tip: "Any combination of keys from the Bundledocs Custom Index vocabulary.", icon: "fa" },
    ];

    BookmarkDisplayTypes: BdEnumArray = [
        { name: "DISPLAY_STANDARD", code: 0, description: "All Bookmarks", tip: "Show all bookmarks.", icon: "fa" },
        { name: "DISPLAY_EXCLUSIVE_ONLY", code: 100, description: "Exclusive Bookmarks", tip: "Show those bookmarks from documents with exclusive bookmarks enabled.", icon: "fa" }
    ];

    ZipIndexSources: BdEnumArray = [
        { name: "DEFAULT", code: 0, description: "@Order @Description", tip: "The display order followed by the document name.", icon: "fa" },
        { name: "DESCRIPTION", code: 200, description: "@Description", tip: "The name of the document only, please choose a unique name to avoid conflicts.", icon: "fa" },
        { name: "SECTION_DISPLAY_ORDER_PAGE_NUMBER_BATES_START", code: 100, description: "@OrderSection @PageNumberBatesStart", tip: "The section order, followed by the starting page number in bates format.", icon: "fa" },
    ];

    ZipIndexSourceSections: BdEnumArray = [
        { name: "DEFAULT", code: 0, description: "@Order @Description", tip: "The display order followed by the section name.", icon: "fa" },
        { name: "DESCRIPTION", code: 200, description: "@Description", tip: "The name of the section only.", icon: "fa" },
    ];

    ZipIndexSourceContainers: BdEnumArray = [
        { name: "DEFAULT", code: 0, description: "@Code", tip: "The code of the bundle.", icon: "fa" },
        { name: "NONE", code: 100, description: "None", tip: "No container will be used.", icon: "fa" },
    ];

    PageNumberStyles: BdEnumArray = [
        { name: "ROMAN_LOWER", code: 0, description: "i, ii, iii...", tip: "Use roman numeral numbering in lower case for index pages.", icon: "fa" },
        { name: "ROMAN_UPPER", code: 100, description: "I, II, III...", tip: "Use roman numeral numbering in upper case for index pages.", icon: "fa" },
        { name: "ARABIC", code: 200, description: "1, 2, 3...", tip: "Use  Numeric for index pages.", icon: "fa" },
        { name: "ALPHABETIC_LOWER", code: 300, description: "a, b, c...", tip: "Use Alphabetical in lower case for index pages.", icon: "fa" },
        { name: "ALPHABETIC_UPPER", code: 400, description: "A, B, C...", tip: "Use Alphabetical in upper case for index pages.", icon: "fa" },
    ];

    ZipIndexHyperlinkTypes: BdEnumArray = [
        { name: "WEB", code: 0, description: "Web Link", tip: "A web link is compatible with all PDF players including web browsers.", icon: "fa" },
        { name: "EXECUTE", code: 100, description: "Executable Link", tip: "An executable link is only compatible with desktop based PDF players.", icon: "fa" },
    ];

    PdfAComplianceLevels: BdEnumArray = [
        { name: "INHERIT", code: 0, description: "-standard-compliance-", tip: "PDF/A Compliance is inherited from the documents.", icon: "fa" },
        { name: "PDF_A_1A", code: 100, description: "PDF/A-1a", tip: "Level A (accessible) conformance.", icon: "fa" },
        { name: "PDF_A_1A_FLATTENED", code: 150, description: "PDF/A-1a (flattened)", tip: "Level A (accessible) conformance. Flattening removes clickable links.", icon: "fa" },
        { name: "PDF_A_1B", code: 200, description: "PDF/A-1b", tip: "Level B (basic) conformance.", icon: "fa" },
    ];

    XRefAltSources: BdEnumArray = [
        { name: "NONE", code: 0, description: "None", tip: "No Cross-Reference replacement is carried out.", icon: "fa" },
        { name: "XREF", code: 100, description: "Cross-Reference", tip: "The name of each Cross-Reference is replaced with the Cross-Reference value.", icon: "fa" },
        { name: "XREF_ALT", code: 200, description: "Cross-Reference Name", tip: "The name of each Cross-Reference is replaced with the Cross-Reference Name value.", icon: "fa" },
        { name: "BATES_PAGE_NUMBER", code: 300, description: "Page Number Bates", tip: "The name of each Cross-Reference is replaced with its relative Bates Number.", icon: "fa" },
    ];

    //brief zoom level
    ZoomLevel: BdEnumArray = [
        { name: "FitInherit", code: -1, description: "Inherit", icon: "fa fa-expand" },
        { name: "FitHeight", code: 0, description: "Fit Height", icon: "fa fa-arrows-v" },
        { name: "FitWidth", code: 1, description: "Fit Width", icon: "fa fa-arrows-h" },
        { name: "Zoom25", code: 25, description: "Zoom 25%", icon: "fa fa-search-plus" },
        { name: "Zoom50", code: 50, description: "Zoom 50%", icon: "fa fa-search-plus" },
        { name: "Zoom75", code: 75, description: "Zoom 75%", icon: "fa fa-search-plus" },
        { name: "Zoom100", code: 100, description: "Zoom 100%", icon: "fa fa-search-plus" }
    ];

    //brief page size
    PageSize: BdEnumArray = [
        { name: "A4", code: 0, description: "A4", icon: "fa" },
        { name: "LETTER", code: 100, description: "Letter", icon: "fa" },
        { name: "LEGAL", code: 200, description: "Legal", icon: "fa" },
        { name: "SOURCE PAGE SIZE", code: 300, description: "Source Page Size", icon: "fa" }
    ];

    //brief date format
    DateFormat: BdEnumArray = [
        { name: "yyyy-MM-dd", description: "yyyy-MM-dd", code: 0 },
        { name: "dd/MM/yyyy", description: "dd/MM/yyyy", code: 1 },
        { name: "MM/dd/yyyy", description: "MM/dd/yyyy", code: 2 },
        { name: "yyyy/MM/dd", description: "yyyy/MM/dd", code: 3 },
        { name: "dd MMMM yyyy", description: "dd MMMM yyyy", code: 4 },
        { name: "Undated", description: "'Undated'", code: 5 },
        { name: "Various", description: "'Various'", code: 6 },
        { name: "empty", description: "' '", code: 7 }
    ];

    BatesPageNumberBehaviors: BdEnumArray = [
        { name: "BATES_NUMBER_ONLY", code: 0, description: "Replace Page Number", tip: "Replace the standard Page Number with the Bates Page Number.", icon: "fa" },
        { name: "BATES_NUMBER_PAGE_NUMBER", code: 100, description: "Separate  Bates Page Number", tip: "Display the standard Page Number and also the Bates Page Number.", icon: "fa" }
    ];

    UserAccessPolicys: BdEnumArray = [
        { name: "DEFAULT", code: 100, description: "-standard-access-policy-", tip: "The standard user access policy is applied.", icon: "fa" },
        { name: "TOTP_REQUIRED", code: 200, description: "Require 2FA", tip: "A user accessing this bundle must have 2FA configured on their account.", icon: "fa" },
        { name: "SSO_REQUIRED", code: 300, description: "Require SSO", tip: "A user accessing this bundle must have SSO configured on their account.", icon: "fa" },
        { name: "TOTP_OR_SSO_REQUIRED", code: 400, description: "Require 2FA or SSO", tip: "A user accessing this bundle must have either 2FA or SSO enabled on their account.", icon: "fa" }
    ];

    UserSubscriptionTypes: BdEnumArray = [
        { name: "FREE", code: 0, description: "Free" },
        { name: "PROFESSIONAL", code: 2, description: "Professional" },
        { name: "MANAGED", code: 100, description: "Managed" },
        { name: "BASIC", code: 1, description: "Basic" },
        { name: "PERFORMANCE", code: 5, description: "Performance" },
        { name: "ULTIMATE", code: 3, description: "Ultimate" },
        { name: "BASIC_V2", code: 1000, description: "BASIC_V2" },
        { name: "PROFESSIONAL_V2", code: 2000, description: "PROFESSIONAL_V2" },
        { name: "ULTIMATE_V2", code: 3000, description: "ULTIMATE_V2" },
        { name: "BASIC_V3", code: 1001, description: "BASIC_V3" },
        { name: "PROFESSIONAL_V3", code: 2001, description: "PROFESSIONAL_V3" },
        { name: "ULTIMATE_V3", code: 3001, description: "ULTIMATE_V3" },
        { name: "GROUP", code: 6, description: "GROUP" },
        { name: "TRIAL_EXPIRED", code: 9, description: "TRIAL_EXPIRED" },
        { name: "CORPORATE", code: 7, description: "CORPORATE" },
        { name: "CORPORATE_GROUP", code: 8, description: "CORPORATE_GROUP" },
        { name: "MANAGED_GROUP", code: 200, description: "MANAGED_GROUP" },
    ];

    UserSubscriptionActivities: BdEnumArray = [
        { name: "FIRST_PAYMENT", code: 0, description: "FirstPayment" },
        { name: "SUBSCRIPTION_RENEWAL", code: 1, description: "SubscriptionRenewal" },
        { name: "SUBSCRIPTION_UPGRADE", code: 2, description: "SubscriptionUpgrade" },
        { name: "SUBSCRIPTION_DOWNGRADE", code: 3, description: "SubscriptionDowngrade" },
        { name: "SUBSCRIPTION_CANCELLED", code: 4, description: "SubscriptionCancelled" },
    ];

    BriefActivities: BdEnumArray = [
        { name: "BRIEF_CREATED", code: 0, description: "Brief Created" },
        { name: "BRIEF_UPDATED", code: 1, description: "Brief Updated" },
        { name: "BRIEF_GENERATE_BEGIN", code: 2, description: "Brief Generate Begin" },
        { name: "BRIEF_GENERATE_END", code: 3, description: "Brief Generate End" },
        { name: "BRIEF_DOWNLOADED", code: 4, description: "Brief Downloaded" },
        { name: "BRIEF_DELETED", code: 5, description: "Brief Deleted" },
        { name: "BRIEF_SAVED_DROPBOX", code: 6, description: "Brief Saved Dropbox" },
        { name: "BRIEF_COPIED", code: 7, description: "Brief Copied" },
        { name: "BRIEF_CREATED_FROM_TEMPLATE", code: 8, description: "Brief Created From Template" },
        { name: "BRIEF_MIGRATION_CREATED", code: 9, description: "Brief Migration Created" },
        { name: "BRIEF_MIGRATION_CANCELLED", code: 10, description: "Brief Migration Cancelled" },
        { name: "BRIEF_MIGRATION_COMPLETED", code: 11, description: "Brief Migration Completed" },
        { name: "BRIEF_COPY_BEGIN", code: 12, description: "Brief Copy Begin" },
        { name: "BRIEF_COPY_FAIL", code: 13, description: "Brief Copy Fail" },
        { name: "BRIEF_COPY_COMPLETE", code: 14, description: "Brief Copy Complete" },
        { name: "BRIEF_GENERATE_OPTIONS_SAVED", code: 15, description: "Brief Generate Options Saved" },
        { name: "BRIEF_DELETE_REQUESTED", code: 16, description: "Brief Delete Requested" },
        { name: "BRIEF_DELETE_RECOVERED", code: 17, description: "Brief Delete Recovered" },
        { name: "BRIEF_GENERATE_CANCELLED", code: 18, description: "Brief Generate Cancelled" },
        { name: "BRIEF_BLOB_STORAGE_ACCOUNT_SWAP_BEGIN", code: 19, description: "Brief Blob Storage Account Swap Begin" },
        { name: "BRIEF_BLOB_STORAGE_ACCOUNT_SWAP_FAIL", code: 20, description: "Brief Blob Storage Account Swap Fail" },
        { name: "BRIEF_BLOB_STORAGE_ACCOUNT_SWAP_COMPLETE", code: 21, description: "Brief Blob Storage Account Swap Complete" },
        { name: "BRIEF_SEAL_BEGIN", code: 22, description: "Brief Seal Begin" },
        { name: "BRIEF_SEAL_FAIL", code: 23, description: "Brief Seal Fail" },
        { name: "BRIEF_SEAL_COMPLETE", code: 24, description: "Brief Seal Complete" },
        { name: "BRIEF_METADATA_UPDATED", code: 25, description: "Brief Metadata Updated" },
    ];

    BriefDocumentActivities: BdEnumArray = [
        { name: "CREATE_BUNDLE_FOLDER", code: 0, description: "Create Bundle Folder" },
        { name: "CREATE_BUNDLE_SECTION", code: 1, description: "Create Bundle Section" },
        { name: "UPLOAD_DOCUMENT", code: 2, description: "Upload Document" },
        { name: "UPLOAD_TITLE_PAGE", code: 3, description: "Upload Title Page" },
        { name: "CONVERT_DOCUMENT_EXECUTE", code: 4, description: "Convert Document Execute" },
        { name: "CONVERT_TITLE_PAGE", code: 5, description: "Convert Title Page" },
        { name: "DELETE_DOCUMENT", code: 6, description: "Delete Document" },
        { name: "DELETE_FOLDER", code: 7, description: "Delete Folder" },
        { name: "DELETE_SECTION", code: 8, description: "Delete Section" },
        { name: "DOWNLOAD_DOCUMENT_PDF", code: 9, description: "Download Document PDF" },
        { name: "DOWNLOAD_DOCUMENT_SRC", code: 10, description: "Download Document SRC" },
        { name: "CREATE_BLANK_DOCUMENT", code: 11, description: "Create Blank Document" },
        { name: "CREATE_DUPLICATE_DOCUMENT", code: 12, description: "Create Duplicate Document" },
        { name: "UPDATE_DOCUMENT", code: 13, description: "Update Document" },
        { name: "UPDATE_DOCUMENT_DESCRIPTION", code: 14, description: "Update Document Description" },
        { name: "UPDATE_DOCUMENT_METADATA", code: 35, description: "Update Document Metadata" },
        { name: "UPDATE_HEADER_DOCUMENT", code: 15, description: "Update Header Document" },
        { name: "DELETE_TITLE_DOCUMENT", code: 16, description: "Delete Title Document" },
        { name: "DELETE_INTRO_DOCUMENT", code: 17, description: "Delete Intro Document" },
        { name: "DELETE_WATERMARK_DOCUMENT", code: 18, description: "Delete Watermark Document" },
        { name: "CREATE_DUPLICATE_SECTION", code: 19, description: "Create Duplicate Section" },
        { name: "ROTATE_DOCUMENT", code: 20, description: "Rotate Document" },
        { name: "DESKEW_DOCUMENT", code: 21, description: "Deskew Document" },
        { name: "REDUCE_DOCUMENT", code: 22, description: "Reduce Document" },
        { name: "BURST_DOCUMENT", code: 23, description: "Burst Document" },
        { name: "MERGE_DOCUMENT", code: 24, description: "Merge Document" },
        { name: "RESET_DOCUMENT", code: 25, description: "Reset Document" },
        { name: "REPLACE_DOCUMENT_SOURCE", code: 36, description: "Replace Document Source" },
        { name: "REPLACE_DOCUMENT", code: 37, description: "Replace Document" },
        { name: "REORDER_DOCUMENT", code: 26, description: "Reorder Document" },
        { name: "SEAL_DOCUMENT", code: 27, description: "Seal Document" },
        { name: "BURST_BOOKMARK_DOCUMENT", code: 28, description: "Burst Bookmark Document" },
        { name: "OCR_DOCUMENT", code: 29, description: "OCR Document" },
        { name: "XREF_VERIFY_DOCUMENT", code: 30, description: "XREF Verify Document" },
        { name: "UPDATE_BATES_STAMP", code: 31, description: "Update Bates Stamp" },
        { name: "UPDATE_CROSS_REFERENCE", code: 32, description: "Update Cross Reference" },
        { name: "BURST_EMAIL_CORRESPONDENCE_DOCUMENT", code: 33, description: "Burst Email Correspondence Document" },
        { name: "REDUCE_LATEST_EMAIL_CORRESPONDENCE_DOCUMENT", code: 34, description: "Reduce Latest Email Correspondence Document" },
        { name: "WORD_ALT_DOCUMENT", code: 35, description: "Word ALT Document" },
        { name: "DOWNLOAD_DOCUMENT_SRC_ALT", code: 36, description: "Download Document SRC ALT" },
        { name: "RESET_DOCUMENT_EXECUTE", code: 904, description: "Reset Document Execute" },
        { name: "ROTATE_DOCUMENT_EXECUTE", code: 920, description: "Rotate Document Execute" },
        { name: "DESKEW_DOCUMENT_EXECUTE", code: 921, description: "Deskew Document Execute" },
        { name: "REDUCE_DOCUMENT_EXECUTE", code: 922, description: "Reduce Document Execute" },
        { name: "BURST_DOCUMENT_EXECUTE", code: 923, description: "Burst Document Execute" },
        { name: "MERGE_DOCUMENT_EXECUTE", code: 924, description: "Merge Document Execute" },
        { name: "REPLACE_DOCUMENT_EXECUTE", code: 925, description: "Replace Document Execute" },
        { name: "REORDER_DOCUMENT_EXECUTE", code: 926, description: "Reorder Document Execute" },
        { name: "SEAL_DOCUMENT_EXECUTE", code: 927, description: "Seal Document Execute" },
        { name: "BURST_BOOKMARK_DOCUMENT_EXECUTE", code: 928, description: "Burst Bookmark Document Execute" },
        { name: "OCR_DOCUMENT_EXECUTE", code: 929, description: "OCR Document Execute" },
        { name: "XREF_VERIFY_DOCUMENT_EXECUTE", code: 930, description: "XREF Verify Document Execute" },
        { name: "BURST_EMAIL_CORRESPONDENCE_DOCUMENT_EXECUTE", code: 933, description: "Burst Email Correspondence Document Execute" },
        { name: "REDUCE_LATEST_EMAIL_CORRESPONDENCE_DOCUMENT_EXECUTE", code: 934, description: "Reduce Latest Email Correspondence Document Execute" },
        { name: "WORD_ALT_DOCUMENT_EXECUTE", code: 935, description: "Word ALT Document Execute" },
    ];

    BriefActivitiesToFollow: BdEnumArray = [
        { name: "BRIEF_UPDATED", code: 1, description: "Bundle Settings Updated" },
        { name: "BRIEF_GENERATE_END", code: 3, description: "Bundle Generate Completed" },
        { name: "BRIEF_DELETE_REQUESTED", code: 16, description: "Bundle Deleted" }
    ];

    GlobalNotificationCategory: BdEnumArray = [
        { name: "GENERAL", code: 30, description: "General" },
        { name: "FEATURE", code: 40, description: "Feature" }
    ];

    BriefDocumentActivitiesToFollow: BdEnumArray = [
        { name: "UPLOAD_DOCUMENT", code: 2, description: "Document Uploaded" },
        { name: "DELETE_DOCUMENT", code: 6, description: "Document Deleted" },
        { name: "REPLACE_DOCUMENT_SOURCE", code: 36, description: "Document Replaced (source)" },
        { name: "REPLACE_DOCUMENT", code: 37, description: "Document Replaced (pdf)" },
    ];

    BundleAvailableToGroups: BdEnumArray = [
        { name: "PUBLIC", code: "public", description: "Available to all of your team groups", tip: "Your bundle will be available to all team groups you are a member of" },
        { name: "PRIVATE", code: "private", description: "Not available to any of your team groups", tip: "Your bundle will Not be available to any team groups you are a member of" },
        { name: "SEMIPRIVATE", code: "semiprivate", description: "Available to selected team groups", tip: "Your bundle will be restricted to one or more selected team groups" }
    ];

}
