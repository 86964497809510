import { BdServiceBase } from "bundledocs.common.client/modules/bd-services";
import { BdUserStripePlanBean } from "../../models/providers/bean/user-portal-bean/bd-user-stripe-plan-bean";

/**
 * Plans Service
 * */
export default class BdServicePlans extends BdServiceBase {
    private static ApiUrl = "/api/v1/plans";

    /**
     * Returns details of stripe plan by id
     * @param stripePlanId: planid
     */
    GetStripePlanById(stripePlanId: string): Promise<BdUserStripePlanBean> {
        return super.Get(`${BdServicePlans.ApiUrl}/${stripePlanId}`);
    }
}
