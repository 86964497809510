import cx from "classnames";
import * as React from "react";
import { MouseEventHandler, ReactNode, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { BdIconUploadFiles } from "../bd-graphics/bd-icons";
import bdStyle from "./bd-dropzone.module.css";

export enum BdDropZoneSize {
    NORMAL = 100,
    LARGE = 200
}

export enum BdDropZoneAcceptedFileTypes {
    PDF = 0,
    DOC = 10,
    DOCX = 11,
    JPG = 12,
    JPEG = 13,
    PNG = 14,
}

export interface IBdDropZoneProps {
    bodyText?: string;
    bdSize?: BdDropZoneSize;
    disabled?: boolean;
    acceptedFileTypes?: BdDropZoneAcceptedFileTypes[],
    allowMultiple?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement> | undefined;
    onDocumentsSelected?: (files: File[]) => void;
    children?: ReactNode;
    bdAreaClass?: string;
    bdAreaDragoverClass?: string;
}

/**
 * Drag and drop area with a default or custom content
 * Click and DragFiles events
 * It allows custom styling for default and drag states
 */
const BdDropZone = ({
    bodyText = "",
    bdSize = BdDropZoneSize.NORMAL,
    acceptedFileTypes,
    allowMultiple = true,
    disabled,
    onClick,
    onDocumentsSelected,
    children,
    bdAreaClass = undefined,
    bdAreaDragoverClass = undefined
}: IBdDropZoneProps) => {
    const onDrop = useCallback(acceptedFiles => {
        onDocumentsSelected?.(acceptedFiles);
    }, []);

    let _acceptedFileTypes: string | string[] | undefined = undefined;
    if (acceptedFileTypes && acceptedFileTypes.length > 0) {
        _acceptedFileTypes = [];
        if (acceptedFileTypes.includes(BdDropZoneAcceptedFileTypes.PDF)) {
            _acceptedFileTypes.push("application/pdf");
        }
        if (acceptedFileTypes.includes(BdDropZoneAcceptedFileTypes.DOCX)) {
            _acceptedFileTypes.push("application/vnd.openxmlformats-officedocument.wordprocessingml.document");
        }
        if (acceptedFileTypes.includes(BdDropZoneAcceptedFileTypes.DOC)) {
            _acceptedFileTypes.push("application/msword");
        }
        if (acceptedFileTypes.includes(BdDropZoneAcceptedFileTypes.JPG)) {
            _acceptedFileTypes.push("image/jpg");
        }
        if (acceptedFileTypes.includes(BdDropZoneAcceptedFileTypes.JPEG)) {
            _acceptedFileTypes.push("image/jpeg");
        }
        if (acceptedFileTypes.includes(BdDropZoneAcceptedFileTypes.PNG)) {
            _acceptedFileTypes.push("image/png");
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        disabled: disabled,
        accept: _acceptedFileTypes,
        multiple: allowMultiple,
        onDrop,
        //@start - legacy support only - candidate for removal
        //#16080
        useFsAccessApi: false
        //@end - legacy support only - candidate for removal
    });

    const inputFilesRef = useRef<HTMLInputElement>(null);

    const getDefaultContent = () => {
        let defaultBodyTitle = bodyText;
        if (!defaultBodyTitle) {
            defaultBodyTitle = allowMultiple
                ? "Browse documents to upload"
                : "Browse documents to upload";
        }
        const defaultBodySubtitle = "or drag and drop";

        let iconSize = 30;
        let bodyContent = (<>
            <h4>{defaultBodyTitle}</h4>
            <h5>{defaultBodySubtitle}</h5>
        </>);
        switch (bdSize) {
            case BdDropZoneSize.LARGE:
                iconSize = 50;
                bodyContent = (<>
                    <h3>{defaultBodyTitle}</h3>
                    <h4>{defaultBodySubtitle}</h4>
                </>);
                break;
        }

        return (
            <>
                <div><BdIconUploadFiles size={iconSize} /></div>
                {bodyContent}
            </>
        );
    };

    //content
    const content = children || getDefaultContent();

    //styling
    const defaultClass: string = bdAreaClass === undefined ? bdStyle.dropzoneArea : bdAreaClass;
    const dragoverClass: string = bdAreaDragoverClass === undefined ? bdStyle.dragover : bdAreaDragoverClass;

    const rootProps = getRootProps();
    //override onclick
    if (onClick) {
        rootProps.onClick = onClick;
    }

    return (
        <div {...rootProps}
            className={cx(
                defaultClass,
                isDragActive && dragoverClass,
                disabled && bdStyle.disabled,
                bdSize === BdDropZoneSize.LARGE && bdStyle.large
            )}
        >
            <input ref={inputFilesRef} {...getInputProps()} />
            {content}
        </div>
    );
};

export default BdDropZone;
