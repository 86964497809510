import {BdFormFilter} from "../models/common/BdFormFilter";

/**
 * Class with common methods related to parsing and generating url
 * */
export default class UrlHelper {
    // generate url string from list of BdFormFilter
    static createFilterParametersFromBdFormFilters(searchData: BdFormFilter[], dateFieldName = "created"): string {
        let filterParameters = "&$filter=";

        searchData.forEach((item, index) => {
            let field = item.field.trim();
            let value = item.value.toLowerCase().trim();

            // according to docs https://learn.microsoft.com/en-us/graph/filter-query-parameter?tabs=http
            // GUID and DateTimeOffset values aren't enclosed in quotes in $filter expressions.
            let quotes = "'";

            const expression = field.toLowerCase() === "dateRangeStart".toLowerCase()
                ? "ge"
                : field.toLowerCase() === "dateRangeEnd".toLowerCase()
                    ? "le"
                    : "eq";

            if (field.toLowerCase() === "dateRangeStart".toLowerCase() || field.toLowerCase() === "dateRangeEnd".toLowerCase()) {
                field = dateFieldName;
                quotes = "";
                // for OData filtering on back-end is huge difference between
                // "created gt 2023-02-20T05:00:00.000Z" (working) and "created gt 2023-02-20t05:00:00.000z" (not working)
                value = value.toUpperCase();
            }

            filterParameters += `${field} ${expression} ${quotes}${value}${quotes}${index !== searchData.length - 1 ? " and " : ""}`;
        });

        return filterParameters;
    }  
}
