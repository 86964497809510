import { BdEnum, BdEnumArray } from "../../models/providers/bean/common-context/bd-enums";

/*
 * Manage mapping from BdEnum to react-select library syntax
 */

/*
 * Generates react-select objects for
 * Single options
        const options = [
            { value: 'v1', label: 'Value 1' },
            { value: 'v2', label: 'Value 2' }
        ];
 * Grouped Options
        const groupedOptions = [
            {
                label: "Group 1",
                options: [
                    { value: 'g1v1', label: 'Group 1 Value 1' },
                    { value: 'g1v2', label: 'Group 1 Value 2' }
                ]
            },
            {
                label: "Group 2",
                options: [
                    { value: 'g2v1', label: 'Group 2 Value 1' },
                    { value: 'g2v2', label: 'Group 2 Value 2' }
                ]
            },
        ];
 */

export interface GroupSelectOptionType {
    label: string,
    options: SingleSelectOptionType[]
}

export interface SingleSelectOptionType {
    value: string,
    label: string,
    name?: string,
    tip?: string,
    icon?: string
}

/** Executes the mapping from array of BdEnum to an array of objects that can be read by react-select*/
export function selectMapper(enumValues: BdEnumArray): SingleSelectOptionType[] | GroupSelectOptionType[] {
    const _mapEnums = (): SingleSelectOptionType[] | GroupSelectOptionType[] => {
        if (enumValues) {
            if (enumValues.length > 0 && enumValues[0].groupBy !== undefined) {
                //enum is a grouped selection option type
                //group enum group by
                const groupedMap: any = enumValues.reduce(
                    (entryMap, e) => entryMap.set(e.groupBy, [...entryMap.get(e.groupBy) || [], e]),
                    new Map()
                );

                //convert to array for easier mapping
                const groupItems = Array.from(groupedMap.entries());

                //apply mapping and return result
                return groupItems.map((groupItem: any) => {
                    if (groupItem[0] !== undefined && groupItem[1] !== undefined) {
                        return {
                            label: groupItem[0],
                            options: groupItem[1].map((groupedOption: any) => {
                                return {
                                    name: groupedOption.name,
                                    label: groupedOption.description,
                                    value: groupedOption.code,
                                    tip: groupedOption.tip,
                                    icon: groupedOption.icon
                                };
                            })
                        };
                    }
                }) as unknown as GroupSelectOptionType[];
            }
            else {
                //enum is a single selection option type
                return enumValues && enumValues?.map(enumItem => {
                    return {
                        name: enumItem.name,
                        label: enumItem.description,
                        value: enumItem.code,
                        tip: enumItem.tip,
                        icon: enumItem.icon
                    };
                }) as unknown as SingleSelectOptionType[];
            }
        }
        else {
            //mapping not available
            return [] as SingleSelectOptionType[];
        }
    };

    return _mapEnums();
}

/** Executes the mapping from a singular BdEnum to SingleSelectOptionType */
export function selectedOptionMapper(enumValue: BdEnum): SingleSelectOptionType {
    const _mapSelectionOption = (): SingleSelectOptionType => {
        return {
            name: enumValue.name,
            label: enumValue.description,
            value: enumValue.code,
            tip: enumValue.tip,
            icon: enumValue.icon
        } as SingleSelectOptionType;
    };

    return _mapSelectionOption();
}
