import { BaseModel } from "../BaseModel";
import { Brief } from "../brief-context/Brief";

export interface User extends BaseModel {
    Email: string;
    EmailGroup: string;
    UserRole: UserRole;

    AdjustBundleRowKey: string;
    DefaultTemplateRowKey?: string;
    IsVisibleToGroups: boolean;

    Metadata: Object;
    
    FeatureStatusEdit: FeatureStatus;
    FeatureStatusAdjust: FeatureStatus;
   
    AccountNumber: string;
    CountryCode: string;
    Company: string;
    BriefBlobStorageAccountName?: string;
    BriefDisplayDateFormat?: string;
    BriefDocumentIndexFontColor?: string;
    BriefDocumentIndexFontSize?: string;
    BriefDocumentIndexFontType?: string;
    BriefIndexCustomColumnHeaderText?: string;
    BriefIndexDisplayDateHeaderText?: string;
    BriefIndexPageNumberHeaderText?: string;
    BriefIndexTitleText?: string;
    BriefLateInsertSeparator?: string;
    BriefPageNumberFontColor?: string;
    BriefPageNumberFontSize?: string;
    BriefPageNumberFontType?: string;
    BriefPageSize?: string;
    BriefSectionBorderColor?: string;
    BriefSectionIndexFontColor?: string;
    BriefSectionIndexFontSize?: string;
    BriefSectionIndexFontType?: string;
    BriefZoomLevel?: string;
    Briefs?: Brief[];
    IsBriefVerifiedBeforeGenerating?: boolean,
    IsSendEmailBriefDeleted?: boolean,
    IsSendEmailBriefGenerated?: boolean,
    IsUsingEmailAttachments?: boolean,
    IsUsingMaxDpiOnGenerate?: boolean,
    IsUsingMaxDpiOnUpload?: boolean,
    IsUsingSourceFileDimensions?: boolean,

    TimeZoneId?:string
}

export const enum UserRole {
    ApplicationUser = 0,
    ApplicationDeveloper = 2,
    FederatedUser = 3
}

export const enum FeatureStatus {
    Disabled = 0,
    DisabledAllowEnable = 100,
    EnabledAllowDisable = 200,
    Enabled = 300
}

export class NewUser implements User {
    PartitionKey = "";
    RowKey = "";

    Email = "";
    EmailGroup = "";
    UserRole: UserRole = UserRole.ApplicationDeveloper;
    AdjustBundleRowKey = "";
    DefaultTemplateRowKey = "";
    IsVisibleToGroups = false;
    Metadata: Object = {};    
    FeatureStatusAdjust: FeatureStatus = FeatureStatus.Disabled;
    FeatureStatusEdit: FeatureStatus = FeatureStatus.Disabled;
    AccountNumber = "";
    CountryCode = "";
    Company = "";
    Briefs: Brief[] = [];

    public static New(): User {
        return new NewUser();
    }

    public static NewWithPartitionKey(partitionKey: string): User {
        const newUser: User = new NewUser();
        newUser.PartitionKey = partitionKey;
        return newUser;
    }
}
