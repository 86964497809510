import * as React from "react";
import { useState } from "react";
import { useBdToast } from "bundledocs.common.client/modules/bd-components";
import { BdModal } from "bundledocs.common.client/modules/bd-components";
import { UserPortalBean } from "../../../models/providers/bean/user-portal-bean/user-portal-bean";
import { BdToastFailureMessage, UserRole } from "bundledocs.common.client/modules/bd-models";
import { BdSelectUserRole } from "./bd-select-user-role";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { UserRoleOptions } from "../../../models/providers/user-context/user-role-options";

interface IBdUserEditRoleModalProps {
    bdUser: UserPortalBean;
    onUpdate?: (user: UserPortalBean) => void;
    onCancel?: Function;
    bdShow: boolean;
}

interface BdUserEditRoleModalState {
    userRole: UserRole
}

/** Modal to manage edit user role */
export const BdUserEditRoleModal = ({
    bdUser,
    onUpdate,
    onCancel,
    bdShow
}: IBdUserEditRoleModalProps) => {
    const [updating, setUpdating] = useState(false);

    //toast functions
    const { toastSuccess, toastError } = useBdToast();

    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    /** set updating=false on close */
    React.useEffect(() => {
        if (!bdShow) {
            setUpdating(false);
        }
    }, [bdShow]);

    /** update user role data */
    const _handleUserRoleUpdate = async (data: BdUserEditRoleModalState): Promise<boolean> => {
        try {
            setUpdating(true);
            if (bdUser && data) {
                const options: UserRoleOptions = {
                    UserRole: data.userRole
                };

                const updatedUserData: UserPortalBean = await _bundledocsApiInstance.Users.UpdateUserRole(bdUser.RowKey as string, options) as UserPortalBean;

                toastSuccess("User Role updated successfully!");
                onUpdate?.(updatedUserData);
                onCancel?.();
            }
            return true;
        }
        catch (error: any) {
            toastError(error as BdToastFailureMessage);
            return false;
        }
        finally {
            setUpdating(false);
        }
    };

    return (
        <BdModal bdTitle="Edit Role" bdDisabled={updating} onConfirm={_handleUserRoleUpdate} onCancel={onCancel} bdShow={bdShow} >
            <BdSelectUserRole
                name="userRole"
                bdLabel={"Role for " + bdUser.Email}
                bdPlaceholder="choose a role..."
                bdValue={bdUser?.UserRole}
                bdClass="margin-b-xs"
            />
        </BdModal>
    );
};
