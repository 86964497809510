import { BdModal, useBdModal, useBdToast } from "bundledocs.common.client/modules/bd-components";
import { BdToastFailureMessage } from "bundledocs.common.client/modules/bd-models";
import { BdConfirmModal } from "bundledocs.common.client/src/components/bd-common/bd-confirm-modal";
import { FeatureStatus } from "bundledocs.common.client/src/models/providers/domain/user-context/User";
import * as React from "react";
import { useRef, useState } from "react";
import { UserPortalBean } from "../../../models/providers/bean/user-portal-bean/user-portal-bean";
import { UserFeatureOptions } from "../../../models/providers/user-context/user-feature-options";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";
import { BdSelectUserFeatureStatus } from "./bd-select-user-feature-status";

interface IBdUserEditFeatureStatusModalProps {
    bdUpdateUsers: UserPortalBean[];
    onUpdate?: (users: UserPortalBean[]) => void;
    onCancel?: Function;
    bdShow: boolean;
}

interface BdUserEditFeatureStatusModalState {
    FeatureStatusAdjust: FeatureStatus,
    FeatureStatusEdit: FeatureStatus,
}

/** Modal to manage edit user role */
export const BdUserEditFeatureStatusModal = ({
    bdUpdateUsers,
    onUpdate,
    onCancel,
    bdShow
}: IBdUserEditFeatureStatusModalProps) => {
    const [updating, setUpdating] = useState(false);
    const [displayUser, setDisplayUser] = useState<UserPortalBean>({} as UserPortalBean);
    const formDataRef = useRef<BdUserEditFeatureStatusModalState>();

    //toast functions
    const { toastSuccess, toastError } = useBdToast();

    const isMultipleUpdate: boolean = bdUpdateUsers && bdUpdateUsers.length > 1;

    const confirmModal = useBdModal();

    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    /** set updating=false on close */
    React.useEffect(() => {
        if (!bdShow) {
            setUpdating(false);
        }
    }, [bdShow]);

    //update display data for user when users changes for any reason
    React.useEffect(() => {
        if (bdUpdateUsers && bdUpdateUsers.length > 0) {
            setDisplayUser(bdUpdateUsers[0]);
        }
    }, [bdUpdateUsers]);

    /** update user feature status data */
    const _handleConfirmClick = async (data: BdUserEditFeatureStatusModalState): Promise<boolean> => {
        formDataRef.current = data;
        if (isMultipleUpdate) {
            confirmModal.Show();
        }
        else {
            _handleUserFeatureStatusUpdate();
        }
        return false;
    };

    /** update user feature status data */
    const _handleUserFeatureStatusUpdate = async (): Promise<boolean> => {
        try {
            setUpdating(true);

            const options: UserFeatureOptions = { ...formDataRef.current as UserFeatureOptions };

            if (displayUser && formDataRef.current) {
                const updatedUserList: UserPortalBean[] = [] as UserPortalBean[];

                if (bdUpdateUsers && bdUpdateUsers.length > 0) {
                    for (const user of bdUpdateUsers) {

                        const userRowKey: string = user.RowKey;

                        const updatedUserData: UserPortalBean = await _bundledocsApiInstance.Users.UpdateUserFeatureStatus(userRowKey, options) as UserPortalBean;
                        updatedUserList.push(updatedUserData);
                    }

                    toastSuccess(bdUpdateUsers.length > 1 ? `${bdUpdateUsers.length} users have been updated successfully!` : "User Feature Status updated successfully!");
                }

                onUpdate?.(updatedUserList);
                onCancel?.();

                formDataRef.current = undefined;
            }
            return true;
        }
        catch (error: any) {
            toastError(error as BdToastFailureMessage);
            return false;
        }
        finally {
            setUpdating(false);
        }
    };

    return (
        <>
            <BdModal bdTitle="Edit Feature Status" bdDisabled={updating} onConfirm={_handleConfirmClick} onCancel={onCancel} bdShow={bdShow} bdSaving={updating}>
                <h6>{isMultipleUpdate ? `${bdUpdateUsers.length} users selected` : displayUser.Email}</h6>
                <BdSelectUserFeatureStatus
                    name="FeatureStatusAdjust"
                    bdLabel="Feature: Adjust (Bundledocs Editor and Previewer)"
                    bdPlaceholder="choose a status for adjust..."
                    bdValue={displayUser?.FeatureStatusAdjust}
                    bdClass="margin-b-xs"
                />
                <BdSelectUserFeatureStatus
                    name="FeatureStatusEdit"
                    bdLabel="Feature: Edit (Text Editing)"
                    bdPlaceholder="choose a status for editing..."
                    bdValue={displayUser?.FeatureStatusEdit}
                    bdClass="margin-b-xs"
                />
            </BdModal>
            {isMultipleUpdate &&
                <BdConfirmModal {...confirmModal.BdModalProps} bdBody={`Are you absolutely sure you want to Edit Feature Status for ${bdUpdateUsers.length} users`} onConfirm={_handleUserFeatureStatusUpdate} />
            }
        </>
    );
};
