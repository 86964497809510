import { BdServiceBase } from "bundledocs.common.client/modules/bd-services";
import {
    BdNotificationBean
} from "../../models/providers/bean/bd-notification-bean/bd-notification-bean";
import { NotificationOptions } from "../../models/providers/notification-context/notification-options";

/**
 * Notifications Service
 * */
export default class BdServiceNotifications extends BdServiceBase {
    private static ApiUrl = "/api/v1/notifications";

    /**
     * Returns list of global notifications
     * @param searchField: filtering property (i.e. created date)
     */
    Search(searchField: string): Promise<BdNotificationBean[]> {
        const fields = "partitionkey,rowkey,created,title,body,actiontext,actionurl,category,timetolive";
        return super.Get(BdServiceNotifications.ApiUrl + "?$select=" + fields + searchField);
    }

    /**
     * Create new notification     
     * @param notificationOptions: notification options object
     */
    CreateNotification(notificationOptions: NotificationOptions): Promise<BdNotificationBean> {
        return super.Post(`${BdServiceNotifications.ApiUrl}`, notificationOptions);
    }

    /**
     * deletes a  notification
     * @param notificationRowKey: row key for notification being deleted
     */
    DeleteNotification(notificationRowKey: string): Promise<BdNotificationBean> {
        return super.Delete(`${BdServiceNotifications.ApiUrl}/${notificationRowKey}`);
    }
}
