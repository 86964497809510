import { BdAlert, BdAlertType, BdCol, BdGrid, BdRow } from "bundledocs.common.client/modules/bd-components";
import { BdIconDisappointed } from "bundledocs.common.client/modules/bd-graphics";
import { UrlHelper } from "bundledocs.common.client/modules/bd-helpers";
import { BdFormFilter } from "bundledocs.common.client/modules/bd-models";
import { BdMargin } from "bundledocs.common.client/modules/bd-style";
import { BdUserSubscriptionTypes } from "bundledocs.common.client/src/constants/bd-enums";
import React, { useState } from "react";
import { UserPortalBean } from "../../../models/providers/bean/user-portal-bean/user-portal-bean";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";
import BdUsersFilterSearchList from "./bd-users-search-filters";
import BdUsersSearchForm from "./bd-users-search-form";
import BdUsersSearchList from "./bd-users-search-list";

export type BdUsersSearchProps = React.HTMLAttributes<HTMLElement>

//object interface used to manage the alert information
interface AlertData {
    type: BdAlertType,
    show: boolean,
    message: string
}

/**
 * Component that allows to search a user by email or email group, and list the result in a table
 * @param param0
 */
export default function BdUsersSearch({
    ...otherProps
}: BdUsersSearchProps): JSX.Element {
    // full user list after API call
    const [users, setUsers] = useState<UserPortalBean[]>([]);
    // list of users after filtering
    const [filteredUsers, setFilteredUsers] = useState<UserPortalBean[]>([]);
    const [expiredToggle, setExpiredToggle] = useState<number>(BdUserSubscriptionTypes.TRIAL_EXPIRED);

    const [alertData, setAlertData] = useState<AlertData>({
        type: BdAlertType.Warning,
        show: false,
        message: ""
    });

    const updateFilteredUsers = (data: UserPortalBean[], filterValue: number) => {
        setFilteredUsers?.(
            data.length > 0 && filterValue === BdUserSubscriptionTypes.TRIAL_EXPIRED
                ? data.filter((user) => user.SubscriptionType !== BdUserSubscriptionTypes.TRIAL_EXPIRED as number)
                : data
        );
    };

    const handleOptionChange = (e: any) => {
        setExpiredToggle(e.target.value);
        updateFilteredUsers(users, parseInt(e.target.value));
    };

    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    //show the alert
    const _openAlert = (type: BdAlertType, message: string) => {
        setAlertData({
            ...alertData,
            type: type,
            message: message,
            show: true
        });
    };

    //hide the alert
    const _closeAlert = () => {
        setAlertData({
            ...alertData,
            show: false
        });
    };

    //executes the search
    const _handleSearch = async (searchData: BdFormFilter[]) => {
        if (searchData.length === 0) {
            _openAlert(BdAlertType.Danger, "The information you are trying to search is in the wrong format. Please, check it and try again");
            return;
        }

        try {
            _closeAlert();

            const filterParameters: string = UrlHelper.createFilterParametersFromBdFormFilters(searchData);
            const result: UserPortalBean[] = await _bundledocsApiInstance.Users.Search(filterParameters);

            setUsers(result);

            // by default show only active users
            updateFilteredUsers(result, BdUserSubscriptionTypes.TRIAL_EXPIRED);

            // after clicking on search btn we need to drop the radio buttons to default value
            setExpiredToggle(BdUserSubscriptionTypes.TRIAL_EXPIRED);

            if (!result[0]) {
                _openAlert(BdAlertType.Info, "No users found.");
            }
        }
        catch (error: any) {
            setUsers([]);
            _openAlert(BdAlertType.Danger, error.message);
        }
    };

    return (
        <BdGrid {...otherProps} style={{
            height: "100%",
            display: "flex",
            flexDirection: "column"
        }} >
            <BdUsersSearchForm onSearch={_handleSearch} />

            <BdAlert bdType={alertData.type} bdDissmisable bdShow={alertData.show} onClose={_closeAlert} style={{ marginTop: BdMargin.Normal }}
                bdTitle={
                    <><BdIconDisappointed style={{ marginRight: BdMargin.Small }} />Oops!</>
                }>
                <p>{alertData.message}</p>
            </BdAlert>

            {users[0] && (
                <BdRow>
                    <BdUsersFilterSearchList
                        bdExpiredValue={expiredToggle}
                        onUpdate={handleOptionChange}
                    />
                </BdRow>
            )}
            {filteredUsers[0] && (
                <BdUsersSearchList bdUsers={filteredUsers} />
            )}
        </BdGrid>
    );
}
