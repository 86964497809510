import {BdFormFilter} from "../models/common/BdFormFilter";
import FormatHelper from "./format-helper";

/**
 * Class with common methods related to parsing and generating correct search filter
 * */
export default class SearchFilterHelper {
    // convert date filter for correct API BdFormFilter
    static transformDateRangeToBdFormFilters(data: { dateRangeStart: string, dateRangeEnd: string },
        defaultDateFormat = "yyyy-MM-dd"): BdFormFilter[] {
        const filters: BdFormFilter[] = [];

        // need convert date to IsoStringDate to fix problem with OData filtering on back-end
        filters.push({
            field: "dateRangeStart",
            value: FormatHelper.convertStringDateToIsoDateFormat(data.dateRangeStart, defaultDateFormat)
        });
        // because end date is in format _todayDate_00:00:000000_ we add one day to show created TODAY's values
        filters.push({
            field: "dateRangeEnd",
            value: FormatHelper.convertStringDateToIsoDateFormat(data.dateRangeEnd, defaultDateFormat, 1)
        });

        return filters;
    }
}
