import * as React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import FormatHelper from "../../helpers/format-helper";
import StringHelper from "../../helpers/string-helper";
import bdStyle from "./bd-timeline.module.css";

export enum BdTimeLineActivityType {
    DEFAULT = 10,
    INFO = 20,
    SUCCESS = 30,
    WARNING = 40,
    DANGER = 50,
}

export interface BdTimeLineActivity {
    text: string;
    user: string;
    date: string;
    icon?: JSX.Element;
    type?: BdTimeLineActivityType;
}

export enum BdTimeLineLayout {
    LEFT = 10,
    RIGHT = 20,
    TWO_SIDES = 30
}

interface BdTimeLineProps {
    bdActivities: BdTimeLineActivity[];
    bdLayout?: BdTimeLineLayout
}

export function BdTimeLine({
    bdActivities,
    bdLayout = BdTimeLineLayout.TWO_SIDES
}: BdTimeLineProps) {
    const objectId = StringHelper.newGuid();

    const _lineColor = "#DEDEDE";
    const _circleColor = "#FFF";

    const _actionBackgroundColor = "#EEE";
    const _actionColor = "#333";

    const _getTimelineItem = (activity: BdTimeLineActivity, index: number): JSX.Element => {
        let _iconBackgroundColor;
        const _iconColor = "#FFF";

        switch (activity.type) {
            case BdTimeLineActivityType.SUCCESS:
                _iconBackgroundColor = "#5cb85c";
                break;
            case BdTimeLineActivityType.WARNING:
                _iconBackgroundColor = "#f0ad4e";
                break;
            case BdTimeLineActivityType.DANGER:
                _iconBackgroundColor = "#d9534f";
                break;
            case BdTimeLineActivityType.INFO:
                _iconBackgroundColor = "#337ab7";
                break;
            case BdTimeLineActivityType.DEFAULT:
            default:
                _iconBackgroundColor = "#999";
                break;
        }

        return (
            <VerticalTimelineElement
                key={`${objectId}_${index}`}
                date={FormatHelper.dateToString(new Date(activity.date))}
                icon={activity.icon}
                contentStyle={{ background: _actionBackgroundColor, color: _actionColor, borderRadius: "2px", padding: "1rem", boxShadow: "0 3px 0 " + _iconBackgroundColor }}
                contentArrowStyle={{ borderRight: "7px solid " + _actionBackgroundColor }}
                iconStyle={{ background: _iconBackgroundColor, color: _iconColor, boxShadow: "0 0 0 4px " + _circleColor }}
            >
                <p className={bdStyle.TimelineElementContent}>
                    <span>{activity.text}</span><br />
                    {activity.user && <span className={bdStyle.TimelineElementContentUser}>by {activity.user}</span>}
                </p>
            </VerticalTimelineElement>
        );

    };

    const _getTimelineLayout = (): "1-column-left" | "1-column-right" | "2-columns" | "1-column" | undefined => {
        switch (bdLayout) {
            case BdTimeLineLayout.LEFT:
                return "1-column-left";
            case BdTimeLineLayout.RIGHT:
                return "1-column-right";
            case BdTimeLineLayout.TWO_SIDES:
                return "2-columns";
        }
    };

    return (
        <VerticalTimeline
            animate={false}
            layout={_getTimelineLayout()}
            lineColor={_lineColor}
            children={bdActivities.map((act, index) => _getTimelineItem(act, index))} />
    );
}
