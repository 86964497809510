import { Col, Modal, Row } from "react-bootstrap";
import { SubscriptionCapacity } from "../../../modules/bd-models";
import BdButton from "../bd-buttons/bd-button";
import { BdButtonType } from "../bd-buttons/bd-buttons-constants";
import BdAlert, { BdAlertType } from "../bd-common/bd-alert";

interface IBdSubscriptionModalProps {
    bdUserLimit: SubscriptionCapacity
    bdShow?: boolean,
    onCancel?: Function
}

export const BdSubscriptionCapacityModal = ({
    bdUserLimit,
    bdShow = false,
    onCancel
}: IBdSubscriptionModalProps) => {

    const _handleCancel = () => {
        onCancel?.();
    };

    const _limitCard = (title: string, liveCount: number, limit: number) => {
        const percentUsed = (liveCount * 100) / limit;

        let alertClass;

        if (percentUsed < 80) {
            alertClass = BdAlertType.Info;
        }
        else if (percentUsed >= 80 && percentUsed <= 99) {
            alertClass = BdAlertType.Warning;
        }
        else {
            alertClass = BdAlertType.Danger;
        }

        return (
            <BdAlert bdType={alertClass}  >
                <>
                    <strong>{title}: </strong>
                    {limit === 2147483647 ? (
                        <>
                            {liveCount} of <i>unlimited</i>
                        </>
                    ) : (
                        <>
                            {liveCount} of {limit}
                            <br />
                            {liveCount <= limit && (
                                <>
                                    <p>
                                        {limit - liveCount} remaining
                                    </p>
                                </>
                            )}{liveCount > limit && (
                                <>
                                    <div style={{ marginTop: "2px", fontSize: "14px" }}>
                                        <span role="img" aria-label="warning">&#9888;</span> You have exceeded your {title} quota, please contact support to upgrade your account.
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            </BdAlert>
        );
    };

    return (
        <>
            <Modal className="modalPanel" show={bdShow} onHide={_handleCancel}>
                <Modal.Header className='h3'>Capacity</Modal.Header>
                <Modal.Body>
                    {bdUserLimit?.SubscriptionProducts && bdUserLimit?.SubscriptionProducts?.length > 0 &&
                        //Subscription Products
                        <BdAlert bdType={BdAlertType.Info}  >
                            <>{bdUserLimit?.SubscriptionProducts?.map(product =>
                                <>
                                    <strong>{product}</strong><br />
                                </>
                            )}</>
                        </BdAlert>
                    }

                    {bdUserLimit.SubscriptionTrialEnd &&
                        //Subscription TrialEnd
                        <BdAlert bdType={BdAlertType.Warning}  >
                            <>
                                <strong>Trial Ends: </strong>
                                {bdUserLimit.SubscriptionTrialEnd}
                            </>
                        </BdAlert>
                    }

                    {bdUserLimit.SubscriptionTotalUsersAcrossOrganization > 0 &&
                        <BdAlert bdType={BdAlertType.Info}  >
                            <>
                                <strong>Total users across organization: </strong>
                                {bdUserLimit.SubscriptionTotalUsersAcrossOrganization}
                            </>
                        </BdAlert>
                    }

                    {bdUserLimit.SubscriptionUserLimit > 0 &&
                        _limitCard("Bundledocs Users", bdUserLimit.SubscriptionBundledocsUsersLiveCount, bdUserLimit.SubscriptionUserLimit)
                    }

                    {bdUserLimit.SubscriptionEditorUsersLimit > 0 &&
                        _limitCard("Editor Users", bdUserLimit.SubscriptionEditorUsersLiveCount, bdUserLimit.SubscriptionEditorUsersLimit)
                    }

                    {bdUserLimit.SubscriptionTextEditingUsersLimit > 0 &&
                        _limitCard("Text Editing Users", bdUserLimit.SubscriptionTextEditingUsersLiveCount, bdUserLimit.SubscriptionTextEditingUsersLimit)
                    }

                    {bdUserLimit.SubscriptionCaseLimit > 0 &&
                        _limitCard("Bundles", bdUserLimit.SubscriptionTotalOfBundlesAcrossOrganization, bdUserLimit.SubscriptionCaseLimit)
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Row className="show-grid">
                        <Col className='pull-request' xs={3}>
                            <BdButton bdType={BdButtonType.Warning} onClick={_handleCancel} bdText="Close" />
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};
