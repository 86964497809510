import { BdSelector, ISelectorProps } from "bundledocs.common.client/modules/bd-components";
import { BdEnum, BdEnums } from "bundledocs.common.client/modules/bd-models";
import { UserFeatureStatus } from "../../../constants/bd-enum-types";

/*
 * Select User Feature Status props
 */
export type BdSelectUserFeatureStatusProps = ISelectorProps

/** User Feature status selection component */
export const BdSelectUserFeatureStatus = ({
    onUpdate,
    ...otherProps
}: BdSelectUserFeatureStatusProps) => {
    const _handleOnUpdate = (selectedOption: BdEnum | BdEnum[]) => {
        if (!Array.isArray(selectedOption)) {
            onUpdate?.(selectedOption.code as number);
        }
    };

    return (
        <BdSelector
            bdOptions={UserFeatureStatus}
            onChangeSelected={_handleOnUpdate}
            {...otherProps}
        />
    );
};
