import {
    BdButton,
    BdCol, BdDatePicker,
    BdForm,
    BdRow
} from "bundledocs.common.client/modules/bd-components";
import { BdFormFilter } from "bundledocs.common.client/modules/bd-models";
import { BdIconCalendar, BdIconSearch, BdLoaderSpinner } from "bundledocs.common.client/src";
import FormatHelper from "bundledocs.common.client/src/helpers/format-helper";
import SearchFilterHelper from "bundledocs.common.client/src/helpers/search-filter-helper";
import { useEffect, useState } from "react";

export interface BdNotificationsSearchFormProps extends React.HTMLAttributes<HTMLElement> {
    onSearch?(bdFormFilter: Array<BdFormFilter>): Promise<void>;
    startDate: Date;
    endDate: Date;
    setEndDate?(date: Date): void,
    btnSearchWorking: boolean;
}

/**
 * Component that shows some fields to fill by the user to search global notifications.
 * It returns a list of filters to apply in the search
 * @param param0
 */
export default function BdNotificationsSearchForm({
    onSearch = void 0,
    startDate = new Date(),
    endDate = new Date(),
    setEndDate = void 0,
    btnSearchWorking = false,
    ...otherProps
}: BdNotificationsSearchFormProps): JSX.Element {
    const defaultDateFormat = "yyyy-MM-dd";

    const [isBtnSearchByDateRangeWorking, setIsBtnSearchByDateRangeWorking] = useState<boolean>(btnSearchWorking);
    const [dateRangeStart, setDateRangeStart] = useState<Date>(startDate);

    useEffect(() => {
        setIsBtnSearchByDateRangeWorking(btnSearchWorking);
    }, [btnSearchWorking]);

    //manages the form submit
    const _handleOnSubmitSearchByDateRange = async (data: { dateRangeStart: string, dateRangeEnd: string }): Promise<boolean> => {
        try {
            setIsBtnSearchByDateRangeWorking(true);
            if (onSearch) {
                await onSearch(SearchFilterHelper.transformDateRangeToBdFormFilters(data));
            }
        }
        finally {
            setIsBtnSearchByDateRangeWorking(false);
            return false;
        }
    };

    const _searchByDateRangeForm = (
        <BdForm onSubmit={_handleOnSubmitSearchByDateRange}>
            <BdRow>
                <BdCol xl={5} lg={5} md={12} sm={12}>
                    <BdDatePicker
                        bdLabel=""
                        name="dateRangeStart"
                        id="dateRangeStart"
                        bdIcon={<BdIconCalendar />}
                        dateFormat={defaultDateFormat}
                        bdValue={dateRangeStart}
                        bdMax={endDate}
                        wrapperClassName="dateRangeStartPicker"
                        onUpdate={setDateRangeStart}
                    />
                </BdCol>

                <BdCol xl={4} lg={4} md={12} sm={12}>
                    <BdDatePicker
                        bdLabel=""
                        name="dateRangeEnd"
                        id="dateRangeEnd"
                        bdIcon={<BdIconCalendar />}
                        dateFormat={defaultDateFormat}
                        bdValue={endDate}
                        bdMin={dateRangeStart}
                        bdMax={FormatHelper.addYearsToDate(new Date(), 10)}
                        wrapperClassName="dateRangeEndPicker"
                        onUpdate={setEndDate}
                    />
                </BdCol>

                <BdCol xl={3} lg={3} md={12} sm={12} style={{ alignSelf: "start" }}>
                    <BdButton type="submit"
                        disabled={isBtnSearchByDateRangeWorking}
                        bdText="Created"
                        bdBlock={true}
                        bdIcon={isBtnSearchByDateRangeWorking ? <BdLoaderSpinner bdSize={25} /> : <BdIconSearch />}
                    />
                </BdCol>
            </BdRow>
        </BdForm>
    );

    return (
        <>
            <BdRow>
                <BdCol sm={12}>
                    {_searchByDateRangeForm}
                </BdCol>
            </BdRow>
        </>
    );
}
