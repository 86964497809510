import { BdTheme } from "./bd-layout-constants";

export default class BdLayoutHelper
{
    public static Theme: BdTheme = BdTheme.Light;

    public static BootstrapThemeVariant(customTheme?: BdTheme): string
    {
        return (customTheme ?? BdLayoutHelper.Theme) === BdTheme.Dark
            ? "dark" : "light";
    }
}
