import { TimeZone } from "../../models/providers/domain/system-context/TimeZone";
import { BdApiResponse, BdServiceBase, BdServiceConfig } from "../bd-service-base";
import IBdServiceSystems from "./interfaces/IBdServiceSystems";

/**
 * Systems Service
 * */
export class BdServiceSystems extends BdServiceBase implements IBdServiceSystems {
    constructor(configuration: BdServiceConfig) {
        super(configuration);
    }
    private static ApiUrl = "/api/v1/systems";
    
    getTimezones(): Promise<BdApiResponse<TimeZone[]>> {
        return super.Get(`${BdServiceSystems.ApiUrl}/timezones`);
    }
}
