import { BdServiceBase } from "bundledocs.common.client/modules/bd-services";
import { BdBundlePortalBean } from "../../models/providers/bean/bd-live-bundles-bean/bd-bundle-bean";
import { BdDocumentPortalBean } from "../../models/providers/bean/bd-live-bundles-bean/bd-document-bean";

/**
 * Bundles Service
 * */
export default class BdServiceLiveBundles extends BdServiceBase {
    private static ApiUrl = "/api/v1/live-bundles";

    /**
     * Returns bundle or list of bundles
     * @param searchField: user field to search for (i.e. email, emailgroup)
     */
    Search(searchField: string): Promise<BdBundlePortalBean[]> {
        const fields = "partitionkey,rowkey,briefcreated,email,emailgroup,lastaccessedtime,lastaccessedaction,casedocuments,briefdateregenerated,createdsince,accesstype,accesstypename";
        return super.Get(BdServiceLiveBundles.ApiUrl + "?$select=" + fields + searchField);
    }

    /**
     * Returns documents for a bundle
     * @param searchField: bundle details (i.e. partitionkey, rowkey)
     */
    GetDocuments(partitionKey: string, rowKey: string): Promise<BdDocumentPortalBean[]> {
        const fields = "partitionkey,rowkey,briefdocumentcreated,displayorder,numberofpages,filesize,fileextension,type";
        return super.Get(`${BdServiceLiveBundles.ApiUrl}/${partitionKey}/${rowKey}/live-documents/` + "?$select=" + fields);
    }
}
