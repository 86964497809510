import React from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import cx from "classnames";
import bdStyle from "./bd-drop-down.module.css";

export interface BdDropDownProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    bdTitle?: React.ReactNode,
    bdId?: string,
    bdShowCaret?: boolean,
    bdMenuDirection?: BdDropDownDirection,
    bdClassName?: string,
    bdDisabled?: boolean,
    children: JSX.Element | JSX.Element[] | undefined | ""
}

export enum BdDropDownDirection {
    UP = "up",
    DOWN = "down",
    START = "start",
    END = "end"
}

/**
 * Drop Down component
 */
const BdDropDown = ({
    bdTitle = "",
    bdId = "",
    bdShowCaret = true,
    bdMenuDirection = BdDropDownDirection.END,
    bdClassName = "",
    bdDisabled = false,
    children
}: BdDropDownProps) => {
    return (
        <DropdownButton title={bdTitle} id={bdId} drop={bdMenuDirection} className={cx(bdStyle.dropdownButton, bdClassName, bdShowCaret ? "" : bdStyle.hideCaret)} disabled={bdDisabled} variant="default">
            {children}
        </DropdownButton>
    );
};

export default BdDropDown;
