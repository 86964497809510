import { BdAlert, BdAlertType, BdModal, useBdToast } from "bundledocs.common.client/modules/bd-components";
import { BdEnums } from "bundledocs.common.client/modules/bd-models";
import React, { useState } from "react";
import { BdUserStripePlanBean } from "../../../models/providers/bean/user-portal-bean/bd-user-stripe-plan-bean";
import { UserPortalBean } from "../../../models/providers/bean/user-portal-bean/user-portal-bean";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";
interface IBdUserStripePlanModalProps {
    bdUser: UserPortalBean;
    onCancel?: Function;
    bdShow: boolean;
}

/** Modal to view user stripe plan */
export const BdUserStripePlanModal = ({
    bdUser,
    onCancel,
    bdShow
}: IBdUserStripePlanModalProps) => {
    const _bdEnums = new BdEnums();

    //services
    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    //Stripe Plan
    const [stripePlan, setStripePlan] = useState<BdUserStripePlanBean>({} as BdUserStripePlanBean);

    //toast functions
    const { toastError } = useBdToast();
   
    React.useEffect(() => {
        _handleGetStripePlan();
    }, [bdUser]);

    //open stripe plan modal
    const _handleGetStripePlan = async () => {       
        if (bdUser.StripeSubscriberPlanKey) {
            const response = await _bundledocsApiInstance.Plans.GetStripePlanById(bdUser.StripeSubscriberPlanKey);

            try {
                if (response) {
                    setStripePlan({
                        planid: bdUser.StripeSubscriberPlanKey,
                        description: bdUser.StripeSubscriberPlanDescription,
                        currency: response.currency,
                        amount: response.amount,
                        metadata: response.metadata
                    });
                }
                else {
                    toastError("No stripe subscription plan found for this user");
                }
            } catch (error: any) {
                toastError(error);
            }
        }        
    };

    /** close on ok */
    const _handleConfirm = async (): Promise<boolean> => {
        onCancel?.();     
        return true;
    };

    return (
        <BdModal bdTitle="Stripe Plan" bdShow={bdShow} onCancel={onCancel} onConfirm={_handleConfirm} bdShowCancel={false} bdConfirmText="Close">
            <BdAlert bdType={BdAlertType.Info}  >
                <>
                    <strong>Plan: </strong>
                    {stripePlan.description}
                </>
            </BdAlert>
            <BdAlert bdType={BdAlertType.Info}  >
                <>
                    <strong>Currency: </strong>
                    {stripePlan?.currency?.toUpperCase()}
                </>
            </BdAlert>
            <BdAlert bdType={BdAlertType.Info}  >
                <>
                    <strong>Price: </strong>
                    {stripePlan?.amount}
                </>
            </BdAlert>
            <BdAlert bdType={BdAlertType.Info}  >
                <>
                    <strong>Max Users: </strong>
                    {stripePlan?.metadata?.MAX_USERS}
                </>
            </BdAlert>
            <BdAlert bdType={BdAlertType.Info}  >
                <>
                    <strong>Max Bundles: </strong>
                    {stripePlan?.metadata?.MAX_BUNDLES}
                </>
            </BdAlert>
            <BdAlert bdType={BdAlertType.Info}  >
                <>
                    <strong>Feature Level: </strong>
                    {_bdEnums.UserSubscriptionTypes.find(us => us.code == stripePlan?.metadata?.FEATURE_LEVEL)?.description}
                </>
            </BdAlert>
        </BdModal>
    );
};
