import { Alert } from "react-bootstrap";

export interface BdAlertProps extends React.HTMLAttributes<HTMLElement> {
    bdTitle?: string | JSX.Element,
    bdType?: BdAlertType,
    bdDissmisable?: boolean,
    bdShow?: boolean,
    onClose?: (a: any, b: any) => void | undefined,
    children: JSX.Element | JSX.Element[] | undefined | ""
}

export enum BdAlertType {
    Primary = 100,
    Secondary = 200,
    Success = 300,
    Info = 400,
    Warning = 500,
    Danger = 600,
    Dark = 600,
    Light = 600,
}

/**
 * Alert component
 */
export default function BdAlert({
    bdTitle,
    bdType,
    bdDissmisable = false,
    bdShow = true,
    onClose = undefined,
    children,
    ...props
}: BdAlertProps): JSX.Element {
    /**
     * Mapping between our types and bootstrap5 types
     * */
    const alertType = (): string => {
        switch (bdType) {
            case BdAlertType.Secondary:
                return "secondary";
            case BdAlertType.Success:
                return "success";
            case BdAlertType.Info:
                return "info";
            case BdAlertType.Warning:
                return "warning";
            case BdAlertType.Danger:
                return "danger";
            case BdAlertType.Dark:
                return "dark";
            case BdAlertType.Light:
                return "light";
            case BdAlertType.Primary:
            default:
                return "primary";
        }
    };

    return (
        <Alert show={bdShow} variant={alertType()} {...props} onClose={onClose} dismissible={bdDissmisable}>
            {bdTitle && <Alert.Heading>{bdTitle}</Alert.Heading>}
            {children}
        </Alert>
    );
}
