import { UseFormReturn } from "react-hook-form";
import { BdFormErrors, BdFormRegistrationProps, BdFormValidationPolicy, IBdFormValidation } from "./bd-form-constants";

export class BdFormHelper {

    /** Returns method and properties needed for the components that are compatible with BdForm */
    public static GetBdFormFieldProps(name: string, value: any, methods: UseFormReturn<any>, validations?: IBdFormValidation[]): BdFormRegistrationProps {
        const validationObject = BdFormHelper.GetValidationObject(validations);

        return {
            register: (name: string) => {
                if (!name) {
                    return {};
                }
                return (value)
                    ? methods.register(name, { value: value, ...validationObject })
                    : methods.register(name, validationObject);
            },
            bdErrorMessage: methods.formState.errors[name]?.message as string,
            bdTouched: methods.formState.touchedFields[name] === true,
            bdSetValue: (value: any) => {
                if (methods.getValues(name) !== value) {
                    methods.setValue(name, value, {
                        shouldValidate: true,
                        shouldDirty: true
                    });
                }
            }
        };
    }

    /** Transforms a BdValidation array to a validation object compatible with the library */
    private static GetValidationObject(validations?: IBdFormValidation[]): any {
        const validationObject: any = {};
        validations?.forEach((validation: IBdFormValidation) => {
            switch (validation.policy) {
                case BdFormValidationPolicy.Required:
                    validationObject.required = {
                        value: true,
                        message: validation.policyError ?? BdFormErrors.Required
                    };
                    break;
                case BdFormValidationPolicy.Email:
                    validationObject.pattern = {
                        value: /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
                        message: validation.policyError ?? BdFormErrors.Email
                    };
                    break;
                case BdFormValidationPolicy.NumberPositive:
                    validationObject.min = {
                        value: 1,
                        message: validation.policyError ?? BdFormErrors.NumberPositive
                    };
                    break;
            }
        });

        return validationObject;
    }
}
