import { BriefAnnotationExclusionCreateOptions, OAuth2ApiResponse } from "../../../modules/bd-models";
import { BdServiceBase } from "../bd-service-base";
import IBdServiceBundleAnnotationExclusions from "./interfaces/IBdServiceBundleAnnotationExclusions";

export class BdServiceBundleAnnotationExclusions extends BdServiceBase implements IBdServiceBundleAnnotationExclusions {
    private static AnnotationApiUrl = "/api/v1/annotation-exclusions";

    async CreateAnnotationExclusion(annotationExclusionBean: BriefAnnotationExclusionCreateOptions): Promise<OAuth2ApiResponse<any>> {
        return super.Post(`${BdServiceBundleAnnotationExclusions.AnnotationApiUrl}`, annotationExclusionBean);
    }

    async DeleteAnnotationExclusion(partitionKey: string, rowKey: string): Promise<OAuth2ApiResponse<any>> {
        return super.Delete(`${BdServiceBundleAnnotationExclusions.AnnotationApiUrl}/${partitionKey}/${rowKey}`);
    }

    async UpdateAnnotationExclusion(partitionKey: string, rowKey: string, annotationExclusionBean: BriefAnnotationExclusionCreateOptions): Promise<OAuth2ApiResponse<any>> {
        return super.Put(`${BdServiceBundleAnnotationExclusions.AnnotationApiUrl}/${partitionKey}/${rowKey}`, annotationExclusionBean);
    }
}
