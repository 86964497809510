import {
    BdModal,
    BdSelector,
    BdTextBox,
    BdTextBoxType,
    useBdToast
} from "bundledocs.common.client/modules/bd-components";
import { BdEnum, BdToastFailureMessage } from "bundledocs.common.client/modules/bd-models";
import * as React from "react";
import { useEffect, useState } from "react";
import { AccountSubscriptionType } from "../../../constants/bd-enum-types";
import { BdAccountNumberBean } from "../../../models/providers/bean/bd-account-number-bean/bd-enterprise-license-bean";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";

interface IBdAccountNumberCreateModalProps {
    bdTitle?: string;
    bdShow: boolean;
    createAction?: boolean;
    account: BdAccountNumberBean;
    onUpdate?: (account: BdAccountNumberBean) => void;
    onCancel?: Function;
}

/** Modal for creating the account number */
export const BdAccountNumberCreateModal = ({
    bdTitle,
    onUpdate,
    onCancel,
    bdShow,
    account,
    createAction = false,
}: IBdAccountNumberCreateModalProps) => {
    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    const [updating, setUpdating] = useState<boolean>(false);
    const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
    const [caseLimit, setCaseLimit] = useState<number>(account.SubscriptionCaseLimit ?? 0);
    const [userLimit, setUserLimit] = useState<number>(account.SubscriptionUserLimit ?? 0);

    useEffect(() => {
        if (account.AccountNumber && account.AccountNumber.length > 0) {
            setCaseLimit(account.SubscriptionCaseLimit ?? 0);
            setUserLimit(account.SubscriptionUserLimit ?? 0);

            if (account.SubscriptionType !== 0) {
                setBtnDisabled(false);
            }
        }
    }, [account]);

    //toast functions
    const { toastSuccess, toastError } = useBdToast();

    /** set updating=false on close */
    useEffect(() => {
        if (!bdShow) {
            setUpdating(false);

            if (account.AccountNumber && account.AccountNumber.length > 0) {
                setBtnDisabled(true);
                setCaseLimit(0);
                setUserLimit(0);
            }
        }
    }, [account.AccountNumber, bdShow]);
    
    /**  change options in dropdown  **/
    const _handleOptionOnUpdate = (selectedOption: BdEnum | BdEnum[]) => {
        if (!Array.isArray(selectedOption)) {
            if (selectedOption.code === 0) {
                setCaseLimit(0);
                setUserLimit(0);
                setBtnDisabled(true);
            } else {
                setBtnDisabled(false);
            }
        }
    };

    /** create account number */
    const _handleAccountNumberCreate = async (accountNumberBean: BdAccountNumberBean): Promise<boolean> => {

        let handleResult = true;

        try {
            setUpdating(true);

            const accountResult: BdAccountNumberBean = await _bundledocsApiInstance.AccountNumbers.Actions(
                accountNumberBean, createAction ? "create" : "update");

            if (accountResult?.RowKey?.length > 0) {
                const message: string = createAction
                    ? "Account number was created successfully"
                    : "Account number was updated successfully";

                toastSuccess(message);
                onUpdate?.(accountResult);
            }

            onCancel?.();

            handleResult = true;
        }
        catch (error: any) {
            toastError(error as BdToastFailureMessage);
            handleResult = false;
        }
        finally {
            setUpdating(false);
            setBtnDisabled(true);
            setCaseLimit(0);
            setUserLimit(0);
        }

        return handleResult;
    };

    return (
        <BdModal bdTitle={bdTitle} bdDisabled={updating} onConfirm={_handleAccountNumberCreate}
            onCancel={onCancel} bdShow={bdShow} >
            <BdTextBox
                name="PartitionKey"
                bdTextBoxType={BdTextBoxType.Hidden}
                bdValue={account?.PartitionKey}
            />
            <BdTextBox
                name="RowKey"
                bdTextBoxType={BdTextBoxType.Hidden}
                bdValue={account?.RowKey}
            />
            <BdTextBox
                bdLabel="Account Number"
                name="AccountNumber"
                style={{ marginBottom: "1rem" }}
                bdValue={account?.AccountNumber}
            />
            <BdSelector
                bdOptions={AccountSubscriptionType}
                bdLabel="Type"
                bdValue={account?.SubscriptionType ?? 0}
                name="SubscriptionType"
                onChangeSelected={_handleOptionOnUpdate}
            />
            <BdTextBox
                bdLabel="Case Limit"
                name="SubscriptionCaseLimit"
                style={{ marginBottom: "1rem" }}
                bdValue={caseLimit}
                bdTextBoxType={BdTextBoxType.Number}
                bdReadOnly={btnDisabled}
                onChange={(e: any) => setCaseLimit(e.target.value)}
            />
            <BdTextBox
                bdLabel="User Limit"
                name="SubscriptionUserLimit"
                style={{ marginBottom: "1rem" }}
                bdValue={userLimit}
                bdTextBoxType={BdTextBoxType.Number}
                bdReadOnly={btnDisabled}
                onChange={(e: any) => setUserLimit(e.target.value)}
            />
            <BdTextBox
                bdLabel="Name"
                name="Name"
                style={{ marginBottom: "1rem" }}
                bdValue={account?.Name}
            />
            <BdTextBox
                bdLabel="Company"
                name="Company"
                style={{ marginBottom: "1rem" }}
                bdValue={account?.Company}
            />
            <BdTextBox
                bdLabel="Email"
                name="Email"
                style={{ marginBottom: "1rem" }}
                bdValue={account?.Email}
            />
            <BdTextBox
                bdLabel="Notes"
                name="Notes"
                style={{ marginBottom: "1rem" }}
                bdValue={account?.Notes}
            />
        </BdModal>
    );
};
