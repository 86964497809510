import * as React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { ButtonType, MenuItemType } from "../../constants/bd-enums";
import { BdConfirmModal } from "../bd-common/bd-confirm-modal";
import { useBdModal } from "../bd-modal/bd-modal";
import bdDropdownStyle from "./bd-drop-down.module.css";

/** props */
interface IMenuItems {
    bdText?: string,
    bdIcon?: React.ReactNode,
    bdType?: MenuItemType
    onSelect?: Function,
    bdButtonType?: ButtonType,
    bdConfirmTitle?: string,
    bdConfirmMessage?: string,
}

const BdMenuItem = ({
    bdIcon = undefined,
    bdText = "",
    bdType = MenuItemType.Entry,
    onSelect = void 0,
    bdButtonType = ButtonType.Default,
    bdConfirmTitle,
    bdConfirmMessage,
}: IMenuItems) => {
    let content: JSX.Element = <></>;

    let _menuItemColor = "";

    const ItemConfirmModal = useBdModal();

    const isModalConfirmNeeded: boolean = !!bdConfirmMessage || !!bdConfirmTitle;

    //Check if execute click on item
    const _handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (isModalConfirmNeeded) {
            ItemConfirmModal.Show();
        }
        else {
            _handleSelect(event);
        }
    };

    //Check if execute click on item
    const _handleSelect = (event: React.MouseEvent<HTMLElement>) => {
        if (onSelect) {
            onSelect(event);
        }
        ItemConfirmModal.Close();
    };

    //Check the Button Type and add the corresponding color
    switch (bdButtonType) {
        case ButtonType.Warning:
            _menuItemColor = bdDropdownStyle.bdWarningItem;
            break;
        case ButtonType.Danger:
            _menuItemColor = bdDropdownStyle.bdDangerItem;
            break;
        case ButtonType.Default:
            _menuItemColor = bdDropdownStyle.bdDefaultItem;
    }

    //Build and Return a MenuItem based on the type entered
    switch (bdType) {
        case MenuItemType.Title:
            content = (
                <Dropdown.Header>
                    {bdText}
                </Dropdown.Header>
            );
            break;
        case MenuItemType.Entry:
            content = (
                <Dropdown.Item as="button" onClick={_handleClick} className={_menuItemColor}>
                    {bdIcon && <span className={bdDropdownStyle.icon} >{bdIcon}</span>} <span >{bdText}</span>
                </Dropdown.Item>
            );
            break;
        case MenuItemType.Divider:
            content = <Dropdown.Divider/>;
            break;
    }

    //Add confirmation modal if needed
    if (isModalConfirmNeeded) {
        content = (
            <>
                {content}
                <BdConfirmModal {...ItemConfirmModal.BdModalProps} bdBody={bdConfirmMessage} bdTitle={bdConfirmTitle} onConfirm={_handleSelect} />
            </>
        );
    }

    return content;
};

export default BdMenuItem;
