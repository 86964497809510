import { BdServiceBase } from "bundledocs.common.client/modules/bd-services";
import {BdSubscriptionAnalysisBean} from "../../models/providers/bean/bd-subscription-analysis-bean/bd-subscription-analysis-bean";

/**
 * Subscription Analysis Service
 * */
export default class BdServiceSubscriptionAnalysis extends BdServiceBase {
    private static ApiUrl = "/api/v1/subscription-analysis";

    /**
     * Returns list of customer invoices
     * @param searchField: filtering property (i.e. datetime)
     */
    Search(searchField: string): Promise<BdSubscriptionAnalysisBean[]> {
        const fields = "InvoiceDate,Activity,Email,Currency,PlanNameCurrent,PlanFeeCurrent,PlanNamePrevious,PlanFeePrevious,PlanFeeDifference,Status";

        return super.Get(BdServiceSubscriptionAnalysis.ApiUrl + "?$select=" + fields + searchField);
    }    
}
