/**
 * CSS CONSTANTS
 * Don't use literals while styling. Define it here instead
 * */

export const BdMargin = {
    None: "0rem",
    Small: "0.5rem",
    Normal: "1rem",
    Medium: "1.5rem",
    Large: "2rem",
};
