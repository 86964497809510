import { Container } from "react-bootstrap";

export interface BdGridProps extends React.HTMLAttributes<HTMLElement> {
    children: JSX.Element | JSX.Element[] | undefined | ""
}

export default function BdGrid({
    ...props
}: BdGridProps): JSX.Element {
    return (
        <Container fluid {...props} />
    );
}
