import { BriefReceipt, DownloadInvitation } from "../../../modules/bd-models";
import { BdApiResponse, BdServiceBase, BdServiceConfig } from "../bd-service-base";
import IBdServiceReceipts from "./interfaces/IBdServiceReceipts";

/**
 * Group Service
 * */
export class BdServiceReceipts extends BdServiceBase implements IBdServiceReceipts {
    constructor(configuration: BdServiceConfig) {
        super(configuration);
    }

    private static ApiUrl = "/api/v1/receipts";

    DeleteDownloadInvitation(receipt: BriefReceipt, downloadInvitation: DownloadInvitation): Promise<BdApiResponse<DownloadInvitation>> {
        return super.Delete(`${BdServiceReceipts.ApiUrl}/${receipt.PartitionKey}/${receipt.RowKey}/download-invitations/${downloadInvitation.RowKey}`);
    }
}
