import { BaseModel } from "bundledocs.common.client/modules/bd-models";

export enum GlobalNotificationCategory {
    General = 30,
    Feature = 40
}

export interface BdNotificationBean extends BaseModel {
    Title: string
    Body: string,
    Category: GlobalNotificationCategory,
    CategoryName: string,
    ActionUrl: string,
    ActionText: string,
    Type: number,
    Context: string,
    Batch: string,
    Progress: number,
    TimeToLive: Date,
    TimeToLiveString: string
}
