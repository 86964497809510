import cx from "classnames";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { BdTheme } from "../bd-layout/bd-layout-constants";
import BdLayoutHelper from "../bd-layout/bd-layout-helper";
import bdTableStyle from "./bd-table.module.css";

export interface BdTableProps extends React.HTMLAttributes<HTMLElement> {
    bdTheme?: BdTheme;
    bdWrap?: boolean;
    bdSelectFirstRow?: boolean;
    bdShowActionsOnLeft?: boolean;
    bdResponsive?: boolean | "sm" | "md" | "lg" | "xl";
    bdColumns: BdTableColumn[];
    bdItems: any[];
    onActions?: Function;
    onRowSelect?: (rowData: any) => void;
}

export interface BdTableColumn {
    header: string;
    field: string;
    typeof: Function;
}

/**
 * Table component
 * @param props
 */
const BdTable = ({
    bdTheme,
    bdWrap = true,
    bdSelectFirstRow = false,
    bdShowActionsOnLeft = true,
    bdResponsive = false,
    bdColumns,
    bdItems,
    onActions = void 0,
    onRowSelect = () => { },
    ...otherProps
}: BdTableProps) => {

    useEffect(() => {
        selectRow(0);
    }, []);

    const [selectedRow, setSelectedRow] = useState<any>(null);

    const _handleRowClick = (row: any, rowno: number) => {
        setSelectedRow(rowno);
        onRowSelect?.(row);
    };

    // Function to programmatically select a row
    const selectRow = (rowIndex: number) => {
        setSelectedRow(rowIndex);
        onRowSelect?.(bdItems[rowIndex]);
    };

    //it builds headers
    const _tableHeaders = bdColumns.map((column: BdTableColumn) =>
        <th key={column.field}>{column.header}</th>
    );

    //it builds rows
    const _tableItems = bdItems.map((item: any, index: number) => {
        const actionCell = onActions && <td className={bdTableStyle.bdActionCell}>{onActions(item)}</td>;

        return (
            <tr key={index} onClick={() => _handleRowClick(item, index)}>
                {bdShowActionsOnLeft && actionCell}
                {bdColumns.map((column: BdTableColumn) => (
                    <td key={column.field + index} className={cx((!bdWrap || (bdWrap && column.typeof === Date) ? "text-nowrap" : ""), (bdSelectFirstRow ? (selectedRow === index ? bdTableStyle.selectedRow : '') : ''))} >
                        {column.typeof === Date && item[column.field] && !isNaN(Date.parse(item[column.field])) ?
                            new Date(item[column.field]).toISOString().substring(0, 10) :
                            column.typeof === String ? (
                                column.field === "SubscriptionCaseLimit" ||
                                column.field === "SubscriptionUserLimit" ||
                                column.field === "SubscriptionDocumentLimit"
                            ) && item[column.field] === 2147483647 ? "Unlimited" : item[column.field] : item[column.field]}
                    </td>
                ))}
                {!bdShowActionsOnLeft && actionCell}
            </tr>
        );
    });

    return (
        <Table striped bordered hover responsive={bdResponsive} {...otherProps} variant={BdLayoutHelper.BootstrapThemeVariant(bdTheme)}>
            <thead>
                <tr style={{ position: "sticky", top: 0 }}>
                    {bdShowActionsOnLeft && onActions && <th key="actions"></th>}
                    {_tableHeaders}
                    {!bdShowActionsOnLeft && onActions && <th key="actions"></th>}
                </tr>
            </thead>
            <tbody>
                {_tableItems}
            </tbody>
        </Table>
    );
};

export default BdTable;
