import * as React from "react";
import { Badge } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";
import BdTooltip from "../bd-common/bd-tooltip";
import { BdIconCancel } from "../bd-graphics/bd-icons";
import bdTagStyle from "./bd-tag.module.css";

interface BdTagProps extends React.ButtonHTMLAttributes<HTMLSpanElement> {
    bdStyle?: BdTagStyle
    bdText?: string,
    bdDisabled?: boolean,
    bdTooltip?: string,
    bdTooltipPlacement?: Placement,
    bdTooltipDiscard?: string
    onDiscard?: () => void,
    children?: React.ReactNode
}

export enum BdTagStyle {
    DEFAULT = 100,
    PRIMARY = 200,
    SUCCESS = 300,
    INFO = 400,
    WARNING = 500,
    DANGER = 600
}

/**
 * Simple tag. With a text and discard button (if onDiscard provided) as default, or custom content
 */
export const BdTag = ({
    bdStyle,
    bdText,
    bdDisabled = false,
    bdTooltip,
    bdTooltipPlacement,
    bdTooltipDiscard,
    onDiscard,
    children,
}: BdTagProps) => {
    const labelStyle = (): string => {
        switch (bdStyle) {
            case BdTagStyle.PRIMARY:
                return "primary";
            case BdTagStyle.SUCCESS:
                return "success";
            case BdTagStyle.INFO:
                return "info";
            case BdTagStyle.WARNING:
                return "warning";
            case BdTagStyle.DANGER:
                return "danger";
            default:
                return "";
        }
    };

    const _handeDiscardButtonClick = () => {
        if (!bdDisabled && onDiscard) {
            onDiscard();
        }
    };

    //return default content: text and discard button if onDiscard provided
    const _defaultContent = (): JSX.Element => {
        let discardButton: JSX.Element = {
            type: undefined,
            props: undefined,
            key: null
        };

        if (onDiscard) {
            discardButton = (<BdIconCancel className={bdTagStyle.bdDiscardButton} size={10} onClick={_handeDiscardButtonClick} />);

            if (bdTooltipDiscard) {
                discardButton = (
                    <BdTooltip bdBody={bdTooltipDiscard} bdDelay={800} bdPlacement={bdTooltipPlacement}>
                        <span>{discardButton}</span>
                    </BdTooltip>
                );
            }
        }

        return (
            <>
                {bdText}
                {onDiscard && discardButton}
            </>
        );
    };

    //tag object
    let _result: JSX.Element = (
        <Badge bg={labelStyle()} className={bdTagStyle.bdBadgeLabel}>
            {children || _defaultContent()}
        </Badge>
    );

    //add tooltip if needed
    if (bdTooltip) {
        _result = (
            <BdTooltip bdBody={bdTooltip} bdDelay={800} bdPlacement={bdTooltipPlacement}>
                {_result}
            </BdTooltip>
        );
    }

    return _result;
};


