import { BdAlert, BdAlertType, BdButton, BdCol, BdGrid, BdRow, BdSelector, useBdToast } from "bundledocs.common.client/modules/bd-components";
import { BdIconDisappointed, BdIconDownload } from "bundledocs.common.client/modules/bd-graphics";
import { UrlHelper } from "bundledocs.common.client/modules/bd-helpers";
import { BdEnum, BdEnumArray, BdEnums, BdFormFilter } from "bundledocs.common.client/modules/bd-models";
import { BdMargin } from "bundledocs.common.client/modules/bd-style";
import FormatHelper from "bundledocs.common.client/src/helpers/format-helper";
import React, { useState } from "react";
import { BdSubscriptionAnalysisBean } from "../../models/providers/bean/bd-subscription-analysis-bean/bd-subscription-analysis-bean";
import BundledocsServicesProvider from "../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../services/portal-api/bd-bundledocs-portal-api";
import BdSubscriptionAnalysisSearchForm from "./bd-subscription-analysis-search-form";
import bdStyle from "./bd-subscription-analysis-search.module.css";
import BdSubscriptionAnalysisTable from "./bd-subscription-analysis-table";

export type BdSubscriptionAnalysisSearchProps = React.HTMLAttributes<HTMLElement>

//object interface used to manage the alert information
interface AlertData {
    type: BdAlertType,
    show: boolean,
    message: string
}

export interface BdStripePlanEnum extends BdSubscriptionAnalysisBean, BdEnum { }

/**
 * Component that allows to search a subscription by invoice date, and list the result in a table
 * @param param0
 */
export default function BdSubscriptionAnalysisSearch({
    ...otherProps
}: BdSubscriptionAnalysisSearchProps): JSX.Element {

    // date range
    const startDate: Date = FormatHelper.subtractDays(new Date(), 7);
    const endDate: Date = new Date();

    const { toastError } = useBdToast();

    const [alertData, setAlertData] = useState<AlertData>({
        type: BdAlertType.Warning,
        show: false,
        message: ""
    });

    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    //show the alert
    const _openAlert = (type: BdAlertType, message: string) => {
        setAlertData({
            ...alertData,
            type: type,
            message: message,
            show: true
        });
    };

    //hide the alert
    const _closeAlert = () => {
        setAlertData({
            ...alertData,
            show: false
        });
    };

    const [subscriptions, setSubscriptions] = useState<BdSubscriptionAnalysisBean[]>([]);
    const [subscriptionsFiltered, setSubscriptionsFiltered] = useState<BdSubscriptionAnalysisBean[]>([]);

    const [activityFilterSelected, setActivityFilterSelected] = useState<any[]>([]);
    const [activityFilterOptions, setActivityFilterOptions] = useState<BdEnumArray>([]);

    const [columnNames, setColumnNames] = useState<any[]>([]);
    const [columnValues, setColumnValues] = useState<any[][]>([]);

    const _bdEnums = new BdEnums();

    /** assign activities to multi-select component */
    React.useEffect(() => {
        //declare columnNames array      
        const columnNames: string[] = [
            "InvoiceDate",
            "Activity",
            "Email",
            "Currency",
            "PlanNameCurrent",
            "PlanFeeCurrent",
            "PlanNamePrevious",
            "PlanFeePrevious",
            "PlanFeeDifference",
            "Status"
        ];
        setColumnNames(columnNames);
        initActivityFilter();
    }, []);

    /** generate columnValues once columnNames is populated */
    React.useEffect(() => {
        if (columnNames.length > 0) {
            const newColumnValues: (string[])[] = [];

            subscriptionsFiltered.sort((a, b) => a.InvoiceDate > b.InvoiceDate ? 0 : 1).forEach((sub: BdSubscriptionAnalysisBean) => {
                const rowData: any[] = columnNames.map((column: keyof BdSubscriptionAnalysisBean) => sub[column]);
                newColumnValues.push(rowData);
            });

            setColumnValues(newColumnValues);
        }
    }, [subscriptionsFiltered]);

    React.useEffect(() => {
        applyActivityFilterSelected();
    }, [activityFilterSelected, subscriptions]);

    const initActivityFilter = () => {
        setActivityFilterOptions(_bdEnums.UserSubscriptionActivities);

        // Map bdFilters to an array of codes
        const initialSelectedActivities = _bdEnums.UserSubscriptionActivities.map(filter => filter.code);
        setActivityFilterSelected(initialSelectedActivities);
    };

    /**  change options in dropdown  **/
    const _handleActivityFilterChange = async (selectedOption: BdEnum | BdEnum[]) => {
        if (Array.isArray(selectedOption) && subscriptions) {
            setActivityFilterSelected(selectedOption.map(k => k.code));

            applyActivityFilterSelected(selectedOption.map(k => k.code));
        }
    };

    const applyActivityFilterSelected = (activities?: any[]) => {
        const selectedActivitiesAsEnums = _bdEnums.UserSubscriptionActivities.filter(k => (activities ?? activityFilterSelected).some(l => k.code == l));

        const result: BdSubscriptionAnalysisBean[] = subscriptions.filter(sub => selectedActivitiesAsEnums.some(opt => opt.description === sub.Activity));

        // need to clean old data before assigning the data
        setSubscriptionsFiltered([]);
        setSubscriptionsFiltered(result);
    };

    const _handleOnExportCSVClick = () => {
        const csvContent = [columnNames.join(","), ...columnValues.map(row => row.join(","))].join("\r\n");
        const link = document.createElement("a");
        link.href = "data:text/plain;charset=utf-8," + encodeURIComponent(csvContent);
        link.setAttribute("download", "Subscription Report.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    //executes the search
    const _handleSearch = async (searchData: BdFormFilter[]) => {
        if (searchData?.length > 0) {
            try {
                _closeAlert();

                const filterParameters: string = UrlHelper.createFilterParametersFromBdFormFilters(searchData, "invoicedate");
                const result: BdSubscriptionAnalysisBean[] = await _bundledocsApiInstance.Subscriptions.Search(filterParameters);

                // need to clean old data before assigning the data
                setSubscriptions([]);
                setSubscriptions(result);

                if (!result[0]) {
                    _openAlert(BdAlertType.Warning, "No subscriptions found. Try with another date");
                }
            }
            catch (error: any) {
                setSubscriptions([]);
                _openAlert(BdAlertType.Danger, error.message);
            }
        }
    };

    return (
        <BdGrid {...otherProps}>
            <BdSubscriptionAnalysisSearchForm onSearch={_handleSearch} startDate={startDate} endDate={endDate} btnSearchWorking={false} />
            <h5 style={{ marginTop: BdMargin.Normal }}>Filter by Activity:</h5>
            <BdRow>
                <BdCol xl={3} lg={3} md={3} sm={3}>
                    <BdSelector
                        bdOptions={activityFilterOptions}
                        bdIsMulti={true}
                        onChangeSelected={_handleActivityFilterChange}
                        bdValue={activityFilterSelected}
                        {...otherProps}
                    />
                </BdCol>
            </BdRow>
            <hr />
            <BdRow>
                <BdCol xl={12} lg={12} md={12} sm={12}>
                    <BdSubscriptionAnalysisTable bdSubscriptions={subscriptionsFiltered} style={{ marginTop: BdMargin.Normal }} />                    
                </BdCol>
            </BdRow>
            <BdRow>
                <BdCol xl={9} lg={9} md={9} sm={9}></BdCol>
                <BdCol xl={3} lg={3} md={3} sm={3} className={bdStyle.alignRight}>
                    <BdButton bdBlock={false} onClick={_handleOnExportCSVClick} bdText="Export CSV" bdIcon={<BdIconDownload size={22} />} disabled={subscriptionsFiltered.length === 0} />
                </BdCol>
            </BdRow>

            <BdAlert bdType={alertData.type} bdDissmisable={false} bdShow={alertData.show} onClose={_closeAlert} style={{ marginTop: BdMargin.Normal }}
                bdTitle={
                    <><BdIconDisappointed style={{ marginRight: BdMargin.Small }} />Oops!</>
                }>
                <p>{alertData.message}</p>
            </BdAlert>
        </BdGrid>
    );
}
