import { BdModal, BdTextBox, useBdToast } from "bundledocs.common.client/modules/bd-components";
import { BdToastFailureMessage } from "bundledocs.common.client/modules/bd-models";
import * as React from "react";
import { useState } from "react";
import {
    BdEnterpriseLicenseBean
} from "../../../models/providers/bean/bd-enterprise-license-bean/bd-enterprise-license-bean";
import {
    EnterpriseLicenseOptions
} from "../../../models/providers/enterprise-license-context/enterprise-license-options";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";

interface IBdEnterpriseLicenseResendModalProps {
    bdLicense: BdEnterpriseLicenseBean;
    onUpdate?: (license: BdEnterpriseLicenseBean) => void;
    onCancel?: Function;
    bdShow: boolean;
}

/** Modal for managing the resend licence process */
export const BdEnterpriseLicenseResendModal = ({
    bdLicense,
    onCancel,
    bdShow
}: IBdEnterpriseLicenseResendModalProps) => {
    const [updating, setUpdating] = useState(false);

    //toast functions
    const { toastSuccess, toastError } = useBdToast();

    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    /** set updating=false on close */
    React.useEffect(() => {
        if (!bdShow) {
            setUpdating(false);
        }
    }, [bdShow]);

    /** update user subscription data */
    const _handleEnterpriseLicenseUpdate = async (licenseOptions: EnterpriseLicenseOptions): Promise<boolean> => {

        let handleResult = true;

        try {
            setUpdating(true);
            await _bundledocsApiInstance.EnterpriseLicenses.ResendEnterpriseLicense(bdLicense.PartitionKey, bdLicense.RowKey, licenseOptions);

            toastSuccess("User's Enterprise License was resent successfully");

            onCancel?.();

            handleResult = true;
        }
        catch (error: any) {
            toastError(error as BdToastFailureMessage);
            handleResult = false;
        }
        finally {
            setUpdating(false);
        }

        return handleResult;
    };

    return (
        <BdModal bdTitle="Resend Enterprise License" bdDisabled={updating} onConfirm={_handleEnterpriseLicenseUpdate}
            onCancel={onCancel} bdShow={bdShow} >
            <BdTextBox
                bdLabel="Organization"
                name="Organization"
                style={{ marginBottom: "1rem" }}
                bdValue={bdLicense?.Organization}
                bdReadOnly={true}
            />
            <BdTextBox
                bdLabel="License"
                name="License"
                style={{ marginBottom: "1rem" }}
                bdValue={bdLicense?.Licensekey}
                bdReadOnly={true}
            />
            <BdTextBox
                bdLabel="Email"
                name="Email"
                style={{ marginBottom: "1rem" }}
                bdValue={bdLicense?.Email}
            />
        </BdModal>
    );
};
