import { BdServiceConfig, BdWorkerService } from "bundledocs.common.client/modules/bd-services";
import BdServiceAccountNumbers from "./bd-service-account-numbers";
import BdServiceLiveBundles from "./bd-service-bundles";
import BdServiceEnterpriseLicense from "./bd-service-enterprise-license";
import BdServiceNotifications from "./bd-service-notification";
import BdServicePlans from "./bd-service-plan";
import BdServiceReports from "./bd-service-reports";
import BdServiceSession from "./bd-service-session";
import BdServiceSubscriptionAnalysis from "./bd-service-subscription-analysis";
import BdServiceUser from "./bd-service-user";

/**
 * Class that puts together the services and configuration needed to use the bundledocs portal api
 **/
export class BdBundledocsPortalApi {
    private _configuration: BdServiceConfig;
    private _workerService: BdWorkerService;

    Users: BdServiceUser;
    EnterpriseLicenses: BdServiceEnterpriseLicense;
    Subscriptions: BdServiceSubscriptionAnalysis;
    AccountNumbers: BdServiceAccountNumbers;
    Session: BdServiceSession;
    Reports: BdServiceReports;
    Plans: BdServicePlans;
    Notifications: BdServiceNotifications;
    LiveBundles: BdServiceLiveBundles;

    //request failed callback
    private requestErrorHandler = (error: any) => {
        //temporal login solution
        if (error.response === undefined) {
            window.location.href = error.config.url;
        }
    };

    constructor(baseUrl: string) {
        this._configuration = {
            BaseUrl: baseUrl,
            WithCredentials: true,
            requestErrorHandler: this.requestErrorHandler
        };
        this._workerService = new BdWorkerService(this._configuration);

        this.Users = new BdServiceUser(this._configuration);
        this.EnterpriseLicenses = new BdServiceEnterpriseLicense(this._configuration);
        this.Subscriptions = new BdServiceSubscriptionAnalysis(this._configuration);
        this.AccountNumbers = new BdServiceAccountNumbers(this._configuration);
        this.Session = new BdServiceSession(this._configuration);
        this.Reports = new BdServiceReports(this._workerService);
        this.Plans = new BdServicePlans(this._configuration);
        this.Notifications = new BdServiceNotifications(this._configuration);
        this.LiveBundles = new BdServiceLiveBundles(this._configuration);
    }
}
