import { BdRadioInput } from "bundledocs.common.client/modules/bd-components";
import { BdMargin } from "bundledocs.common.client/src";
import { BdUserSubscriptionTypes } from "bundledocs.common.client/src/constants/bd-enums";
import React from "react";

export interface BdUsersSearchListProps extends React.HTMLAttributes<HTMLElement> {
    bdExpiredValue: BdUserSubscriptionTypes;
    onUpdate?: (data: any) => void;
}

/**
 * Components that show filter components
 */
export default function BdUsersFilterSearchList({
    bdExpiredValue = BdUserSubscriptionTypes.TRIAL_EXPIRED,
    onUpdate,
    ...otherProps
}: BdUsersSearchListProps): JSX.Element {
    return (
        <>
            <h5 style={{ marginTop: BdMargin.Normal }}>Filters:</h5>
            <div style={{ display: "flex" }}>
                <h6 style={{ marginRight: BdMargin.Normal, marginBottom: 0 }}>Expired users</h6>
                <BdRadioInput bdChecked={bdExpiredValue.toString() === BdUserSubscriptionTypes.TRIAL_EXPIRED.toString()}
                    bdValue={BdUserSubscriptionTypes.TRIAL_EXPIRED.toString()}
                    onChange={onUpdate}
                    bdLabel='Hide'
                    labelStyles={{ marginRight: BdMargin.Small }}
                />
                <BdRadioInput bdChecked={bdExpiredValue.toString() === BdUserSubscriptionTypes.ALL.toString()}
                    bdValue={BdUserSubscriptionTypes.ALL.toString()}
                    onChange={onUpdate}
                    bdLabel='Show'
                />
            </div>
            <hr />
        </>
    );
}
