import * as React from "react";
import BdButton from "../bd-buttons/bd-button";
import { BdButtonSize, BdButtonType } from "../bd-buttons/bd-buttons-constants";
import { BdIconItemSelected, BdIconItemUnselected } from "../bd-graphics/bd-icons";

/*
 * EXAMPLE OF USE:
 * 
 * const { register, selectedItems } = useOrderSelector();
 *
 * <>
 *  <BdOrderSelector {...register('Id_1')} />
 *  <BdOrderSelector {...register('Id_2')} />
 *  <BdOrderSelector {...register('Id_3')} />
 *  <BdOrderSelector {...register('Id_4')} />
 * </>
 *
 * selectedItems => list that contains the selected ids ordered
 *                  ['Id_3', 'Id_1']
 */


/*
 * Custom Hook to manage the orderly selection
 */
export interface useOrderSelectorRegisterResult {
    id: string;
    position: number;
    onSelectedChange: (id: string) => void;
    onShiftSelectedChange: (id: string) => void;
}

export interface useOrderSelectorResult {
    register: (id: string) => useOrderSelectorRegisterResult;
    setAllItems: (ids: string[]) => void;
    clearSelection: () => void;
    removeItem: (id: string) => void;
    selectedItems: string[];
}

export function useOrderSelector(): useOrderSelectorResult {
    const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
    const allItems = React.useRef<string[]>([]);

    const _register = (id: string): useOrderSelectorRegisterResult => {
        return {
            id: id,
            position: selectedItems.indexOf(id),
            onSelectedChange: _onChecked,
            onShiftSelectedChange: _onShiftSelected
        };
    };

    // Single item selection
    const _onChecked = (id: string): void => {
        const newSelectedItems = [...selectedItems];
        const itemIndex: number = newSelectedItems.indexOf(id);
        if (itemIndex < 0) {
            newSelectedItems.push(id);
        }
        else {
            newSelectedItems.splice(itemIndex, 1);
        }
        setSelectedItems(newSelectedItems);
    };

    // Multiple item selection with shift key
    const _onShiftSelected = (id: string) => {
        if (allItems.current) {
            //find current item position in list
            const currentSelectionIndex = allItems.current.indexOf(id);

            //get the index of the last checked item in list
            const lastCheckedItem = selectedItems[selectedItems.length - 1];
            const lastCheckedIndex = allItems.current.indexOf(lastCheckedItem);

            //determine if adding or removing checkboxes
            const addingCheckbox: boolean = selectedItems.indexOf(id) > -1 ? false : true;

            if (lastCheckedIndex === -1) {
                //ensure check item when no previous items are selected
                _onChecked(id);
            } else {
                let startPos = lastCheckedIndex;
                const endPos = currentSelectionIndex;
                const newSelectedItems = [...selectedItems];

                const _toggleCheckbox = (currentPos: number) => {
                    const nextId: string = allItems.current[currentPos];
                    const itemIndex: number = newSelectedItems.indexOf(nextId);
                    if (itemIndex < 0 && addingCheckbox) {
                        newSelectedItems.push(nextId);
                    }
                    else if (itemIndex > -1 && addingCheckbox === false) {
                        newSelectedItems.splice(itemIndex, 1);
                    }
                };

                if (lastCheckedIndex > currentSelectionIndex) {
                    //selection from bottom of list towards top
                    for (; startPos >= endPos; startPos--) {
                        _toggleCheckbox(startPos);
                    }
                } else {
                    //selection from top of list towards bottom
                    for (; startPos <= endPos; startPos++) {
                        _toggleCheckbox(startPos);
                    }
                }

                setSelectedItems(newSelectedItems);
            }
        }
    };

    // Empty entire item selection
    const _clearSelection = (): void => {
        setSelectedItems([]);
    };

    // Empty individual item
    const _removeItem = (id: string): void => {
        const itemIndex: number = selectedItems.indexOf(id);
        if (itemIndex >= 0) {
            const newSelectedItems = [...selectedItems];
            newSelectedItems.splice(itemIndex, 1);
            setSelectedItems(newSelectedItems);
        }
    };

    // All items available to be selected
    const _setAllItems = (ids: string[]): void => {
        allItems.current = ids;
    };


    return {
        register: _register,
        setAllItems: _setAllItems,
        clearSelection: _clearSelection,
        removeItem: _removeItem,
        selectedItems: selectedItems
    };
}

/*
 * Order Selector
 */
export interface BdOrderSelectorProps extends useOrderSelectorRegisterResult {
    bdSize?: BdButtonSize,
    disabled?: boolean,
    className?: string | undefined
}

export const BdOrderSelector = ({
    id,
    position,
    onSelectedChange,
    onShiftSelectedChange,
    ...buttonProps
}: BdOrderSelectorProps) => {

    const _handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event.shiftKey) {
            onShiftSelectedChange(id);
        }
        else {
            onSelectedChange(id);
        }
    };
    const _handleDoubleClick = (e: React.MouseEvent<HTMLButtonElement>) => e?.stopPropagation();

    const _selected: boolean = position > -1;

    return (
        <div>
            <BdButton
                bdText={_selected ? (position + 1).toString() : ""}
                bdIcon={_selected ? <BdIconItemSelected /> : < BdIconItemUnselected />}
                bdType={_selected ? BdButtonType.Danger : BdButtonType.Primary}
                bdSize={BdButtonSize.Normal}
                bdBlock={false}
                onClick={_handleClick}
                onDoubleClick={_handleDoubleClick}
                {...buttonProps}
            />
        </div>
    );
};
