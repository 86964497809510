import { BaseModel } from "../BaseModel";

export enum DownloadInvitationDocumentResourceType {
    Pdf = 10,
    Source = 11
}

export enum DownloadInvitationResourceType {
    BriefReceipt = 0,
    BriefDocument = 10,
    BriefDocumentSource = 11
}

export interface DownloadInvitation extends BaseModel {
    Email: string;
    Password: string;
    Description: string;
    Message: string;
    ResourceType: DownloadInvitationResourceType;

    /*@start - Login Protection*/
    PasswordAttemptCounter: number;
    PasswordAttemptTimeout: string;
    /*@end*/

    //allow the invitation to expire after a set period
    ExpiryDownloadCount: number;
    ExpiryDownloadLimit: number;
    ExpiryDate: Date;

    //contains the web hook action identifier for this invitation
    ActionUri: string
    //contains the web hook action key for this invitation
    ActionKey: string
}
