import { EmailAddress } from "../../models/providers/domain/shared-context/EmailAddress";
import { BdApiResponse, BdServiceBase, BdServiceConfig } from "../bd-service-base";
import IBdServiceSessions from "./interfaces/IBdServiceSessions";

/**
 * Sessions Service
 * */
export class BdServiceSessions extends BdServiceBase implements IBdServiceSessions {
    constructor(configuration: BdServiceConfig) {
        super(configuration);
    }
    private static ApiUrl = "/api/v1/sessions";

    /**
    * Reset password request
    */
    resetPassword(forgotPassword: EmailAddress): Promise<BdApiResponse<string>> {
        return super.Post(`${BdServiceSessions.ApiUrl}/password/reset`, forgotPassword);
    }
}
