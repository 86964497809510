import cx from "classnames";
import * as React from "react";
import { CSSProperties, useRef, useState } from "react";
import bdStyle from "./bd-split-view.module.css";

export interface BdSplitViewProps {
    direction: "vertical" | "horizontal";
    panelOneContent: React.ReactNode;
    panelTwoContent: React.ReactNode;
    firstSectionSize?: string;
}
export function BdSplitView({
    direction = "horizontal",
    panelOneContent,
    panelTwoContent,
    firstSectionSize
}: BdSplitViewProps) {
    const [currentFirstSectionSize, setCurrentFirstSectionSize] = useState(firstSectionSize ?? (direction === "vertical" ? "60%" : "40%"));

    const xPositionRef = useRef<number>(0);
    const yPositionRef = useRef<number>(0);
    const firstSectionSizeRef = useRef<number>(0);

    const firstSectionRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const _resizerMouseDownHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        xPositionRef.current = e.clientX;
        yPositionRef.current = e.clientY;

        firstSectionSizeRef.current = direction === "vertical"
            ? firstSectionRef.current!.getBoundingClientRect().height
            : firstSectionRef.current!.getBoundingClientRect().width;

        containerRef.current!.style.cursor = direction === "vertical" ? "ns-resize" : "ew-resize";
        document.body.classList.add(bdStyle.PreventSelect);

        document.addEventListener("mousemove", _documentMouseMoveHandler);
        document.addEventListener("mouseup", _documentMouseUpHandler);
    };

    const _documentMouseMoveHandler = (e: MouseEvent) => {
        const dx = e.clientX - xPositionRef.current;
        const dy = e.clientY - yPositionRef.current;

        const newFirstSectionSize = direction === "vertical"
            ? ((firstSectionSizeRef.current + dy) * 100) / containerRef.current!.getBoundingClientRect().height
            : ((firstSectionSizeRef.current + dx) * 100) / containerRef.current!.getBoundingClientRect().width;

        setCurrentFirstSectionSize(newFirstSectionSize + "%");
    };

    const _documentMouseUpHandler = () => {
        containerRef.current!.style.cursor = "";
        document.body.classList.remove(bdStyle.PreventSelect);

        document.removeEventListener("mousemove", _documentMouseMoveHandler);
        document.removeEventListener("mouseup", _documentMouseUpHandler);
    };

    const firstSectionStyle: CSSProperties = direction === "vertical"
        ? {
            height: currentFirstSectionSize
        }
        : {
            width: currentFirstSectionSize
        };

    return (
        <div ref={containerRef} className={cx(bdStyle.Container, direction)} >
            <div ref={firstSectionRef} className={bdStyle.SectionOne} style={firstSectionStyle}>{panelOneContent}</div>
            <div className={bdStyle.Resizer} onMouseDown={_resizerMouseDownHandler}></div>
            <div className={bdStyle.SectionTwo}>{panelTwoContent}</div>
        </div>
    );
}
