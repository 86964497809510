import { BdEnum, UserRole } from "bundledocs.common.client/modules/bd-models";
import { BdSelector, ISelectorProps } from "bundledocs.common.client/modules/bd-components";
import { UserRolesPortal } from "../../../constants/bd-enum-types";

/*
 * Select User Role props
 */
export type BdSelectUserRoleProps = ISelectorProps

/** User Role selection component */
export const BdSelectUserRole = ({
    onUpdate,
    ...otherProps
}: BdSelectUserRoleProps) => {
    const _handleOnUpdate = (selectedOption: BdEnum | BdEnum[]) => {
        if (!Array.isArray(selectedOption)) {
            onUpdate?.(selectedOption.code as UserRole);
        }
    };

    return (
        <BdSelector
            bdOptions={UserRolesPortal}
            onChangeSelected={_handleOnUpdate}
            {...otherProps}
        />
    );
};
