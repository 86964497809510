import { Activity, BriefDocument } from "../../../modules/bd-models";
import { BdApiResponse, BdServiceBase, BdServiceConfig } from "../bd-service-base";
import IBdServiceDocuments, { BdDownloadType, BriefDocumentActionUnlockOptions, DeleteTagsOptions, PatchTagsOptions } from "./interfaces/IBdServiceDocuments";

/**
 * Documents Service
 * */
export class BdServiceDocuments extends BdServiceBase implements IBdServiceDocuments {
    constructor(configuration: BdServiceConfig) {
        super(configuration);
    }

    private static ApiUrl = "/api/v1/documents";
    private static DownloadUrl = "/api/v1/downloads";

    GetActivities(document: BriefDocument): Promise<BdApiResponse<Activity[]>> {
        return super.Get(`${BdServiceDocuments.ApiUrl}/${document.PartitionKey}/${document.RowKey}/activitys`);
    }

    ApplyUnlock(document: BriefDocument, options: BriefDocumentActionUnlockOptions): Promise<BdApiResponse<BriefDocument>> {
        return super.Put(`${BdServiceDocuments.ApiUrl}/${document.PartitionKey}/${document.RowKey}/unlock`, options);
    }

    async Download(document: BriefDocument, downloadType: BdDownloadType): Promise<void> {
        const result = await super.Post(`${BdServiceDocuments.ApiUrl}/${document.PartitionKey}/${document.RowKey}/downloads/${downloadType}`);

        if (result.data?.length > 0) {
            const downloadToken = result.data[0];
            const downloadHost = result.data[1];

            window.location.href = `${downloadHost}${BdServiceDocuments.DownloadUrl}?token=${downloadToken}`;
        }
    }

    PatchTags(document: BriefDocument, patchTagsOptions: PatchTagsOptions): Promise<BdApiResponse<BriefDocument>> {
        return super.Patch(`${BdServiceDocuments.ApiUrl}/${document.PartitionKey}/${document.RowKey}/tags`, patchTagsOptions);
    }

    DeleteTags(document: BriefDocument, deleteTagsOptions: DeleteTagsOptions): Promise<BdApiResponse<BriefDocument>> {
        return super.Delete(`${BdServiceDocuments.ApiUrl}/${document.PartitionKey}/${document.RowKey}/tags?options=${encodeURIComponent(JSON.stringify(deleteTagsOptions))}`);
    }
}
