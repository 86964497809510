
/**
 * Class with common methods related with string formatting
 * */
export default class StringHelper {

    //string to title case string
    static toTitleCase(str: string): string {
        return (
            str.toLowerCase().split(" ").map(function (word) {
                return (word.charAt(0).toUpperCase() + word.slice(1));
            }).join(" ")
        );
    }

    //returns a random string of four characters
    private static s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    //return a random guid
    static newGuid(): string {
        return this.s4() + this.s4() + "-" + this.s4() + "-" + this.s4() + "-" + this.s4() + "-" + this.s4() + this.s4() + this.s4();
    }

    //returns the list of elements of a path
    static getPathElements(path: string): string[] {
        return StringHelper.trimStart(path?.replace(/\\/g, "/"), "/")?.split("/") ?? [];
    }

    static trimStart(value: string, character: string): string {
        return value?.startsWith(character ?? "") ? value.slice(1) : value ?? "";
    }

    //return a nonce
    static readonly nonce: string = StringHelper.newGuid();

    static isNullOrWhiteSpace(nullOrWhiteSpace: string) {
        return nullOrWhiteSpace === undefined || nullOrWhiteSpace === null || typeof (nullOrWhiteSpace) === "boolean" || nullOrWhiteSpace.toString().replace(/\s/g, "") === "";
    }

    static hashString(str: string) {
        let hash = 0, i, chr;
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
}
