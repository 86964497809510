import cx from "classnames";
import { ReactElement } from "react";
import Avatar from "react-avatar";
import { Nav, NavItem } from "react-bootstrap";
import { BdButton, BdButtonType, BdNavbar, BdPanelLeft } from "../../../modules/bd-components";
import { BdIconSidebarCollapse, BdIconSidebarExpand } from "../bd-graphics/bd-icons";
import bdSidebarStyle from "./bd-sidebar.module.css";

export interface BdSidebarProps extends React.HTMLAttributes<HTMLElement> {
    bdNavigationItems: BdNavigationItem[];
    bdExtended: boolean;
    bdUsername: string;
    onToggleSidebar?: Function;
    onClickNavigationLink?: Function;
}

export interface BdNavigationItem {
    bdIcon: ReactElement;
    bdText: string;
    bdNavigate: string;
    bdActive?: boolean;
}

/**
 * Sidebar component
 * @param props
 */
const BdSidebar = ({
    bdNavigationItems,
    bdExtended = true,
    bdUsername = "",
    onToggleSidebar = void 0,
    onClickNavigationLink = void 0,
    ...otherProps
}: BdSidebarProps) => {
    //structure user name - if email remove the domain
    const displayUserName: string = bdUsername.indexOf("@") > 0 ? bdUsername.substring(0, bdUsername.indexOf("@") + 1) : bdUsername;

    //builds navigation buttons
    const _sidebarItems = bdNavigationItems.map((item: BdNavigationItem, index: number) =>
        <NavItem key={index}>
            <BdButton bdIcon={item.bdIcon}
                bdText={bdExtended ? item.bdText : ""}
                bdType={BdButtonType.Light}
                onClick={() => onClickNavigationLink?.(item)}
                className={cx(
                    "nav-link link-dark",
                    !bdExtended && "border-bottom",
                    bdExtended ? bdSidebarStyle.bdLinkExpand : bdSidebarStyle.bdLinkCollapse,
                    item.bdActive && "active"
                )}
                {...otherProps}/>
        </NavItem>
    );

    return bdExtended ?
        (
            <BdPanelLeft bdClass={cx("d-flex flex-column flex-shrink-0 p-3 bg-light", bdSidebarStyle.bdSidebar)}>
                <BdNavbar bdExtended={bdExtended} />
                <hr style={{ marginTop: 0 }} />
                <Nav className={"nav nav-pills flex-column mb-auto"}>
                    {_sidebarItems}
                </Nav >
                <hr />
                <div className={bdSidebarStyle.bdSidebarFooterExpand}>
                    <div>
                        <div className={"d-flex align-items-center link-dark text-decoration-none"}>
                            <Avatar name={displayUserName} size="32" round={true} textSizeRatio={1.75} />
                            <div className={bdSidebarStyle.bdUserName}>{displayUserName}</div>
                        </div>
                        <div className={bdSidebarStyle.bdSidebarFooterNavigation}>
                            <BdButton bdIcon={<BdIconSidebarCollapse size={25} />} bdType={BdButtonType.Light} className={bdSidebarStyle.bdNavigationButton} onClick={() => onToggleSidebar?.(!bdExtended)} />
                        </div>
                    </div>
                </div>
            </BdPanelLeft>
        )
        : (
            <BdPanelLeft bdClass={cx("d-flex flex-column flex-shrink-0 bg-light", bdSidebarStyle.bdSidebar, bdSidebarStyle.bdSidebarCollapsed)}>
                <BdNavbar bdExtended={bdExtended} />

                <Nav className="nav nav-pills nav-flush flex-column mb-auto text-center">
                    {_sidebarItems}
                </Nav >

                <div className={bdSidebarStyle.bdSidebarFooterCollapse}>
                    <div className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none">
                        <Avatar name={displayUserName} size="32" round={true} textSizeRatio={1.75} />
                    </div>
                    <div className={bdSidebarStyle.bdSidebarFooterNavigation}>
                        <BdButton bdIcon={<BdIconSidebarExpand size={25} />} bdType={BdButtonType.Light} className={bdSidebarStyle.bdNavigationButton} onClick={() => onToggleSidebar?.(!bdExtended)} />
                    </div>
                </div>
            </BdPanelLeft>
        );
};

export default BdSidebar;
