import { UrlHelper } from "bundledocs.common.client/modules/bd-helpers";
import { BdEnums, BdFormFilter } from "bundledocs.common.client/modules/bd-models";
import { BdAlert, BdAlertType, BdButton, BdButtonType, BdCol, BdGrid, BdIconDisappointed, BdIconIoList, BdMargin, BdRow, useBdModal } from "bundledocs.common.client/src";
import FormatHelper from "bundledocs.common.client/src/helpers/format-helper";
import SearchFilterHelper from "bundledocs.common.client/src/helpers/search-filter-helper";
import React, { useEffect, useState } from "react";
import {
    BdNotificationBean, GlobalNotificationCategory
} from "../../../models/providers/bean/bd-notification-bean/bd-notification-bean";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";
import { BdNotificationsCreateModal } from "../bd-notifications-actions/bd-notifications-create";
import { BdPreviewNotificationModal } from "../bd-notifications-actions/bd-preview-notification";
import bdStyle from "./bd-notification-search-list.module.css";
import BdNotificationsSearchForm from "./bd-notifications-search-form";
import BdNotificationsSearchList from "./bd-notifications-search-list";

export type BdNotificationsSearchProps = React.HTMLAttributes<HTMLElement>

//object interface used to manage the alert information
interface AlertData {
    type: BdAlertType,
    show: boolean,
    message: string
}

/**
 * Component that allows to search a notification by date range, and list the result in a table
 * @param param0
 */
export default function BdNotificationsSearch({
    ...otherProps
}: BdNotificationsSearchProps): JSX.Element {

    const _bdEnums = new BdEnums();
    const _bundleDocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    const startDate: Date = FormatHelper.addYearsToDate(new Date(), -1);
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [btnSearchWorking, setBtnSearchWorking] = useState<boolean>(false);

    //function to return an empty notification bean
    function createEmptyNotificationBean(): BdNotificationBean {
        return {
            Title: "",
            Body: "",
            ActionText: "",
            ActionUrl: "",
            Category: GlobalNotificationCategory.General,
            CategoryName: "General",
            PartitionKey: "",
            RowKey: "",
            Type: 0,
            Context: "",
            Batch: "",
            Progress: 0,
            TimeToLive: new Date,
            TimeToLiveString: ""
        };
    }

    const [notifications, setNotifications] = useState<BdNotificationBean[]>([]);
    const [draftNotification, setDraftNotification] = useState<BdNotificationBean>(createEmptyNotificationBean());

    // display the notifications by default rendering
    useEffect(() => {
        const data = {
            dateRangeStart: FormatHelper.dateToStringShort(startDate),
            dateRangeEnd: FormatHelper.dateToStringShort(endDate)
        };
        const filters: BdFormFilter[] = SearchFilterHelper.transformDateRangeToBdFormFilters(data);

        setBtnSearchWorking(true);
        _handleSearch(filters);
    }, []);

    const [alertData, setAlertData] = useState<AlertData>({
        type: BdAlertType.Warning,
        show: false,
        message: ""
    });

    //show the alert
    const _openAlert = (type: BdAlertType, message: string) => {
        setAlertData({
            ...alertData,
            type: type,
            message: message,
            show: true
        });
    };

    //hide the alert
    const _closeAlert = () => {
        setAlertData({
            ...alertData,
            show: false
        });
    };

    // Define a function to set CategoryName based on Category code
    function _setCategoryNames(notifications: BdNotificationBean[]): BdNotificationBean[] {
        return notifications.map(notification => {
            const category = _bdEnums.GlobalNotificationCategory.find(cat => cat.code === notification.Category);
            if (category) {
                return {
                    ...notification,
                    CategoryName: category.description,
                    TimeToLiveString: FormatHelper.dateToString(new Date(notification.TimeToLive))
                };
            } else {
                return notification; // If category not found, return notification as it is
            }
        });
    }

    //executes the search
    const _handleSearch = async (searchData: BdFormFilter[]) => {

        if (searchData.length === 0) {
            return;
        }

        try {
            _closeAlert();

            const filterParameters: string = UrlHelper.createFilterParametersFromBdFormFilters(searchData, "created");
            const result: BdNotificationBean[] = await _bundleDocsApiInstance.Notifications.Search(filterParameters);

            // need to clean old data before assigning the data
            setNotifications([]);

            // Before setting the state, call _setCategoryNames function to set CategoryName
            const updatedNotifications = _setCategoryNames(result);
            setNotifications(updatedNotifications);

            if (!result[0]) {
                _openAlert(BdAlertType.Warning, "No notifications found. Try with another date");
            }
        }
        catch (error: any) {
            setNotifications([]);
            _openAlert(BdAlertType.Danger, error.message);
        }

        setBtnSearchWorking(false);
    };

    //modal managers
    const notificationCreateModal = useBdModal();
    const notificationPreviewModal = useBdModal();

    const _handleCreateNotifications = (): void => {
        notificationCreateModal.Show();
    };

    const _handleCancelPublish = (notification: BdNotificationBean): void => {
        notificationPreviewModal.Close();
        notificationCreateModal.Show();
        setDraftNotification(notification);
    };

    const _handleCancelAdd = (): void => {
        setDraftNotification(createEmptyNotificationBean());
        notificationPreviewModal.Close();
        notificationCreateModal.Close();
    };

    const _onNotificationCreate = (notification: BdNotificationBean) => {
        setDraftNotification(notification);
        notificationCreateModal.Close();
        notificationPreviewModal.Show();
    };

    const _onNotificationPublish = (notification: BdNotificationBean) => {
        // insert new value in front of list of notifications for correct displaying
        notifications.unshift(notification);

        const newNotifications: BdNotificationBean[] = notifications;
        setNotifications([]);//cleaning old data
        const updatedNotifications = _setCategoryNames(newNotifications);
        setNotifications(updatedNotifications);

        _closeAlert();
        setDraftNotification(createEmptyNotificationBean());

        notificationPreviewModal.Close();
        notificationCreateModal.Close();
    };

    const _onNotificationDelete = (notification: BdNotificationBean) => {

        const notificationsAfterDelete = notifications.filter(item => item.RowKey !== notification.RowKey);

        setNotifications([]);//cleaning old data
        const updatedNotificationsList = _setCategoryNames(notificationsAfterDelete);
        setNotifications(updatedNotificationsList);
    };

    return (
        <BdGrid {...otherProps} className={bdStyle.GridContainer} >
            <BdNotificationsSearchForm onSearch={_handleSearch} startDate={startDate} endDate={endDate} setEndDate={setEndDate} btnSearchWorking={btnSearchWorking} />

            <BdRow style={{ justifyContent: "end" }}>
                <BdCol xl={3} lg={3} md={12} sm={12}>
                    <div style={{ margin: "0 0.5rem", textAlign: "center" }}>OR</div>
                    <BdButton type="button"
                        bdType={BdButtonType.Success}
                        bdText="New Notification"
                        bdBlock={true}
                        onClick={_handleCreateNotifications}
                        bdIcon={<BdIconIoList />}
                    />
                </BdCol>
            </BdRow>
            <BdAlert bdType={alertData.type} bdDissmisable={false} bdShow={alertData.show} onClose={_closeAlert} style={{ marginTop: BdMargin.Normal }}
                bdTitle={
                    <><BdIconDisappointed style={{ marginRight: BdMargin.Small }} />Oops!</>
                }>
                <p>{alertData.message}</p>
            </BdAlert>

            <div className={bdStyle.MainContainer} >
                <div className={bdStyle.BodyContainer}>
                    {notifications[0] && <BdNotificationsSearchList bdNotifications={notifications}
                        onNotificationDelete={_onNotificationDelete}
                        style={{ marginTop: BdMargin.Normal }} createdDate={endDate} />}
                </div>
            </div>
            <BdNotificationsCreateModal bdNotification={draftNotification} {...notificationCreateModal.BdModalProps} onCreate={_onNotificationCreate} bdTitle={"Add Notification"} onCancel={_handleCancelAdd}></BdNotificationsCreateModal>
            <BdPreviewNotificationModal bdDraftNotification={draftNotification} {...notificationPreviewModal.BdModalProps} onPublish={_onNotificationPublish} onCancelPublish={_handleCancelPublish}></BdPreviewNotificationModal>
        </BdGrid>
    );
}
