import * as React from "react";
import { ContainerProps, Form, FormGroup } from "react-bootstrap";
import Select, { ControlProps, GroupHeadingProps, GroupProps, IndicatorSeparatorProps, InputProps, MenuProps, OptionProps, OptionsOrGroups, ValueContainerProps } from "react-select";
import { BdEnum, BdEnumArray } from "../../models/providers/bean/common-context/bd-enums";
import { IFormFieldProps } from "../bd-form/bd-form-constants";
import { useBdForm } from "./bd-form";
import { selectedOptionMapper, selectMapper, SingleSelectOptionType } from "./bd-selector-mapper";

/* Bootstrap 5 version */

/** props */
export interface ISelectorProps extends IFormFieldProps, React.InputHTMLAttributes<HTMLSelectElement> {
    bdLabel?: string,
    bdValue?: string | number | string[] | number[],
    bdPlaceholder?: string,
    bdOptions?: BdEnumArray,
    bdClass?: string,
    bdIsMulti?: boolean,
    onChangeSelected?: (selected: BdEnum | BdEnum[]) => void;
    onUpdate?: (selectedCode: unknown) => void;
}

interface ITemplateProps {
    value: string,
    label: string,
    name: string,
    tip: string,
    icon: string,
    options: any
}

/**
 * Selector component
*/
const BdSelector = ({
    //specific properties
    bdLabel = "",
    bdPlaceholder = "",
    bdClass = "",
    bdIsMulti = false,
    bdOptions = [] as BdEnumArray,
    onChangeSelected = void 0,
    onUpdate = void 0,
    //form field properties
    name = "",
    bdValue,
    bdValidations,
    bdShowError = true,
    //other properties
    ...otherProperties
}: ISelectorProps) => {
    const [selectedItem, setSelectedItem] = React.useState<BdEnum | BdEnum[]>();

    //BdForm data if exists
    const { register, bdSetValue } = useBdForm(name, bdValue, bdValidations);

    React.useEffect(() => {
        let selectItem: BdEnum | BdEnum[] | undefined;
        if (bdIsMulti) {
            if (bdValue && Array.isArray(bdValue)) {
                const existingValues: string[] = bdValue as string[];
                selectItem = bdOptions.filter(i => existingValues?.some(g => g.toString() === i.code.toString()));
            }
        } else {
            if (bdOptions && bdValue !== undefined && bdValue !== null) {
                selectItem = bdOptions.find(i => i.code.toString() === bdValue.toString());
            }
        }
        setSelectedItem(selectItem);
    }, [bdValue, bdOptions]);

    const _selectedItems: OptionsOrGroups<any, any> = selectMapper(bdOptions);
    const _selectedOption = () => {
        let selectedOptionValue: any;
        if (bdOptions && selectedItem !== undefined) {
            if (bdIsMulti) {
                const selectItems: BdEnum[] | undefined = bdOptions.filter(i => (selectedItem as BdEnum[]).some(g => g.description.toString() === i.description.toString()));
                if (selectItems) {
                    selectedOptionValue = selectMapper(selectItems);
                }
            } else {
                const selectItem: BdEnum | undefined = bdOptions.find(i => i.code.toString() === (selectedItem as BdEnum).code.toString());
                if (selectItem) {
                    selectedOptionValue = selectedOptionMapper(selectItem);
                }
            }
            return selectedOptionValue;
        }
    };

    /** Executes when user selection has changed */
    const _handleSelectionChange = (option: SingleSelectOptionType | SingleSelectOptionType[]) => {
        //send selected enum type back to parent
        if (bdIsMulti) {
            const options = (option as SingleSelectOptionType[]);
            const newSelectItems = bdOptions.filter(i => (options).some(g => g.label.toString() === i.description.toString()));

            if (newSelectItems) {
                const selectedCodes: string[] = newSelectItems.map(item => item.description.toString());
                setSelectedItem(newSelectItems);
                onChangeSelected?.(newSelectItems);
                onUpdate?.(selectedCodes);
                bdSetValue?.(selectedCodes);
            }
        }
        else {
            const newSelectItem = bdOptions.find(i => i.code.toString() === (option as SingleSelectOptionType).value.toString());
            if (newSelectItem) {
                setSelectedItem(newSelectItem);
                const selectedCode: string = newSelectItem.code.toString();
                onChangeSelected?.(newSelectItem);
                onUpdate?.(selectedCode);
                bdSetValue?.(selectedCode);
            }
        }
    };

    /** Style the selector */
    const selectStyles: any = {
        container: (base: ContainerProps) => ({
            ...base,
            width: "max-content",
            minWidth: "100%"
        }),
        control: (styles: ControlProps) => ({
            ...styles,
            borderRadius: "3px",
            backgroundImage: "linear-gradient(to bottom,#fefefe,#f2f2f2)",
            backgroundColor: "#f9f9f9",
            boxShadow: "none",
            borderColor: "#b8b8b8",
            color: "#303030",
            fontSize: "13px",
            lineHeight: "18px",
        }),
        indicatorSeparator: (styles: IndicatorSeparatorProps) => ({
            ...styles,
            display: "none"
        }),
        valueContainer: (styles: ValueContainerProps) => ({
            ...styles,
            color: "#303030",
            fontSize: "13px",
            lineHeight: "18px",
        }),
        input: (styles: InputProps) => ({
            ...styles,
            color: "#303030",
            fontSize: "13px",
            lineHeight: "18px"
        }),
        menu: (styles: MenuProps) => ({
            ...styles,
            zIndex: 9999,
            marginTop: 2,
            borderRadius: 0
        }),
        option: (styles: OptionProps, state: any) => ({
            ...styles,
            fontSize: "13px",
            lineHeight: "18px",
            padding: "5px 8px",
            color: state.isSelected ? "#495c68" : "#303030",
            backgroundColor: state.isSelected ? "#f5fafd" : state.isFocused ? "rgba(0,0,0,.05)" : "transparent",
            ":active": {
                backgroundColor: !state.isDisabled ? state.isSelected ? "#f5fafd" : "rgba(0,0,0,.05)" : undefined
            }
        }),
        groupHeading: (styles: GroupHeadingProps) => ({
            ...styles,
            color: "#303030",
            cursor: "default",
            fontWeight: 700,
            fontSize: ".85em",
            paddingLeft: 8,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        group: (styles: GroupProps) => ({
            ...styles,
            paddingTop: 2,
            paddingBottom: 2,
        })
    };

    /** Option Template */
    const formatOptionTemplate = ({ value, label, name, tip, icon, options }: ITemplateProps) => (
        // can add html and formatting in here
        options !== undefined ?
            // format group by display
            <>
                {options.label}
            </>
            :
            // format single display
            <>
                {label}
            </>
    );

    /** Main content */
    return (
        <FormGroup id={name}>
            {bdLabel && <Form.Label>{bdLabel}</Form.Label>}
            <Select
                {...register?.(name)}
                value={_selectedOption()}
                isMulti={bdIsMulti}
                formatOptionLabel={formatOptionTemplate}
                options={_selectedItems}
                styles={selectStyles}
                placeholder={bdPlaceholder}
                onChange={_handleSelectionChange}
                {...otherProperties} />
        </FormGroup>
    );
};

export default BdSelector;
