import { BdButton, BdButtonType, BdDropDown, BdMenuItem, BdTable, BdTag, useBdModal, useBdToast, useOrderSelector } from "bundledocs.common.client/modules/bd-components";
import { MenuItemType } from "bundledocs.common.client/modules/bd-constants";
import { BdIconOptions, BdIconStripeProfile, BdIconStripeTrial, BdIconUser } from "bundledocs.common.client/modules/bd-graphics";
import { EnumHelper } from "bundledocs.common.client/modules/bd-helpers";
import { BdToastFailureMessage, BdToastFailureResponse, SubscriptionCapacity, SubscriptionStatus, SubscriptionType, UserRole } from "bundledocs.common.client/modules/bd-models";
import { BdTableColumn } from "bundledocs.common.client/src/components/bd-data/bd-table";
import { BdAdminIcon, BdIconDisableAuthorization, BdIconEditNote, BdIconReceipt, BdIconStripePlan, BdIconUnlock, BdIconUserFeatureEdit } from "bundledocs.common.client/modules/bd-graphics";
import { BdSubscriptionCapacityModal } from "bundledocs.common.client/src/components/bd-modal/bd-capacity-modal";
import { BdOrderSelector } from "bundledocs.common.client/src/components/bd-order-selector/bd-order-selector";
import React, { useState } from "react";
import { UserPortalBean } from "../../../models/providers/bean/user-portal-bean/user-portal-bean";
import { UserSubscriptionOptions } from "../../../models/providers/user-context/user-subscription-options";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";
import { BdUserAdminModal } from "../bd-user-admin/bd-user-admin";
import { BdUserEditFeatureStatusModal } from "../bd-user-edit-feature-status/bd-user-edit-feature-status";
import { BdUserEditRoleModal } from "../bd-user-edit-role/bd-user-edit-role";
import { BdUserEditSubscriptionModal } from "../bd-user-edit-subscription/bd-user-edit-subscription";
import { BdUserSubscriptionStripeTrialModal } from "../bd-user-edit-subscription/bd-user-subscription-stripe-trial-modal";
import { BdUserStripePlanModal } from "../bd-users-stripe-plan/bd-users-stripe-plan";
import bdStyle from "./bd-users-search.module.css";

export interface BdUsersSearchListProps extends React.HTMLAttributes<HTMLElement> {
    bdUsers: UserPortalBean[];
}

export interface UserRow extends UserPortalBean {
    RoleName: string;
    SubscriptionTypeName: string;
    SubscriptionStatusName: string;
}

/**
 * Components that show a list of users that receives in  the props
 * @param param0
 */
export default function BdUsersSearchList({
    bdUsers,
    ...otherProps
}: BdUsersSearchListProps): JSX.Element {
    //selected users
    const [selectedUser, setSelectedUser] = useState<UserPortalBean>({} as UserPortalBean);

    const [selectedUpdateUsers, setSelectedUpdateUsers] = useState<UserPortalBean[]>([] as UserPortalBean[]);

    //Subscription Capacity
    const [subscriptionCapacity, setSubscriptionCapacity] = useState<SubscriptionCapacity>({} as SubscriptionCapacity);

    //manages whether controls are enabled or not
    const [controlsEnabled, setControlsEnabled] = useState(false);

    //modal managers
    const UserRoleModal = useBdModal();
    const UserFeatureStatusModal = useBdModal();
    const StripeSubscriptionModal = useBdModal();
    const SubscriptionCapacityModal = useBdModal();
    const SubscriptionEditModal = useBdModal();
    const UserStripePlanModal = useBdModal();
    const UserAdminDetailsModal = useBdModal();

    //user selector
    const usersSelector = useOrderSelector();

    //toast functions
    const { toastSuccess, toastError } = useBdToast();

    //services
    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    //always check if user changes for any reason, remove user from selection when user no longer in list
    React.useEffect(() => {
        setControlsEnabled(bdUsers ? true : false);

        //remove filtered items from selected list
        if (usersSelector.selectedItems.length > 0) {
            const allUsersRowKey: string[] = bdUsers?.map(d => d.RowKey);
            const usersRowKeyToUnselect: string[] = usersSelector.selectedItems.filter(id => allUsersRowKey.indexOf(id) < 0);
            for (const docRowKey of usersRowKeyToUnselect) {
                usersSelector.removeItem(docRowKey);
            }
        }

        usersSelector.setAllItems(bdUsers.map(d => d.RowKey));
    }, [bdUsers, usersSelector]);


    //table definition
    const _columns: BdTableColumn[] = [
        { header: "Created", field: "Created", typeof: Date },
        { header: "Email", field: "Email", typeof: String },
        { header: "Last Active", field: "LastActive", typeof: Date },
        { header: "Role", field: "RoleName", typeof: String },
        { header: "Sub Created", field: "SubscriptionCreated", typeof: Date },
        { header: "Status", field: "SubscriptionStatusName", typeof: String },
        { header: "Subscription Type", field: "SubscriptionTypeName", typeof: String },
        { header: "Subscription Plan", field: "StripeSubscriberPlanDescription", typeof: String },
        { header: "Stripe Key", field: "StripeSubscriberKey", typeof: String },
        { header: "User Limit App", field: "SubscriptionUserLimit", typeof: String },
        { header: "User Limit Editor", field: "SubscriptionUserLimitAdjust", typeof: String },
        { header: "User Limit Edit", field: "SubscriptionUserLimitEdit", typeof: String },
        { header: "Bundle Limit", field: "SubscriptionCaseLimit", typeof: String },
        { header: "Live Bundles", field: "LiveBundles", typeof: Number },
        { header: "Document Limit", field: "SubscriptionDocumentLimit", typeof: String },
        { header: "Live Documents", field: "LiveDocuments", typeof: Number },
        { header: "Account Number", field: "AccountNumber", typeof: Number },
        { header: "Renewal Date", field: "RenewalDate", typeof: Date },
        { header: "Currency", field: "SubscriptionCurrencyCode", typeof: Number },
        { header: "Fee", field: "RenewalFee", typeof: Number },
        { header: "Domain", field: "Domain", typeof: Number },
        { header: "User Agent", field: "BrowserUserAgent", typeof: Number },
        { header: "Group", field: "EmailGroup", typeof: String },
    ];

    //assign a name depends on the user role. Candidate to implement in a view model
    const _getUserRoleName = (role: UserRole): string => {
        switch (role) {
            case UserRole.ApplicationUser:
                return "Application";
            case UserRole.ApplicationDeveloper:
                return "Developer";
            case UserRole.FederatedUser:
                return "Federated";
            default:
                return role;
        }
    };

    //open edit role modal
    const _handleEditRole = (user: UserPortalBean) => {
        setSelectedUser(user);
        UserRoleModal.Show();
    };

    //open edit feature status modal
    const _handleEditFeatureStatus = (user: UserPortalBean) => {
        const updateUsers: UserPortalBean[] = Array(user);
        setSelectedUpdateUsers(updateUsers);

        UserFeatureStatusModal.Show();
    };

    //open edit feature status modal for multiple selection
    const _handleEditFeatureStatusSelection = () => {
        setSelectedUpdateUsers(_getUsersFromOrderList);

        UserFeatureStatusModal.Show();
    };

    //open edit subscription modal
    const _handleEditSubscription = (user: UserPortalBean) => {
        const updateUsers: UserPortalBean[] = Array(user);
        setSelectedUpdateUsers(updateUsers);

        SubscriptionEditModal.Show();
    };

    //open edit subscription modal for multiple selection
    const _handleEditSubscriptionSelection = () => {
        setSelectedUpdateUsers(_getUsersFromOrderList);

        SubscriptionEditModal.Show();
    };

    //open stripe trial modal
    const _handleEditSubscriptionToStripeTrial = (user: UserPortalBean) => {
        setSelectedUser(user);
        StripeSubscriptionModal.Show();
    };

    //open admin modal
    const _handleOpenAdmin = (user: UserPortalBean) => {
        setSelectedUser(user);
        UserAdminDetailsModal.Show();
    };

    //open subscription capacity modal
    const _handleSubscriptionCapacity = async (user: UserPortalBean) => {
        const response = await _bundledocsApiInstance.Users.GetUserSubscriptionCapacity(user.RowKey);

        try {
            if (response) {
                setSubscriptionCapacity({
                    ...subscriptionCapacity,
                    SubscriptionTotalUsersAcrossOrganization: response.SubscriptionTotalUsersAcrossOrganization,
                    SubscriptionCaseLimit: response.SubscriptionCaseLimit,
                    SubscriptionTotalOfBundlesAcrossOrganization: response.SubscriptionTotalOfBundlesAcrossOrganization,
                    SubscriptionUserLimit: response.SubscriptionUserLimit,
                    SubscriptionBundledocsUsersLiveCount: response.SubscriptionBundledocsUsersLiveCount,
                    SubscriptionEditorUsersLimit: response.SubscriptionEditorUsersLimit,
                    SubscriptionEditorUsersLiveCount: response.SubscriptionEditorUsersLiveCount,
                    SubscriptionTextEditingUsersLimit: response.SubscriptionTextEditingUsersLimit,
                    SubscriptionTextEditingUsersLiveCount: response.SubscriptionTextEditingUsersLiveCount,
                    SubscriptionProducts: response.SubscriptionProducts,
                    SubscriptionTrialEnd: response.SubscriptionTrialEnd,
                });
            }
        } catch (error: any) {
            toastError(error);
            SubscriptionCapacityModal.Close();
        }
        SubscriptionCapacityModal.Show();
    };

    //navigate to user stripe account
    const _handleGetSubscriptionAccountUrl = async (user: UserPortalBean) => {
        try {
            window.open(await _bundledocsApiInstance.Users.GetStripeAccountUrl(user), "_blank");
        }
        catch (error) {
            toastError(error as BdToastFailureResponse);
        }
    };

    //open stripe plan modal
    const _handleGetStripePlan = (user: UserPortalBean) => {
        setSelectedUser(user);

        if (user.StripeSubscriberPlanKey) {
            UserStripePlanModal.Show();
        }
        else {
            toastError("No stripe subscription plan found for this user");
        }
    };

    //edit user updates
    const _onUserUpdated = (user: UserPortalBean) => {
        //update user role on main search list
        const userIndex: number = bdUsers.findIndex(i => i.RowKey === user.RowKey);
        if (userIndex >= 0) {
            bdUsers[userIndex] = user;
        }

        //reset modal data
        setSelectedUser({} as UserPortalBean);
    };

    //edit multiple user updates
    const _onMultipleUsersUpdated = (users: UserPortalBean[]) => {
        //update users on main search list
        for (const user of users) {
            const userIndex: number = bdUsers.findIndex(i => i.RowKey === user.RowKey);
            if (userIndex >= 0) {
                bdUsers[userIndex] = user;
            }
        }

        //reset selection and modal data
        usersSelector.clearSelection();
        setSelectedUpdateUsers([] as UserPortalBean[]);
    };

    //unlock account for user
    const _onUnlockAccount = async (user: UserPortalBean) => {
        try {
            //unlock settings
            const subscriptionOptions: UserSubscriptionOptions = {
                CurrencyCode: "", //keep subscription currency
                AccountNumber: user.AccountNumber,
                CountryCode: user.CountryCode,
                Company: user.Company
            } as UserSubscriptionOptions;

            //update user settings
            const updatedData: UserSubscriptionOptions = await _bundledocsApiInstance.Users.UpdateUserSubscriptionToManagedTrial(user.RowKey, subscriptionOptions) as UserSubscriptionOptions;

            if (updatedData) {
                //update user on main search list
                const updatedUserRecord: UserPortalBean | undefined = bdUsers.find(i => i.RowKey === user.RowKey);
                if (updatedUserRecord) {
                    updatedUserRecord.SubscriptionType = updatedData.SubscriptionType;
                    updatedUserRecord.SubscriptionStatus = updatedData.SubscriptionStatus;
                    updatedUserRecord.SubscriptionUserLimit = updatedData.UserLimit;
                    updatedUserRecord.SubscriptionCaseLimit = updatedData.CaseLimit;
                    updatedUserRecord.SubscriptionDocumentLimit = updatedData.DocumentLimit;
                }
            }

            //show confirmation
            toastSuccess(`${user.Email} unlocked successfully!`);
        }
        catch (error: BdToastFailureMessage | any) {
            if (typeof error === "object") {
                toastError(error as BdToastFailureMessage);
            }
            else {
                toastError(`${user.Email} not unlocked successfully!`);
            }
        }
    };

    //unlock account for user
    const _onRemoveTOTP = async (user: UserPortalBean) => {
        try {
            //update user Authorization settings
            await _bundledocsApiInstance.Users.RemoveUserTOTP(user.RowKey as string);

            //show confirmation
            toastSuccess(`${user.Email} 2FA disabled successfully!`);
        }
        catch (error: BdToastFailureMessage | any) {
            if (typeof error === "object") {
                toastError(error as BdToastFailureMessage);
            }
            else {
                toastError(`${user.Email} 2FA could not be disabled!`);
            }
        }
    };

    //get user items from order selector list
    const _getUsersFromOrderList = (): UserPortalBean[] => bdUsers.filter(d => usersSelector.selectedItems.indexOf(d.RowKey) > -1)
        .sort((a, b) => usersSelector.selectedItems.indexOf(a.RowKey) - usersSelector.selectedItems.indexOf(b.RowKey));

    //options menu
    const _getUserActions = (user: UserPortalBean) => {
        return (
            <div className={bdStyle.bdActionWrapper}>
                <BdOrderSelector {...usersSelector?.register(user.RowKey)} />
                <BdDropDown bdTitle={<BdIconOptions size={18} style={{ marginBottom: "0.2rem" }} />} bdShowCaret={false} bdId={"userAction_" + user.RowKey}>
                    <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => _handleEditRole(user)}
                        bdIcon={<BdIconUser />} bdText="Edit Role" />
                    <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => _handleEditFeatureStatus(user)}
                        bdIcon={<BdIconUserFeatureEdit />} bdText="Edit Feature Status" />
                    <BdMenuItem bdType={MenuItemType.Divider} />
                    <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => { _onRemoveTOTP(user); }}
                        bdIcon={<BdIconDisableAuthorization />} bdText="Disable 2FA" bdConfirmMessage="Are you absolutely sure you want to disable 2FA?" />
                    <BdMenuItem bdType={MenuItemType.Divider} />
                    <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => { _onUnlockAccount(user); }}
                        bdIcon={<BdIconUnlock />} bdText="Create Trial (Managed)" bdConfirmMessage="Are you absolutely sure you want to create a managed trial?" />
                    <BdMenuItem bdType={MenuItemType.Divider} />
                    <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => _handleEditSubscriptionToStripeTrial(user)}
                        bdIcon={<BdIconStripeTrial size={18} />} bdText="Create Trial (Stripe)" />
                    <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => _handleGetSubscriptionAccountUrl(user)}
                        bdIcon={<BdIconStripeProfile />} bdText="View Account (Stripe)" />
                    <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => _handleGetStripePlan(user)}
                        bdIcon={<BdIconStripePlan />} bdText="Stripe Plan" />
                    <BdMenuItem bdType={MenuItemType.Divider} />
                    <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => _handleSubscriptionCapacity(user)}
                        bdIcon={<BdIconReceipt size={18} />} bdText="Subscription Capacity" />
                    <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => { _handleEditSubscription(user); }}
                        bdIcon={<BdIconEditNote size={18} />} bdText="Subscription Edit" />
                    <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => { _handleOpenAdmin(user); }}
                        bdIcon={<BdAdminIcon size={18} />} bdText="View Owners/Admins" />
                </BdDropDown>
            </div>
        );
    };

    //process a list of user and returns a list of extended objects that contains all fields needed by the table
    const bdUsersViewModels: UserRow[] = bdUsers.map((user: UserPortalBean) => {
        return {
            ...user,
            RoleName: _getUserRoleName(user.UserRole),
            SubscriptionStatusName: EnumHelper.getFriendlyName(SubscriptionStatus, user.SubscriptionStatus),
            SubscriptionTypeName: EnumHelper.getFriendlyName(SubscriptionType, user.SubscriptionType)
        };
    });

    return (
        <>
            {usersSelector.selectedItems.length > 0 && (
                <div className={bdStyle.bdSearchHeaderArea}>
                    <BdTag bdText={
                        usersSelector.selectedItems.length +
                        (usersSelector.selectedItems.length === 1 ? " user selected" : " users selected")
                    }
                    bdTooltipDiscard='Clear selection' bdTooltipPlacement='top' onDiscard={usersSelector.clearSelection}
                    bdDisabled={!controlsEnabled} />
                    {usersSelector.selectedItems.length > 1 && (
                        <div className={bdStyle.bdSearchSharedActions}>
                            <>
                                <BdButton className={bdStyle.bdSearchSharedActionButton} bdIcon={<BdIconUserFeatureEdit />} bdTooltipBody={"Edit Feature Status for selected users"} bdTooltipPlacement='left'
                                    onClick={_handleEditFeatureStatusSelection}
                                    disabled={!controlsEnabled}
                                    bdType={BdButtonType.SimpleIcon}
                                />
                                <BdButton className={bdStyle.bdSearchSharedActionButton} bdIcon={<BdIconEditNote />} bdTooltipBody={"Subscription Edit for selected users"} bdTooltipPlacement='left'
                                    onClick={_handleEditSubscriptionSelection}
                                    disabled={!controlsEnabled}
                                    bdType={BdButtonType.SimpleIcon}
                                />
                            </>
                        </div>
                    )}
                </div>
            )}

            <div className="table-responsive-container">
                <BdTable {...otherProps} bdResponsive bdColumns={_columns} bdItems={bdUsersViewModels} onActions={_getUserActions} bdWrap={false} bdShowActionsOnLeft={true} />
            </div>
            <BdUserEditRoleModal {...UserRoleModal.BdModalProps} bdUser={selectedUser} onUpdate={_onUserUpdated} />
            <BdUserStripePlanModal {...UserStripePlanModal.BdModalProps} bdUser={selectedUser} />
            <BdUserEditFeatureStatusModal {...UserFeatureStatusModal.BdModalProps} bdUpdateUsers={selectedUpdateUsers} onUpdate={_onMultipleUsersUpdated} />
            <BdUserSubscriptionStripeTrialModal {...StripeSubscriptionModal.BdModalProps} bdUser={selectedUser} onUpdate={_onUserUpdated} />
            <BdSubscriptionCapacityModal {...SubscriptionCapacityModal.BdModalProps} bdUserLimit={subscriptionCapacity} />
            <BdUserEditSubscriptionModal {...SubscriptionEditModal.BdModalProps} bdUpdateUsers={selectedUpdateUsers} onUpdate={_onMultipleUsersUpdated}></BdUserEditSubscriptionModal>
            <BdUserAdminModal {...UserAdminDetailsModal.BdModalProps} bdUser={selectedUser}></BdUserAdminModal>
        </>
    );
}
