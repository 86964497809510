import { BriefAnnotationExclusion } from "../../../../../modules/bd-models";
import { BaseModel } from "../BaseModel";
import { UserGroup } from "../group-context/UserGroup";
import { BriefCollaborator } from "./BriefCollaborator";
import { BriefReceipt } from "./BriefReceipt";
import { BriefVisibleTo } from "./BriefVisibleTo";

export enum BriefAccessType {
    CASE = 0,
    TEMPLATE = 1,
    ADJUST = 2
}

export interface Brief extends BaseModel {
    Code?: string;
    Title?: string;
    Description?: string;

    LastAccessedTimeStamp?: string;
    IsVisibleToGroups?: boolean;
    IsReadOnlyToGroups?: boolean;
    UserAccessPolicy?: number;

    AccessType?: number;

    BlobStorageAccountName?: string;
    IsBlobStorageAccountNameAppliedToTree?: boolean;
    IsUsingMaxDpiOnUpload?: boolean;
    MaxDpiOnUpload?: number;

    BriefDocumentDisplayDateFormat?: string;
    IsBriefDocumentDisplayDateVisibleInTree?: boolean;
    IsBriefDocumentDisplayDateFormatAppliedToTree?: boolean;
    IsBriefDocumentCustomColumnTextVisibleInTree?: boolean;
    IsBriefDocumentXRefVisibleInTree?: boolean;
    IsBatesStampVisibleInTree?: boolean;
    IsUsingSourceOrientationOnUpload?: boolean;
    IsUsingRemovePreviousCorrespondenceOnUpload?: boolean;
    IsUsingSourceAnnotationsOnUpload?: boolean;
    IsUsingOcrOnUpload?: boolean;
    IsUsingLockedOnUpload?: boolean;
    IsUsingEmailMetadataOnUpload?: boolean;
    
    /*Annotations*/
    BriefAnnotationExclusionPolicy?: number;
    BriefAnnotationExclusions?: Array<BriefAnnotationExclusion>;

    IsRedactionsExcludedGlobal?: boolean;
    IsHighlightsExcludedGlobal?: boolean;
    IsNotesExcludedGlobal?: boolean;
    IsLabelsExcludedGlobal?: boolean;

    IsRedactionsIndicative?: boolean;
    IsGenerateWithPrivateAnnotations?: boolean;

    GenerateStatus?: number;
    IsLocked?: boolean;

    Metadata?: any;

    BriefReceipts?: Array<BriefReceipt>;
    PlaceholderFileTypes?: Array<number>;
    BriefCollaborators?: Array<BriefCollaborator>;
    BriefVisibleTos?: Array<BriefVisibleTo>;
    VisibleToGroups?: Array<UserGroup>;

    TemplatePartitionKey?: string;
    TemplateRowKey?: string;

    IsBriefOpen?: boolean;
    IsOcrAppliedToTree?: boolean;
    IsSectionBorderColorAppliedToTree?: boolean;
    SectionBorderColor?: string;
    ZoomLevel?: number;
    PageSize?: number;
}

export class NewBrief implements Brief {
    PartitionKey = "";
    RowKey = "";

    public static New(): Brief {
        return new NewBrief();
    }
}
