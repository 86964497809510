import { BdFormValidationPolicy, BdModal, BdTextBox, BdTextBoxType, useBdToast } from "bundledocs.common.client/modules/bd-components";
import { BdToastFailureMessage } from "bundledocs.common.client/modules/bd-models";
import { useState } from "react";
import { UserPortalBean } from "../../../models/providers/bean/user-portal-bean/user-portal-bean";
import { UserSubscriptionToStripeTrialOptions } from "../../../models/providers/user-context/user-subscription-to-stripe-trial-options";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";
import { BdSelectCurrency } from "./bd-select-currency";

interface BdUserSubscriptionStripeTrialModalProps {
    bdUser: UserPortalBean;
    bdShow: boolean;
    onCancel?: () => void;
    onUpdate?: (user: UserPortalBean) => void;
}

/** Modal to manage edit user role */
export const BdUserSubscriptionStripeTrialModal = ({
    bdUser,
    onCancel,
    onUpdate,
    ...modalProps
}: BdUserSubscriptionStripeTrialModalProps) => {
    const [updating, setUpdating] = useState<boolean>(false);

    //toast functions
    const { toastSuccess, toastError } = useBdToast();

    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    /** update user role data */
    const _handleSubmit = async (data: UserSubscriptionToStripeTrialOptions): Promise<boolean> => {
        try {
            setUpdating(true);
            const updatedUser: UserPortalBean = await _bundledocsApiInstance.Users.UpdateUserSubscriptionToStripeTrial(bdUser.RowKey, data);

            toastSuccess("User's subscription updated successfully");
            onUpdate?.(updatedUser);
            onCancel?.();

            return true;
        }
        catch (error: any) {
            toastError(error as BdToastFailureMessage);
            return false;
        }
        finally {
            setUpdating(false);
        }
    };

    return (
        <BdModal bdTitle={`Stripe trial - ${bdUser.Email}`} bdDisabled={updating} onCancel={onCancel} {...modalProps} onConfirm={_handleSubmit} >
            <BdSelectCurrency
                name="CurrencyCode"
                bdLabel={"Currency"}
                bdPlaceholder="choose a currency..."
                bdClass="margin-b-xs"
                bdValue={bdUser?.SubscriptionCurrencyCode}
            />
            <BdTextBox
                bdValidations={[{ policy: BdFormValidationPolicy.NumberPositive }]}
                name="Duration"
                bdLabel={"Duration"}
                bdTextBoxType={BdTextBoxType.Number}
                bdMin={1}
                bdMax={30}
                bdValue={30}
                style={{marginTop: "1rem"}}
            />
        </BdModal>
    );
};
