import { BdTable } from "bundledocs.common.client/modules/bd-components";
import { BdTableColumn } from "bundledocs.common.client/src/components/bd-data/bd-table";
import React from "react";
import { BdSubscriptionAnalysisBean } from "../../models/providers/bean/bd-subscription-analysis-bean/bd-subscription-analysis-bean";

export interface BdSubscriptionAnalysisTableProps extends React.HTMLAttributes<HTMLElement> {
    bdSubscriptions: BdSubscriptionAnalysisBean[];
}

/**
 * Components that show a list of subscriptions that receives in  the props
 * @param param0
 */
export default function BdSubscriptionAnalysisTable({
    bdSubscriptions,
    ...otherProps
}: BdSubscriptionAnalysisTableProps): JSX.Element {     

    const _columns: BdTableColumn[] = [
        { header: "Date", field: "InvoiceDate", typeof: Date },
        { header: "Activity", field: "Activity", typeof: String },
        { header: "Email", field: "Email", typeof: String },
        { header: "Currency", field: "Currency", typeof: String },
        { header: "Plan Name Current", field: "PlanNameCurrent", typeof: String },
        { header: "Plan Fee Current", field: "PlanFeeCurrent", typeof: String },
        { header: "Plan Name Previous", field: "PlanNamePrevious", typeof: String },
        { header: "Plan Fee Previous", field: "PlanFeePrevious", typeof: String },
        { header: "Plan Fee Difference", field: "PlanFeeDifference", typeof: String },
        { header: "Status", field: "Status", typeof: String }, 
    ];

    return (
        <>
            <BdTable {...otherProps} bdColumns={_columns} bdItems={bdSubscriptions} />           
        </>
    );
}
