import cx from "classnames";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import BdButton from "../bd-buttons/bd-button";
import { BdButtonType } from "../bd-buttons/bd-buttons-constants";
import BdForm from "../bd-form/bd-form";
import { BdIconSpinner } from "../bd-graphics/bd-icons";
import bdStyle from "./bd-modal.module.css";

/** BDMODAL HOOK */

/**
 * const BdExampleModalController = useBdModal();
 *
 * BdExampleModalController.Show();
 * BdExampleModalController.Close();
 * 
 * <BdExampleModal {...BdExampleModalController.BdModalProps} ... />
 * */

interface useBdModalResult {
    BdModalProps: useBdModalPropsResult;
    Show: () => void;
    Close: () => void;
}

interface useBdModalPropsResult {
    bdShow: boolean;
    onCancel: () => void;
}

export function useBdModal(): useBdModalResult {
    const [show, setShow] = useState<boolean>(false);

    const _show = () => {
        setShow(true);
    };

    const _close = () => {
        setShow(false);
    };

    const _onCancel = () => {
        _close();
    };

    return {
        BdModalProps: {
            bdShow: show,
            onCancel: _onCancel
        },
        Show: _show,
        Close: _close
    };
}

/** BDMODAL */

interface IBdModalProps {
    bdShowCancel?: boolean;
    bdShow?: boolean;
    bdTitle?: string;
    bdDisabled?: boolean;
    bdConfirmText?: string;
    bdCancelText?: string;
    bdSaving?: boolean;
    bdConfirmMessage?: string;
    bdConfirmTitle?: string;
    onConfirm?: (data: any) => Promise<boolean>;
    onCancel?: Function;
    children?: JSX.Element | JSX.Element[] | undefined | "";
    isModalForm?: boolean;
}

/** Modal container */
export const BdModal = ({
    bdShowCancel = true,
    bdShow = false,
    bdTitle = "",
    bdDisabled = false,
    bdConfirmText = "OK",
    bdCancelText = "Cancel",
    bdSaving = false,
    bdConfirmMessage = "",
    bdConfirmTitle = "",
    onConfirm = void 0,
    onCancel = void 0,
    children,
    isModalForm = true
}: IBdModalProps) => {
    const _handleConfirm = async (data: any): Promise<boolean> => {
        return await onConfirm?.(data) ?? true;
    };

    /** Executes the cancel action if defined */
    const _handleCancel = () => {
        onCancel?.();
    };

    let _modalContent = (<>
        <Modal.Header closeButton>
            <Modal.Title className="h3">{bdTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {children}
        </Modal.Body>

        <Modal.Footer>
            <Row>
                <Col>
                    {bdShowCancel && <BdButton bdType={BdButtonType.Warning} onClick={_handleCancel} bdText={bdCancelText} />}
                </Col>
                <Col>
                    <BdButton type={isModalForm ? "submit" : undefined} disabled={bdDisabled} bdType={BdButtonType.Primary} bdText={bdConfirmText} bdIcon={bdSaving && bdSaving === true ? <BdIconSpinner size={15} /> : undefined}
                        bdConfirmMessage={bdConfirmMessage} bdConfirmTitle={bdConfirmTitle} onClick={!isModalForm ? _handleConfirm : undefined}
                    />
                </Col>
            </Row>
        </Modal.Footer>
    </>);

    if (isModalForm) {
        _modalContent = (
            <BdForm onSubmit={_handleConfirm}>
                {_modalContent}
            </BdForm>
        );
    }

    return (
        <Modal className={cx("modalConfirm", bdStyle.modalPosition)} show={bdShow} onHide={_handleCancel}>
            {_modalContent}
        </Modal>
    );
};
