import { useEffect, useState } from "react";
import { MAX_SUBSCRIPTION_CAPACITY_LIMIT } from "../../constants/bd-common-constants";
import { BdIconInfinity, BdIconInfinityOff } from "../bd-graphics/bd-icons";
import { useBdForm } from "./bd-form";
import BdTextBox, { BdTextBoxType, ITextBoxProps } from "./bd-textbox";

type INumberLimiterProps = ITextBoxProps;

export const BdNumberLimiter = ({...otherProps }: INumberLimiterProps) => {
    const [isToggled, setIsToggled] = useState<boolean>();
    const { bdSetValue } = useBdForm(otherProps.name ?? "", otherProps.bdValue);

    useEffect(() => {
        if (Number(otherProps.bdValue) < MAX_SUBSCRIPTION_CAPACITY_LIMIT) {
            setIsToggled(false);
            bdSetValue?.(Number(otherProps.bdValue) ?? 0);
        } else {
            setIsToggled(true);
            bdSetValue?.(MAX_SUBSCRIPTION_CAPACITY_LIMIT);
        }
    }, [otherProps.bdValue]);

    const _handleClick = () => {
        if (!isToggled) {
            bdSetValue?.(MAX_SUBSCRIPTION_CAPACITY_LIMIT);
        }
        setIsToggled(!isToggled);
    };

    return (
        <>
            <BdTextBox
                {...otherProps}
                style={{ ...otherProps.style, display: !isToggled ? undefined : "none" }}
                bdTextBoxType={BdTextBoxType.Number}
                bdButtonIcon={<BdIconInfinity />}
                bdReadOnly={false}
                onRightActionButtonClick={_handleClick}
            />
            <BdTextBox
                bdLabel={otherProps.bdLabel}
                style={{ ...otherProps.style, display: isToggled ? undefined : "none" }}
                bdTextBoxType={BdTextBoxType.Text}
                bdButtonIcon={<BdIconInfinityOff />}
                bdValue="Unlimited"
                bdReadOnly={true}
                onRightActionButtonClick={_handleClick}
            />
        </>
    );
};

export default BdNumberLimiter;
