import { Activity, BriefDocument } from "../../../../modules/bd-models";
import { BdApiResponse } from "../../bd-service-base";

export enum BdDownloadType {
    PDF = 0,
    SOURCE = 1,
    SOURCE_ALT = 2
}

export class SystemTag {
    constructor(
        public Role: number,
        public Name: string
    ) { }
}

export class PatchTagsOptions {
    constructor(
        public Tags: SystemTag[]
    ) { }
}

export class DeleteTagsOptions {
    constructor(
        public Tags: SystemTag[]
    ) { }
}

export class BriefDocumentActionUnlockOptions {
    constructor(
        public Password: string,
        public Batch: string
    ) { }
}

export enum StampType {
    None = 0,
    Static = 100,
    Template = 200,
    Signature = 300
}

/**
 * Bundledocs API wrapper for Documents
 * */
export default interface IBdServiceDocuments {
    /**
     * Returns document's activities
     * @param document
     */
    GetActivities: (document: BriefDocument) => Promise<BdApiResponse<Activity[]>>;

    /**
     * Applies supplied password to document
     * @param documents
     * @param password
     */
    ApplyUnlock: (document: BriefDocument, options: BriefDocumentActionUnlockOptions) => Promise<BdApiResponse<BriefDocument>>;

    /**
     * @param document
     * @param downloadType
     * @returns
     */
    Download: (document: BriefDocument, downloadType: BdDownloadType) => Promise<void>;

    /**
     * @param document
     * @param patchTagsOptions
     * @returns
     */
    PatchTags: (document: BriefDocument, patchTagsOptions: PatchTagsOptions) => Promise<BdApiResponse<BriefDocument>>;

    /**
     * @param document
     * @param deleteTagsOptions
     * @returns
     */
    DeleteTags: (document: BriefDocument, deleteTagsOptions: DeleteTagsOptions) => Promise<BdApiResponse<BriefDocument>>;
}
