import {
    BdModal, useBdToast
} from "bundledocs.common.client/modules/bd-components";
import { BdEnums, BdToastFailureMessage } from "bundledocs.common.client/modules/bd-models";
import { useEffect, useState } from "react";
import {
    BdNotificationBean
} from "../../../models/providers/bean/bd-notification-bean/bd-notification-bean";
import {
    NotificationOptions
} from "../../../models/providers/notification-context/notification-options";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";
import { BdGlobalNotification } from "./bd-global-notification";

interface IBdPreviewNotificationModalProps {
    bdDraftNotification: BdNotificationBean;
    onPublish?: (license: BdNotificationBean) => void;
    bdTitle?: string;
    onCancelPublish?: Function;
    bdShow: boolean;
}

/** Modal for previewing the notification */
export const BdPreviewNotificationModal = ({
    bdDraftNotification,
    onPublish,
    bdTitle = "Preview Notification",
    onCancelPublish,
    bdShow
}: IBdPreviewNotificationModalProps) => {
    const [updating, setUpdating] = useState(false);
    const _bdEnums = new BdEnums();

    //toast functions
    const { toastSuccess, toastError } = useBdToast();

    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    /** set updating=false on close */
    useEffect(() => {
        if (!bdShow) {
            setUpdating(false);
        }
    }, [bdShow]);

    function convertToNotificationOptions(notification: BdNotificationBean): NotificationOptions {

        const category = (_bdEnums.GlobalNotificationCategory.find(nc => nc.code == notification?.Category));

        return {
            Title: notification.Title,
            Body: notification.Body,
            ActionUrl: notification.ActionUrl,
            ActionText: notification.ActionText,
            CategoryCode: category?.code as number,
            TimeToLive: notification.TimeToLive
        };
    }

    /** create or publish global notification */
    const _handleNotificationPublish = async (): Promise<boolean> => {
        let handleResult = true;

        try {
            setUpdating(true);
            const notificationOptions: NotificationOptions = convertToNotificationOptions(bdDraftNotification);

            //create notification
            const notificationResult = await _bundledocsApiInstance.Notifications.CreateNotification(notificationOptions);

            if (notificationResult?.PartitionKey?.length > 0) {
                toastSuccess("Global Notification was created successfully");
                onPublish?.(notificationResult);
            }

            handleResult = true;
        }
        catch (error: any) {
            toastError(error as BdToastFailureMessage);
            onPublish?.(bdDraftNotification);
            handleResult = false;
        }
        finally {
            setUpdating(false);
        }

        return handleResult;
    };

    /** cancel global notification */
    const _handleCancel = async (): Promise<boolean> => {

        let handleResult = true;

        try {
            setUpdating(true);

            //cancel publishing notification
            onCancelPublish?.(bdDraftNotification);

            handleResult = true;
        }
        catch (error: any) {
            toastError(error as BdToastFailureMessage);
            handleResult = false;
        }
        finally {
            setUpdating(false);
        }

        return handleResult;
    };

    return (
        <BdModal isModalForm={false} bdTitle={bdTitle} bdDisabled={updating} onConfirm={_handleNotificationPublish} onCancel={_handleCancel} bdShow={bdShow} bdConfirmText="Publish" bdCancelText="Edit" bdConfirmMessage="Are you sure you want to publish?" bdConfirmTitle="Confirm Publish">
            <BdGlobalNotification notification={bdDraftNotification}></BdGlobalNotification>
        </BdModal>
    );
};
