import { BdDropDown, BdMenuItem, BdTable, useBdModal, useBdToast } from "bundledocs.common.client/modules/bd-components";
import { BdTableColumn } from "bundledocs.common.client/src/components/bd-data/bd-table";
import React, { useState } from "react";
import { BdIconEditNote, BdIconOptions, BdIconStripeProfile, BdToastFailureMessage } from "bundledocs.common.client/src";
import { MenuItemType } from "bundledocs.common.client/src/constants/bd-enums";
import { BdIconDisableAuthorization, BdIconReceipt } from "bundledocs.common.client/modules/bd-graphics";
import { BdAccountNumberBean } from "../../../models/providers/bean/bd-account-number-bean/bd-enterprise-license-bean";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdAccountNumberCreateModal } from "../bd-account-numbers-actions/bd-account-numbers-create";

export interface BdAccountNumbersSearchListProps extends React.HTMLAttributes<HTMLElement> {
    accountNumbers: BdAccountNumberBean[];
    onAccountNumberUpdates: (accounts: BdAccountNumberBean[]) => void;
}

/**
 * Components that show a list of accounts that receives in  the props
 * @param param0
 */
export default function BdAccountNumberSearchList({
    accountNumbers,
    onAccountNumberUpdates,
    ...otherProps
}: BdAccountNumbersSearchListProps): JSX.Element {
    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    const { toastSuccess, toastError } = useBdToast();
    const [accounts, setAccounts] = useState<BdAccountNumberBean[]>(accountNumbers);
    const [selectedAccount, setSelectedAccount] = useState<BdAccountNumberBean>({} as BdAccountNumberBean);

    const _columns: BdTableColumn[] = [
        { header: "IsActive", field: "IsActiveString", typeof: String },
        { header: "AccountNumber", field: "AccountNumber", typeof: String },
        { header: "Subscription Type Name", field: "SubscriptionTypeName", typeof: String },
        { header: "Subscription Case Limit", field: "SubscriptionCaseLimit", typeof: Number },
        { header: "Subscription User Limit", field: "SubscriptionUserLimit", typeof: Number },
        { header: "Name", field: "Name", typeof: String },
        { header: "Company", field: "Company", typeof: String },
        { header: "Email", field: "Email", typeof: String },
        { header: "Notes", field: "Notes", typeof: String },
    ];

    //modal managers
    const AccountNumberUpdateModal = useBdModal();

    const _handleUpdateAccount = (account: BdAccountNumberBean): void => {
        setSelectedAccount(account);
        AccountNumberUpdateModal.Show();
    };

    const _onUpdateAccount = (accountResult: BdAccountNumberBean): void => {
        const accountIndex: number = accounts.findIndex(i => i.RowKey === accountResult.RowKey);
        if (accountIndex >= 0) {
            accounts[accountIndex] = accountResult;
        }
    };

    const _accountActions = async (account: BdAccountNumberBean, action: string, successMessage: string) => {
        try {
            const accountResult: BdAccountNumberBean = await _bundledocsApiInstance.AccountNumbers.Actions(account, action);

            //show confirmation
            toastSuccess(successMessage);

            const updatedAccounts: BdAccountNumberBean[] = action === "remove"
                ? accounts.filter(x => x.RowKey !== accountResult.RowKey)
                : accounts.map(account => {
                    if (account.RowKey === accountResult.RowKey) {
                        account.IsActive = accountResult.IsActive;
                        account.IsActiveString = accountResult.IsActiveString;
                    }

                    return account;
                });

            onAccountNumberUpdates([]);
            onAccountNumberUpdates(updatedAccounts);
        }
        catch (error: any) {
            toastError(error as BdToastFailureMessage);
        }
    };

    //options menu
    const _getAccountActions = (account: BdAccountNumberBean) => {
        const revoke = {
            request: `Are you sure you want to revoke the 'Account Number' for ${account.AccountNumber}?`,
            success: `'Account Number' for ${account.AccountNumber} has been revoked.`,
            action: "revoke"
        };
        const activate = {
            request: `Are you sure you want to renew the 'Account Number' for ${account.AccountNumber}?`,
            success: `'Account Number' for ${account.AccountNumber} has been renewed.`,
            action: "activate"
        };
        const remove = {
            request: `Are you sure you want to delete the 'Account Number' ${account.AccountNumber}?`,
            success: `'Account Number' for ${account.AccountNumber} has been removed.`,
            action: "remove"
        };

        return (
            <BdDropDown bdTitle={<BdIconOptions size={18} style={{ marginBottom: "0.2rem" }} />}
                bdShowCaret={false} bdId={"accountAction_" + account.RowKey}>
                <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => { _accountActions(account, activate.action, activate.success); }}
                    bdIcon={<BdIconStripeProfile />} bdText="Activate" bdConfirmMessage={activate.request} />
                <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => _handleUpdateAccount(account)}
                    bdIcon={<BdIconEditNote />} bdText="Edit" />
                <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => { _accountActions(account, revoke.action, revoke.success); }}
                    bdIcon={<BdIconReceipt />} bdText="Revoke" bdConfirmMessage={revoke.request} />
                <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => { _accountActions(account, remove.action, remove.success); }}
                    bdIcon={<BdIconDisableAuthorization />} bdText="Delete" bdConfirmMessage={remove.request} />

            </BdDropDown>
        );
    };

    return (
        <>
            <BdTable {...otherProps} bdColumns={_columns} bdItems={accounts} onActions={_getAccountActions} />
            <BdAccountNumberCreateModal {...AccountNumberUpdateModal.BdModalProps}
                bdTitle="Update Account Number"
                createAction={false}
                onUpdate={_onUpdateAccount}
                account={selectedAccount}></BdAccountNumberCreateModal>
        </>
    );
}
