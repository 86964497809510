import { BdButton, BdGrid, BdTable } from "bundledocs.common.client/modules/bd-components";
import { BdIconDownload } from "bundledocs.common.client/src";
import { BdTableColumn } from "bundledocs.common.client/src/components/bd-data/bd-table";
import cx from "classnames";
import React, { useState } from "react";
import { BdBundlePortalBean } from "../../models/providers/bean/bd-live-bundles-bean/bd-bundle-bean";
import bdStyle from "./bd-list-with-summary.module.css";

export interface BdBundlesListProps extends React.HTMLAttributes<HTMLElement> {
    bdBundles: BdBundlePortalBean[];
    bdDocumentsCount?: number,
    bdFileSize?: string,
    onRowSelect?: (rowData: any) => void;
}

/**
 * Components that show a list of live bundles that receives in  the props
 * @param param0
 */
export default function BdBundlesListWithSummary({
    bdBundles,
    bdDocumentsCount,
    bdFileSize,
    onRowSelect = void 0,
    ...otherProps
}: BdBundlesListProps): JSX.Element {

    const [columns, setColumns] = useState<any[]>([]);
    const [values, setValues] = useState<any[][]>([]);
    const [bundlesCount, setBundlesCount] = useState<number>(0);
    const [templatesCount, setTemplatesCount] = useState<number>(0);
    const [adjustCount, setAdjustCount] = useState<number>(0);

    /** create columns in excel */
    React.useEffect(() => {

        //declare columns array      
        const columnNames: string[] = [
            "AccessTypeName",
            "BriefCreated",
            "Email",
            "LastAccessedTime",
            "LastAccessedAction",
            "CaseDocuments",
            "BriefDateRegenerated",
            "CreatedSince"
        ];
        setColumns(columnNames);
    }, []);

    /** generate values once columns is populated */
    React.useEffect(() => {
        if (columns.length > 0) {
            const newValues: (any[])[] = [];

            bdBundles.forEach((sub: BdBundlePortalBean) => {
                const rowData: any[] = columns.map((column: keyof BdBundlePortalBean) => sub[column]);
                newValues.push(rowData);
            });

            setValues(newValues);
        }

        setBundlesCount(bdBundles.filter(k => k.AccessType === 0).length);
        setTemplatesCount(bdBundles.filter(k => k.AccessType === 1).length);
        setAdjustCount(bdBundles.filter(k => k.AccessType === 2).length);

    }, [columns, bdBundles]);


    const _columns: BdTableColumn[] = [
        { header: "Type", field: "AccessTypeName", typeof: String },
        { header: "Created", field: "BriefCreated", typeof: String },
        { header: "Email", field: "Email", typeof: String },
        { header: "Last Activity Date", field: "LastAccessedTime", typeof: String },
        { header: "Last Activity Action", field: "LastAccessedAction", typeof: Date },
        { header: "Document Count", field: "CaseDocuments", typeof: String },
        { header: "Date Regenerated", field: "BriefDateRegenerated", typeof: String },
        { header: "Created Since", field: "CreatedSince", typeof: String },
    ];

    const _handleOnExportCSVClick = () => {
        const csvContent = [columns.join(","), ...values.map(row => row.join(","))].join("\r\n");
        const link = document.createElement("a");
        link.href = "data:text/plain;charset=utf-8," + encodeURIComponent(csvContent);
        link.setAttribute("download", "Live Bundles Report.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (

        <BdGrid {...otherProps} className={bdStyle.Container} >
            <div className={bdStyle.TableContainer}>
                <BdTable {...otherProps} bdColumns={_columns} bdItems={bdBundles} onRowSelect={onRowSelect} bdSelectFirstRow={true} />
            </div>
            <div className={bdBundles.length > 0 ? cx(bdStyle.DisplayDiv, bdStyle.FooterHeight) : bdStyle.HideDiv}>
                <span><b>Bundles:</b> {bundlesCount}</span>
                <span><b>Templates:</b> {templatesCount}</span>
                <span><b>Editor:</b> {adjustCount}</span>
                <span>{bdDocumentsCount && <><b>Documents:</b> {bdDocumentsCount}</>}</span>
                <span>{bdFileSize && <><b>File Size:</b> {bdFileSize}</>}</span>
                <span>
                    <BdButton bdBlock={false} onClick={_handleOnExportCSVClick} bdText="Export CSV" bdIcon={<BdIconDownload size={15} />} disabled={(!bdBundles || bdBundles.length === 0)} />
                </span>
            </div>
        </BdGrid>
    );
}
