import { Row } from "react-bootstrap";
import { BdAlignment } from "./bd-layout-constants";

export interface BdRowProps extends React.HTMLAttributes<HTMLElement> {
    lg?: number | "auto" | { cols: number | "auto" }
    md?: number | "auto" | { cols: number | "auto" }
    sm?: number | "auto" | { cols: number | "auto" }
    xl?: number | "auto" | { cols: number | "auto" }
    xs?: number | "auto" | { cols: number | "auto" }
    xxl?: number | "auto" | { cols: number | "auto" }
    bdAlignment?: BdAlignment
    children?: JSX.Element | JSX.Element[] | undefined | ""
}

export default function BdGrid({
    bdAlignment,
    className,
    ...otherProps
}: BdRowProps): JSX.Element {
    let rowClass: string = className ?? "";
    switch (bdAlignment) {
        case BdAlignment.Center:
            rowClass += " justify-content-center";
            break;
    }
    return (
        <Row className={rowClass} {...otherProps} />
    );
}
