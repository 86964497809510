import { Col } from "react-bootstrap";

export interface BdColProps extends React.HTMLAttributes<HTMLElement> {
    lg?: boolean | "auto" | number | { span?: boolean | "auto" | number, offset?: number, order?: "first" | "last" | number }
    md?: boolean | "auto" | number | { span?: boolean | "auto" | number, offset?: number, order?: "first" | "last" | number }
    sm?: boolean | "auto" | number | { span?: boolean | "auto" | number, offset?: number, order?: "first" | "last" | number }
    xl?: boolean | "auto" | number | { span?: boolean | "auto" | number, offset?: number, order?: "first" | "last" | number }
    xs?: boolean | "auto" | number | { span?: boolean | "auto" | number, offset?: number, order?: "first" | "last" | number }
    xxl?: boolean | "auto" | number | { span?: boolean | "auto" | number, offset?: number, order?: "first" | "last" | number }
    children?: JSX.Element | JSX.Element[] | undefined | ""
}

export default function BdCol({
    ...props
}: BdColProps): JSX.Element {
    return (
        <Col {...props} />
    );
}
