import * as React from "react";
import { JSXElementConstructor } from "react";
import { useForm } from "react-hook-form";
import { BdFormRegistrationProps, IBdFormValidation } from "./bd-form-constants";
import { BdFormHelper } from "./bd-form-helper";

export interface IBdFormProps {
    onSubmit: (formData: any) => Promise<boolean | { success: boolean, data: any }>;
    onIsValidChange?: Function;
    submitOnEnter?: string[] | boolean;
    children: React.ReactElement<any, string | JSXElementConstructor<any>> | React.ReactElement<any, string | JSXElementConstructor<any>>[];
}

interface useBdFormResult {
    getBdFormFieldProps?: (name: string, value: any, validations?: IBdFormValidation[]) => BdFormRegistrationProps
}

const BdFormContext = React.createContext<useBdFormResult>({});

/**
 * Component that manages forms
 * @param onSubmit: submit handler, it is only fired when the form has no errors
 * @param onIsValidChange: isValid change handler, it is called always a value change
 */
export default function BdForm({ children, onSubmit, submitOnEnter, onIsValidChange = void 0 }: IBdFormProps) {

    //create form defining validations
    const methods = useForm({
        mode: "onBlur",
    });

    const { handleSubmit, reset } = methods;

    React.useEffect(() => {
        onIsValidChange?.(methods.formState.isValid);
    }, [methods.formState.isValid]);

    const _handleSubmit = async (data: any) => {
        const result: boolean | { success: boolean, data: any } = await onSubmit(data);
        if (typeof result == "boolean") {
            if (result) {
                //if has been submitted correctly, reset form
                reset(data);
            }
        }
        else if (result?.success) {
            //form has been submitted correctly
            if (result?.data) {
                //reset form with override data
                //Note: undefined doesn't override the value.
                //when want to empty a string field, send in an empty string, not undefined.
                reset(result.data);
            }
            else {
                //reset form with original data
                reset(data);
            }
        }
    };

    const getBdFormFieldProps = React.useCallback(
        (name: string, value: any, validations?: IBdFormValidation[]) => BdFormHelper.GetBdFormFieldProps(name, value, methods, validations),
        [methods]
    );

    const _handleOnKeyPress = async (event: any) => {
        //provide some typing for the event for maintainablity
        const typedEvent = event as KeyboardEvent & { target: HTMLInputElement | HTMLTextAreaElement };

        //ensure the target element contains a value that could trigger validation
        if (typedEvent.target instanceof HTMLInputElement || typedEvent.target instanceof HTMLTextAreaElement) {
            //only perform validation if the target field has a value
            if (typedEvent.target.value) {
                if (submitOnEnter && Array.isArray(submitOnEnter) && typedEvent.target.name && submitOnEnter.includes(typedEvent.target.name)) {
                    //trigger live validation of active form field when this is a form with Enter submission
                    typedEvent.target.blur();
                    typedEvent.target.focus();

                    //submit the form when the user hits the enter key on the keyboard or the numpad
                    if (typedEvent.code === "Enter" || typedEvent.code === "NumpadEnter") {
                        //no need to write the return key value as it will be ignored
                        typedEvent.preventDefault();

                        //trigger validation events and submit the form
                        await methods.trigger();
                        await handleSubmit(_handleSubmit)();
                    }
                }
            }
        }
    };

    return (
        <BdFormContext.Provider value={{ getBdFormFieldProps }}>
            <form onSubmit={handleSubmit(_handleSubmit)} children={children} onKeyPress={submitOnEnter ? _handleOnKeyPress : undefined} />
        </BdFormContext.Provider>
    );
}

/**
 * Custom hook to implement components compatible with BdForm
 *  @param name: Name of the form field
 *  @param value: Value of the form field
 *  @param validations (optional): Array with the validations applied to the form field
 * */
export const useBdForm = (name: string, value: any, validations?: IBdFormValidation[]) => {
    //get form data from context
    const bdFormFieldProps = React.useContext<useBdFormResult>(BdFormContext)?.getBdFormFieldProps?.(name, value, validations);

    //update value in form everytime it changes from parent
    React.useEffect(() => {
        bdFormFieldProps?.bdSetValue?.(value);
    }, [value]);

    //return form data or default
    return bdFormFieldProps
        ?? {
            bdErrorMessage: "",
            bdSetValue: undefined,
            bdTouched: false,
            register: undefined
        };
};

