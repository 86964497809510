import * as React from "react";
import { BdImageSize } from "./bd-graphics-constants";
import SvgLogo from "../../assets/img/bundledocs-logo.svg";

export interface BdLogoProps {
    BdSize?: BdImageSize
}

/**
 * Bundledocs logo
 * @param param0
 */
const BdLogo = ({ BdSize = BdImageSize.Default }: BdLogoProps) => {
    let maxWidth: number | undefined = undefined;

    switch (BdSize) {
        case BdImageSize.XS:
            maxWidth = 200;
            break;
        case BdImageSize.S:
            maxWidth = 300;
            break;
        case BdImageSize.M:
            maxWidth = 400;
            break;
        case BdImageSize.L:
            maxWidth = 500;
            break;
        case BdImageSize.XL:
            maxWidth = 600;
            break;
    }

    return (
        <img src={SvgLogo} alt="" style={{ maxWidth:maxWidth }} />
    );
};

export default BdLogo;
