import { BdServiceBase } from "bundledocs.common.client/modules/bd-services";

/**
 * Session Service
 * */
export default class BdServiceSession extends BdServiceBase {
    private static ApiUrl = "/api/v1/support-user/me";

    /**
     * Returns portal user data
     */
    GetPortalUser(): Promise<string> {
        return super.Get(BdServiceSession.ApiUrl);
    }
}
