import {
    BdDatePicker,
    BdFormValidationPolicy,
    BdModal,
    BdSelector,
    BdTextBox,
    BdTextBoxType,
    useBdToast
} from "bundledocs.common.client/modules/bd-components";
import { BdEnums, BdToastFailureMessage } from "bundledocs.common.client/modules/bd-models";
import { BdIconCalendar } from "bundledocs.common.client/src";
import { useEffect, useState } from "react";
import { Category } from "../../../constants/bd-enum-types";
import {
    BdNotificationBean
} from "../../../models/providers/bean/bd-notification-bean/bd-notification-bean";

interface IBdNotificationsCreateModalProps {
    bdNotification: BdNotificationBean;
    bdTitle?: string;
    onCreate?: Function;
    onCancel?: Function;
    bdShow: boolean;
}

/** Modal for creating the notification */
export const BdNotificationsCreateModal = ({
    bdNotification,
    bdTitle,
    onCreate,
    onCancel,
    bdShow
}: IBdNotificationsCreateModalProps) => {
    const [updating, setUpdating] = useState(false);    
    const defaultDateFormat = "Pp";
    const [dateTimeToLiveStart, setDateTimeToLiveStart] = useState<Date>(new Date());

    //toast functions
    const { toastError } = useBdToast();

    const [updateNotification, setUpdateNotification] = useState<BdNotificationBean>(bdNotification);

    /** set updating=false on close */
    useEffect(() => {
        if (!bdShow) {
            setUpdating(false);
        }
        setUpdateNotification(bdNotification);
    }, [bdShow]);

    /** create global notification */
    const _handleNotificationCreate = async (notification: BdNotificationBean): Promise<boolean> => {
        let handleResult = true;

        try {
            setUpdating(true);

            //create notification
            onCreate?.(notification);

            handleResult = true;
        }
        catch (error: any) {
            toastError(error as BdToastFailureMessage);
            handleResult = false;
        }
        finally {
            setUpdating(false);
        }

        return handleResult;
    };

    /** cancel global notification */
    const _handleCancel = async (notification: BdNotificationBean): Promise<boolean> => {

        let handleResult = true;

        try {
            setUpdating(true);

            //cancel creating notification
            onCancel?.(notification);

            handleResult = true;
        }
        catch (error: any) {
            toastError(error as BdToastFailureMessage);
            handleResult = false;
        }
        finally {
            setUpdating(false);
        }

        return handleResult;
    };

    return (
        <BdModal bdTitle={bdTitle} bdDisabled={updating} onConfirm={_handleNotificationCreate} onCancel={_handleCancel} bdShow={bdShow} >
            <BdTextBox
                bdLabel="Title"
                name="Title"
                bdValidations={[{ policy: BdFormValidationPolicy.Required }]}
                style={{ marginBottom: "1rem" }}
                bdValue={updateNotification?.Title}
            />
            <BdTextBox
                bdLabel="Body"
                bdValidations={[{ policy: BdFormValidationPolicy.Required }]}
                name="Body"
                style={{ marginBottom: "1rem" }}
                bdValue={updateNotification?.Body}
                bdTextBoxType={BdTextBoxType.Text}
                bdShowTextarea={true}
                bdRows={3}
            />
            <BdTextBox
                bdLabel="Action Text"
                name="ActionText"
                style={{ marginBottom: "1rem" }}
                bdValue={updateNotification?.ActionText}
                bdTextBoxType={BdTextBoxType.Text}
            />
            <BdTextBox
                bdLabel="Action Url"
                name="ActionUrl"
                style={{ marginBottom: "1rem" }}
                bdValue={updateNotification?.ActionUrl}
                bdTextBoxType={BdTextBoxType.Text}
            />
            <BdSelector
                bdOptions={Category}
                bdLabel="Category"
                bdValue={updateNotification?.Category}
                style={{ marginBottom: "1rem" }}
                name="Category"
            />
            <BdDatePicker
                bdLabel="Time To Live"
                name="TimeToLive"
                id="dateTimeToLive"
                bdIcon={<BdIconCalendar />}
                dateFormat={defaultDateFormat}
                bdValue={dateTimeToLiveStart}
                wrapperClassName="dateRangeStartPicker"
                onUpdate={setDateTimeToLiveStart}
                style={{ marginBottom: "1rem", marginTop: "1rem" }}
                bdMin={new Date()}
                bdShowTimeSelect={true}
            />
        </BdModal>
    );
};
