import { addDays, addMonths, addYears, format, parse } from "date-fns";

/**
 * Class with common methods related with formatting
 * */
export default class FormatHelper {
    private static padTo2Digits(num: number): string {
        return num.toString().padStart(2, "0");
    }

    //date to string 'yyyy-MM-dd HH:mm:ss'
    static dateToString(date: Date): string {
        if (!(date instanceof Date)) {
            throw new Error("Invalid date object");
        }
        return (
            [
                date.getFullYear(),
                FormatHelper.padTo2Digits(date.getMonth() + 1),
                FormatHelper.padTo2Digits(date.getDate()),
            ].join("-") +
            " " +
            [
                FormatHelper.padTo2Digits(date.getHours()),
                FormatHelper.padTo2Digits(date.getMinutes()),
                FormatHelper.padTo2Digits(date.getSeconds()),
            ].join(":")
        );
    }

    //date to string 'yyyy-MM-dd'
    static dateToStringShort(date: Date): string {
        return (
            [
                date.getFullYear(),
                FormatHelper.padTo2Digits(date.getMonth() + 1),
                FormatHelper.padTo2Digits(date.getDate()),
            ].join("-")
        );
    }

    /*Method which formats the date using date-fns library    */
    static getFormattedDate(dateFormatString: string): string {
        if (dateFormatString === undefined || dateFormatString === null || dateFormatString.length == 0) {
            dateFormatString = "MMM dd yyyy";
        }

        let formattedDate = "";
        const todaysdate = new Date();

        try {
            formattedDate = format(todaysdate, dateFormatString);
        }
        catch {
            //ignoring this error since empty string is returned when the date-fns library doesn't suppport a particular format
        }

        return formattedDate;
    }

    /* Converting date to provided format */
    static convertDateToFormat(date: Date, dateFormat: string): string {
        return format(date, dateFormat);
    }

    /* Converting string date to IsoDateFormat */
    static convertStringDateToIsoDateFormat(date: string, dateFormat: string, addDays = 0): string {
        const updatedDate: Date = this.addDaysToDate(parse(date, dateFormat, new Date()), addDays);
        return format(updatedDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
    }

    /* Subtract day from date */
    static subtractDays(date: Date, numberOfDays: number): Date {
        return new Date(
            date.getFullYear(),
            date.getMonth(),
            (date.getDate() - numberOfDays)
        );
    }

    /* Add day to date */
    static addDaysToDate(date: Date, days: number): Date {
        return addDays(date, days);
    }

    /* Add months to date */
    static addMonthsToDate(date: Date, months: number): Date {
        return addMonths(date, months);
    }

    /* Add years to date */
    static addYearsToDate(date: Date, years: number): Date {
        return addYears(date, years);
    }
}
