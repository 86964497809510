import { BdNavigationItem, BdSidebar } from "bundledocs.common.client/modules/bd-components";
import { BdIconAccountNumbers, BdIconNotification, BdIconReceipt, BdIconReports, BdIconStripe, BdIconUser } from "bundledocs.common.client/modules/bd-graphics";
import { BdIconLiveBundles } from "bundledocs.common.client/modules/bd-graphics";
import { useLocation, useNavigate } from "react-router-dom";

export interface BdLeftMenuProps extends React.HTMLAttributes<HTMLElement> {
    bdUsername: string,
    bdExtended: boolean,
    onExpandSidebarChange?: Function;
}

/** left panel menu */
export const BdLeftMenu = ({ bdUsername = "", bdExtended = true, onExpandSidebarChange = void 0 }: BdLeftMenuProps) => {
    const navigate = useNavigate();

    const location = useLocation();
    const currentLocation = location.pathname.toLowerCase().slice(1);

    //navigation items list
    const _navigationItems: BdNavigationItem[] = [
        { bdIcon: <BdIconUser />, bdText: "Users", bdNavigate: "users", bdActive: currentLocation === "users" },
        { bdIcon: <BdIconReceipt />, bdText: "License", bdNavigate: "licenses", bdActive: currentLocation === "licenses" },
        { bdIcon: <BdIconStripe />, bdText: "Stripe", bdNavigate: "subscriptions", bdActive: currentLocation === "subscriptions" },
        { bdIcon: <BdIconAccountNumbers />, bdText: "Account Numbers", bdNavigate: "account-numbers", bdActive: currentLocation === "account-numbers" },
        { bdIcon: <BdIconReports />, bdText: "Reports", bdNavigate: "reports", bdActive: currentLocation === "reports" },
        { bdIcon: <BdIconLiveBundles />, bdText: "Live Bundles", bdNavigate: "live-bundles", bdActive: currentLocation === "live-bundles" },
        { bdIcon: <BdIconNotification />, bdText: "Notifications", bdNavigate: "notifications", bdActive: currentLocation === "notifications" },
    ];

    const _handleClickNavigationLink = (navigationItem: BdNavigationItem) => {
        //performing navigation action here because navigation needs to be used in the context of a <Router> component
        navigate(`/${navigationItem.bdNavigate}`);
    };

    return (
        <BdSidebar bdNavigationItems={_navigationItems} bdExtended={bdExtended} onToggleSidebar={onExpandSidebarChange} onClickNavigationLink={_handleClickNavigationLink} bdUsername={bdUsername} />
    );
};
