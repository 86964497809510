import * as React from "react";
import { Flip, toast, ToastContainer, TypeOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ActionEvent, UserNotification } from "../../../modules/bd-models";
import { BdBriefEventTypes, BdDocumentEventTypes } from "../../helpers/bd-events-helper";
import { useBdEventsManager } from "../../hooks/useBdEventsManager";
import { BdToastFailureMessage, BdToastFailureResponse } from "../../models/errors/BdToastFailureMessage";
import { UserNotificationType } from "../../models/providers/domain/user-context/UserNotification";
import IBdServiceEvents from "../../services/core-api/interfaces/IBdServiceEvents";
import "./bd-toast.css";

/*
 * EXAMPLE OF USE:
 * 
 * const { toastDocumentAction, toastSuccess } = useBdToast(BundledocsServicesProvider.BundledocsApiInstance().Events);
 *
 * toastDocumentAction(context, batch, 'Merging documents');
 * toastSuccess('Document updated successfully');
 * 
 * <>
 *  <BdActionToastContainer />
 * </>
 *
 */
interface BdToastShowOptions {
    modalId?: string;
}

export interface useBdToastResult {
    //simple toasts
    toastInfo: (message: string, options?: BdToastShowOptions, icon?: React.ReactNode) => void,
    toastSuccess: (message: string, icon?: React.ReactNode) => void,
    toastWarning: (message: string, icon?: React.ReactNode) => void,
    toastError: (failureMessage: BdToastFailureMessage | BdToastFailureResponse | string, icon?: React.ReactNode) => void,
    toastUserNotification: (notification: UserNotification) => void,
    //toast that tracks a UAC_BriefDocumentAction action given a context and a batch, updating its own state, progress and visibility
    toastDocumentAction: (context: string, batch: string, message: string, icon?: React.ReactNode,
        successHandler?: (success?: ActionEvent) => void,
        failureHandler?: (failure?: ActionEvent) => void,
        beginHandler?: (begin?: ActionEvent) => void) => void,
    //toast that tracks a UAC_BriefSwapBlobStorageAccount action given a context and a batch, updating its own state, progress and visibility
    toastBriefAction: (context: string, batch: string, action: BdBriefEventTypes, icon?: React.ReactNode,
        successHandler?: (success?: ActionEvent) => void,
        progressHandler?: (progress?: any) => void,
        failureHandler?: (failure?: ActionEvent) => void,
        beginHandler?: (begin?: ActionEvent) => void) => void
}

/**
 * Custom Hook to show toasts
 * @param bdServiceEvents
 */
export function useBdToast(bdServiceEvents?: IBdServiceEvents): useBdToastResult {
    //time that notifications will be visible
    const _longTime = 5000;
    const _shortTime = 3000;

    const { startBriefDocumentEventsListener, startBriefEventsListener } = useBdEventsManager(bdServiceEvents);

    /**
     * SIMPLE TOAST METHODS
     */

    const _toastInfo = (message: string, options?: BdToastShowOptions, icon?: React.ReactNode) =>
        toast(message, { toastId: options?.modalId, type: toast.TYPE.INFO, hideProgressBar: true, autoClose: _longTime, icon: icon, closeButton: true, draggable: false, transition: Flip });

    const _toastSuccess = (message: string, icon?: React.ReactNode) =>
        toast(message, { type: toast.TYPE.SUCCESS, hideProgressBar: true, autoClose: _shortTime, icon: icon, closeButton: true, draggable: false, transition: Flip });

    const _handleToast = (failureObject: BdToastFailureMessage | BdToastFailureResponse | string, toastType: TypeOptions, icon?: React.ReactNode) => {
        try {
            let errorMessage = "";
            if (typeof failureObject === "string") {
                errorMessage = failureObject;
            }
            else {
                let failureResponse = null;
                if ((failureObject as BdToastFailureResponse)?.data) {
                    failureResponse = failureObject as BdToastFailureResponse;
                }
                else if ((failureObject as BdToastFailureMessage)?.response) {
                    failureResponse = (failureObject as BdToastFailureMessage).response;
                }

                if (failureResponse?.data?.status) {
                    switch (failureResponse?.data?.status) {
                        case 401:
                            errorMessage = "You do not have permission to perform that action";
                            break;
                        default:
                            break;
                    }
                }
                else if (failureResponse?.data?.errors) {
                    errorMessage = failureResponse?.data?.errors[0]?.message;
                }
            }

            if (errorMessage) {
                toast(errorMessage, { type: toastType, hideProgressBar: true, autoClose: _longTime, icon: icon, closeButton: true, draggable: false, transition: Flip });
            }
        }
        catch {
        }
    };

    const _toastWarning = (failureObject: BdToastFailureMessage | BdToastFailureResponse | string, icon?: React.ReactNode) => {
        _handleToast(failureObject, toast.TYPE.WARNING, icon);
    };

    const _toastError = (failureObject: BdToastFailureMessage | BdToastFailureResponse | string, icon?: React.ReactNode) => {
        _handleToast(failureObject, toast.TYPE.ERROR, icon);
    };

    const _toastUserNotification = (notification: UserNotification) => {
        //It is possible to extend this method to customize these toasts or show them in a different location
        switch (notification.Type) {
            case UserNotificationType.Success:
                _toastSuccess(notification.Body);
                break;
            case UserNotificationType.Error:
                _toastError(notification.Body);
                break;
            default:
                _toastInfo(notification.Body);
        }
    };

    /**
     * COMMON ACTION METHODS
     */
    const _addActionToast = (id: string, message: string, icon?: React.ReactNode, progress = 0) =>
        toast(`${message} (${(progress * 100)}%)`, { toastId: id, progress: progress, icon: icon });
    const _progressActionToast = (id: string, message: string, progress: number) =>
        toast.update(id, { progress: progress, render: `${message} (${(progress * 100)}%)` });
    const _errorActionToast = (id: string, message: string) =>
        toast.update(id, { icon: undefined, type: toast.TYPE.ERROR, progress: undefined, hideProgressBar: true, autoClose: _longTime, closeButton: true, render: message });
    const _successActionToast = (id: string, message: string) =>
        toast.update(id, { icon: undefined, type: toast.TYPE.SUCCESS, progress: undefined, hideProgressBar: true, autoClose: _shortTime, closeButton: true, render: message });

    /**
     * DOCUMENT ACTION METHODS
     */
    const _toastDocumentAction = (context: string, batch: string, message: string, icon?: React.ReactNode,
        successHandler?: (success?: any) => void,
        failureHandler?: (failure?: any) => void,
        beginHandler?: (begin?: any) => void) => {

        if (bdServiceEvents) {
            const toastId: string = context + batch;

            _addActionToast(toastId, message, icon, 0.2);

            startBriefDocumentEventsListener(context, batch, BdDocumentEventTypes.ACTION,
                (begin) => {
                    _progressActionToast(toastId, message, 0.5);
                    beginHandler?.(begin);
                },
                (success) => {
                    _successActionToast(toastId, message);
                    successHandler?.(success);
                },
                (failure) => {
                    _errorActionToast(toastId, `${message} - ${(failure as ActionEvent)?.data?.Message ? (failure as ActionEvent)?.data?.Message : "Unable to perform the action"}`);
                    failureHandler?.(failure);
                },
            );
        }
    };

    /**
     *  ACTION METHODS
     */
    const _toastBriefAction = (context: string, batch: string, action: BdBriefEventTypes, icon?: React.ReactNode,
        successHandler?: (success?: any) => void,
        progressHandler?: (progress?: any) => void,
        failureHandler?: (failure?: any) => void,
        beginHandler?: (begin?: any) => void) => {

        if (bdServiceEvents) {
            const toastId: string = context + batch;

            const addActionMessage = action === BdBriefEventTypes.SWAP_BLOB_STORAGE_ACCOUNT_EDITOR ? "Updating storage location" : "PLEASE WAIT..";
            const beginMessage = action === BdBriefEventTypes.SWAP_BLOB_STORAGE_ACCOUNT_EDITOR ? "Updating storage location" : "received...";
            const progressMessage = action === BdBriefEventTypes.SWAP_BLOB_STORAGE_ACCOUNT_EDITOR ? "Updating storage location" : "please Wait...";
            const successMessage = action === BdBriefEventTypes.SWAP_BLOB_STORAGE_ACCOUNT_EDITOR ? "Storage location updated" : "ready for use...";
            const failureMessage = action === BdBriefEventTypes.SWAP_BLOB_STORAGE_ACCOUNT_EDITOR ? "Updating storage location -" : "failed...";

            _addActionToast(toastId, addActionMessage, icon, 0.2);

            startBriefEventsListener(context, batch, action,
                (begin) => {
                    _progressActionToast(toastId, beginMessage, 0.5);
                    beginHandler?.(begin);
                },
                (progress) => {
                    _progressActionToast(toastId, progressMessage, 0.75);
                    progressHandler?.(progress);
                },
                (success) => {
                    _successActionToast(toastId, successMessage);
                    successHandler?.(success);
                },
                (failure) => {
                    _errorActionToast(toastId, `${failureMessage} ${failure ? failure : "Unable to perform the action"}`);
                    failureHandler?.(failure);
                },
            );
        }
    };

    return {
        toastInfo: _toastInfo,
        toastSuccess: _toastSuccess,
        toastWarning: _toastWarning,
        toastError: _toastError,
        toastUserNotification: _toastUserNotification,
        toastDocumentAction: _toastDocumentAction,
        toastBriefAction: _toastBriefAction
    };
}

/**
 * Toast container component
 */
export function BdActionToastContainer(): JSX.Element {
    return (
        <ToastContainer position='top-center' autoClose={false} closeButton={false} draggable={false} closeOnClick={false} pauseOnFocusLoss={false} style={{ textAlign: "left", zIndex: 2999 }} />
    );
}
