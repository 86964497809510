import { BdImageSize } from "../bd-graphics/bd-graphics-constants";
import BdLogo from "../bd-graphics/bd-logo";
import { BdMargin } from "../../style/bd-style-constants";
import BdGrid from "../bd-layout/bd-grid";
import BdRow from "../bd-layout/bd-row";
import { BdAlignment } from "../bd-layout/bd-layout-constants";

export interface BdErrorProps {
    bdTitle?: number | string,
    bdSubtitle?: string
}

/**
 * Error to show when an error occurs in the web. (i.e. 404 - Not Found, 500 - Internal Server Error)
 * @param param0
 */
export default function BdError({
    bdTitle = "",
    bdSubtitle = ""
}: BdErrorProps): JSX.Element {
    return (
        <BdGrid>
            <>
                <BdRow bdAlignment={BdAlignment.Center} style={{ marginBottom: BdMargin.Large }}>
                    <BdLogo BdSize={BdImageSize.M} />
                </BdRow>
                {bdTitle && <BdRow bdAlignment={BdAlignment.Center} xs="auto">
                    <h1>{bdTitle}</h1>
                </BdRow>}
                {bdSubtitle && <BdRow bdAlignment={BdAlignment.Center} xs="auto">
                    <h3>{bdSubtitle}</h3>
                </BdRow>}
            </>
        </BdGrid>
            
    );
}
