import { BdIconClose } from "bundledocs.common.client/modules/bd-graphics";
import { FormatHelper } from "bundledocs.common.client/modules/bd-helpers";
import { BdButton, BdButtonSize, BdButtonType, BdTooltip } from "bundledocs.common.client/src";
import { BdIconFeatureNotification, BdIconGeneralNotification } from "bundledocs.common.client/modules/bd-graphics";
import moment from "moment";
import * as React from "react";
import { BdNotificationBean, GlobalNotificationCategory } from "../../../models/providers/bean/bd-notification-bean/bd-notification-bean";
import bdStyle from "./bd-global-notification.module.css";

interface BdGlobalNotificationProps {
    notification: BdNotificationBean;
}

/**
 * Global Notification item
 * @param props
 */
export const BdGlobalNotification = ({ notification }: BdGlobalNotificationProps) => {

    //Events Handlers
    const _handleActionButtonClick = () => {
        if (notification.ActionUrl) {
            // Open the URL in a new tab     
            if (isAbsoluteUrl(notification.ActionUrl)) {
                window.open(notification.ActionUrl, "_blank");
            }
            else {
                window.open("https://app.bundledocs.com/#!" + notification.ActionUrl, "_blank");
            }
        }
    };

    function isAbsoluteUrl(url: string) {
        // Regular expression to match absolute URLs
        const absoluteUrlPattern = /^(?:\w+:)?\/\//;

        // Test if the URL matches the absolute URL pattern
        return absoluteUrlPattern.test(url);
    }

    //UI Methods
    const _dateText = () => {
        try {
            return moment(new Date()).fromNow();
        }
        catch {
            return "";
        }
    };

    const _annotationIcon = (className?: string) => {
        if (notification?.Category == GlobalNotificationCategory.Feature) {
            return (<BdIconFeatureNotification className={className} />);
        }
        else if (notification?.Category == GlobalNotificationCategory.General) {
            return (<BdIconGeneralNotification className={className} />);
        }
    };

    return (
        <div key={notification.RowKey} className={bdStyle.BdNotification}>
            <div className={bdStyle.BdNotificationBody}>
                <div className={bdStyle.BdNotificationIconContainer}>
                    {(<div className={bdStyle.BdNotificationIcon}>
                        {_annotationIcon(bdStyle.BdNotificationMainIcon)}
                    </div>
                    )}
                </div>
                <div className={bdStyle.BdNotificationTextContainer}>
                    <div className={bdStyle.BdNotificationTextHeader}>
                        <p className={bdStyle.BdNotificationTextTitle}>{notification.Title}</p>
                        <BdTooltip bdBody="Dismiss notification" bdPlacement="left" bdDelay={800} >
                            <div><BdIconClose className={bdStyle.BdNotificationButtonDismiss} /></div>
                        </BdTooltip>
                    </div>
                    <p className={bdStyle.BdNotificationTextBody}>{notification.Body}</p>
                </div>
            </div>
            <div className={bdStyle.BdNotificationFooter}>
                {(
                    <BdTooltip bdDelay={800} bdBody={FormatHelper.convertDateToFormat(new Date(), "yyyy-MM-dd HH:mm")}>
                        <span>
                            {_dateText()}
                        </span>
                    </BdTooltip>)}
                {notification.ActionText && (
                    <BdButton bdBlock={false} bdText={notification.ActionText} onClick={_handleActionButtonClick} bdSize={BdButtonSize.Small} bdType={BdButtonType.Light} className={bdStyle.ActionButton} />
                )}
            </div>
        </div>
    );
};


