import { BdEnumArray } from "../models/providers/bean/common-context/bd-enums";

export const UserRoles: BdEnumArray = [
    { name: "APPLICATION_USER", code: 0, description: "Application User", icon: "fa fa-users text-default" },
    { name: "FEDERATED_USER", code: 3, description: "Federated User", icon: "fa fa-users text-default" },
    { name: "APPLICATION_DEVELOPER", code: 2, description: "Application Developer", icon: "fa fa-users text-default" }
];

export const Currencies: BdEnumArray = [
    { name: "EUR", code: "EUR", description: "EUR - Euro" },
    { name: "USD", code: "USD", description: "USD - United States Dollar" },
    { name: "GBP", code: "GBP", description: "GBP - Pound Sterling" },
    { name: "AUD", code: "AUD", description: "AUD - Australian Dollar" }
];
