import { BdButton, BdCol, BdDatePicker, BdForm, BdFormValidationPolicy, BdRow, BdTextBox } from "bundledocs.common.client/modules/bd-components";
import { BdIconCalendar, BdIconSearch, BdIconUser, BdLoaderSpinner } from "bundledocs.common.client/modules/bd-graphics";
import { BdFormFilter } from "bundledocs.common.client/modules/bd-models";
import FormatHelper from "bundledocs.common.client/src/helpers/format-helper";
import SearchFilterHelper from "bundledocs.common.client/src/helpers/search-filter-helper";
import { useState } from "react";

export interface BdUsersSearchFormProps extends React.HTMLAttributes<HTMLElement> {
    onSearch?(bdFormFilter: Array<BdFormFilter>): Promise<void>;
}

/**
 * Component that shows some fields to fill by the user to search bundledocs users.
 * It returns a list of filters to apply in the search
 * @param param0
 */
export default function BdUsersSearchForm({
    onSearch = void 0,
    ...otherProps
}: BdUsersSearchFormProps): JSX.Element {
    const defaultDateFormat = "yyyy-MM-dd";

    const [isBtnSearchByEmailWorking, setIsBtnSearchByEmailWorking] = useState<boolean>(false);

    //manages the form submit
    const _handleOnSubmitSearchByEmail = async (data: { userText: string }): Promise<boolean> => {
        try {
            setIsBtnSearchByEmailWorking(true);
            await onSearch?.(_getFiltersForSearchByEmail(data));
        }
        finally {
            setIsBtnSearchByEmailWorking(false);
            return false;
        }
    };
    //returns a list of filters depends on the data that comes from the form
    const _getFiltersForSearchByEmail = (data: { userText: string }): BdFormFilter[] => {
        const filters: BdFormFilter[] = [];

        //filter by all
        if (data.userText.length > 0 && data.userText.indexOf("@") >= 0) {
            const searchTextSplit: string[] = data.userText.split("@");
            const filterByAll: BdFormFilter = {
                field: (searchTextSplit.length > 1 && searchTextSplit[0]) ? "email" : "emailgroup",
                value: data.userText
            };
            filters.push(filterByAll);
        }

        return filters;
    };

    const _searchByEmailForm = (
        <BdForm onSubmit={_handleOnSubmitSearchByEmail} submitOnEnter={["userText"]}>
            <BdRow>
                <BdCol xl={9} lg={9} md={8} sm={12}>
                    <BdTextBox
                        name="userText"
                        bdPlaceholder="mail@example.com / @example.com"
                        bdValidations={[{ policy: BdFormValidationPolicy.Required }]}
                        bdIcon={<BdIconUser />}
                    />
                </BdCol>

                <BdCol xl={3} lg={3} md={4} sm={12}>
                    <BdButton type="submit" disabled={isBtnSearchByEmailWorking} bdText="Email" bdBlock={true}
                        bdIcon={isBtnSearchByEmailWorking ? <BdLoaderSpinner bdSize={25} /> : <BdIconSearch />}
                    />
                </BdCol>
            </BdRow>
        </BdForm>
    );

    const [isBtnSearchByDateRangeWorking, setIsBtnSearchByDateRangeWorking] = useState<boolean>(false);
    const [dateRangeStart, setDateRangeStart] = useState<Date>(FormatHelper.subtractDays(new Date(), 7));
    const [dateRangeEnd, setDateRangeEnd] = useState<Date>(new Date());

    //manages the form submit
    const _handleOnSubmitSearchByDateRange = async (data: { dateRangeStart: string, dateRangeEnd: string }): Promise<boolean> => {
        try {
            setIsBtnSearchByDateRangeWorking(true);
            await onSearch?.(SearchFilterHelper.transformDateRangeToBdFormFilters(data));
        }
        catch {
            onSearch?.([]);
        }
        finally {
            setIsBtnSearchByDateRangeWorking(false);
            return false;
        }
    };

    const _searchByDateRangeForm = (
        <BdForm onSubmit={_handleOnSubmitSearchByDateRange}>
            <BdRow>
                <BdCol xl={5} lg={5} md={4} sm={5}>
                    <BdDatePicker
                        bdLabel=""
                        name="dateRangeStart"
                        id="dateRangeStart"
                        bdIcon={<BdIconCalendar />}
                        dateFormat={defaultDateFormat}
                        bdValue={dateRangeStart}
                        bdMax={dateRangeEnd}
                        wrapperClassName="dateRangeStartPicker"
                        onUpdate={setDateRangeStart}
                    />
                </BdCol>

                <BdCol xl={4} lg={4} md={4} sm={5}>
                    <BdDatePicker
                        bdLabel=""
                        name="dateRangeEnd"
                        id="dateRangeEnd"
                        bdIcon={<BdIconCalendar />}
                        dateFormat={defaultDateFormat}
                        bdValue={dateRangeEnd}
                        bdMin={dateRangeStart}
                        bdMax={new Date()}
                        wrapperClassName="dateRangeEndPicker"
                        onUpdate={setDateRangeEnd}
                    />
                </BdCol>

                <BdCol xl={3} lg={3} md={4} sm={2} style={{ alignSelf: "start" }}>
                    <BdButton type="submit"
                        disabled={isBtnSearchByDateRangeWorking}
                        bdText="Created"
                        bdBlock={true}
                        bdIcon={isBtnSearchByDateRangeWorking ? <BdLoaderSpinner bdSize={25} /> : <BdIconSearch />}
                    />
                </BdCol>
            </BdRow>
        </BdForm>
    );

    return (
        <>
            <BdRow>
                <BdCol sm={6}>
                    {_searchByEmailForm}
                </BdCol>
                <BdCol sm={6}>
                    {_searchByDateRangeForm}
                </BdCol>
            </BdRow>
        </>
    );
}
