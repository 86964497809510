import cx from "classnames";
import { Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import BdTooltip from "../bd-common/bd-tooltip";
import { BdIconCancel } from "../bd-graphics/bd-icons";
import bdStyle from "./bd-tile.module.css";

export interface BdTileProps {
    onClick: any,
    bdSource?: string,
    bdWidth?: string,
    bdFontSize?: string,
    bdTitle?: string,
    bdVariant?: string,
    bdLoading?: boolean,
    bdCancel?: () => void
}

export const BdTile = ({
    onClick,
    bdSource = "",
    bdWidth = "",
    bdFontSize = "",
    bdTitle = "",
    bdVariant = "light",
    bdLoading = false,
    bdCancel
}: BdTileProps) => {

    const _handleClick = () => {
        onClick?.();
    };

    const _handeCancelButtonClick = () => {
        if (bdCancel) {
            bdCancel();
        }
    };

    return (
        <Card
            border="secondary"
            style={{ width: bdWidth }}
            bg={bdVariant}
            key={bdVariant}
            text={bdVariant.toLowerCase() === "light" ? "dark" : "white"}
            className="mb-2"
        >
            <Card.Body className={bdStyle.zeroPadding}>
                <div className={bdStyle.bodyDiv}  >
                    <Card.Header style={{ fontSize: bdFontSize }} className={bdStyle.cardHeaderStyle}>{bdTitle}</Card.Header>
                    <div className={cx(bdStyle.spinnerDiv, bdLoading ? bdStyle.spinnerDisplayBlock : bdStyle.spinnerDisplayNone)} >
                        <Spinner
                            animation="border"
                            variant="light"
                            role="status"
                            className={cx(bdStyle.spinner, bdLoading ? bdStyle.spinnerDisplayBlock : bdStyle.spinnerDisplayNone)} >
                        </Spinner>
                        {bdCancel &&
                            <BdTooltip bdBody="Cancel" bdDelay={800}>
                                <span className={bdStyle.cancelButtonWrapper}>
                                    <BdIconCancel className={bdStyle.cancelButton} size={15} onClick={_handeCancelButtonClick} />
                                </span>
                            </BdTooltip>
                        }
                    </div>
                    <div className={bdStyle.cardImage}>
                        <Card.Img src={bdSource} onClick={_handleClick} className="img-fluid" />
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
