import { BriefDocument } from "../../../modules/bd-models";
import { BdApiResponse, BdServiceBase, BdServiceConfig } from "../bd-service-base";
import IBdServiceSections from "./interfaces/IBdServiceSections";

/**
 * Brief Service
 * */
export class BdServiceSections extends BdServiceBase implements IBdServiceSections {
    constructor(configuration: BdServiceConfig) {
        super(configuration);
    }
    private static ApiUrl = "/api/v1/sections";

    async Create(briefDocument: BriefDocument): Promise<BdApiResponse<BriefDocument>> {
        return super.Post(`${BdServiceSections.ApiUrl}`, briefDocument);
    }
}
