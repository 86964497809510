import { BdModal } from "../bd-modal/bd-modal";

export interface BdConfirmModalProps {
    bdTitle?: string;
    bdBody?: string;
    onConfirm?: Function;
    onCancel?: Function;
    bdShow: boolean;
    bdConfirmText?: string;
    bdCancelText?: string;
}

export const BdConfirmModal = ({
    bdTitle = "Are you sure?",
    bdBody = "",
    onConfirm = void 0,
    onCancel = void 0,
    ...otherProps
}: BdConfirmModalProps) => {
    /** Executes the confirm action if defined and hide the modal */
    const _handleConfirm = async (): Promise<boolean> => {
        let confirmedActionSuccess = true;
        if (onConfirm) {
            confirmedActionSuccess = await onConfirm();
        }
        if (confirmedActionSuccess) {
            onCancel?.();
        }
        return confirmedActionSuccess;
    };

    return (
        <BdModal {...otherProps} bdTitle={bdTitle} onConfirm={_handleConfirm} onCancel={onCancel} >
            <>{bdBody}</>
        </BdModal>
    );
};
