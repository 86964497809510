import { Activity, OAuth2ApiResponse, User, UserBundleSettingsDefault, UserNotification, UserUpdateEmailOptions, UserUpdatePersonalOptions, UserUpdateSettingGroupOptions } from "../../../modules/bd-models";
import { AnalystQuery } from "../../models/providers/domain/analyst-context/AnalystQuery";
import { UserNotificationDismissOptions } from "../../models/providers/domain/user-context/UserNotificationDismissOptions";
import { UserNotificationReadOptions } from "../../models/providers/domain/user-context/UserNotificationReadOptions";
import { BdApiResponse, BdServiceBase, BdServiceConfig } from "../bd-service-base";
import IBdServiceUsers from "./interfaces/IBdServiceUsers";

/**
 * Users Service
 * */
export class BdServiceUsers extends BdServiceBase implements IBdServiceUsers {
    constructor(configuration: BdServiceConfig) {
        super(configuration);
    }

    private readonly _usersArea = {
        Briefs: "user.briefs",
    };

    private static _apiUrl = "/api/v1/users";

    getTOTP(): Promise<BdApiResponse<boolean>> {
        return super.Get(`${BdServiceUsers._apiUrl}/me/2fa/totp`);
    }

    createTOTPChallenge(): Promise<BdApiResponse<User>> {
        return super.Post(`${BdServiceUsers._apiUrl}/me/2fa/totp`);
    }

    activateTOTP(code: string): Promise<BdApiResponse<User>> {
        return super.Put(`${BdServiceUsers._apiUrl}/me/2fa/totp?code=${code}`, code);
    }

    deleteTOTP(code: string): Promise<BdApiResponse<User>> {
        return super.Delete(`${BdServiceUsers._apiUrl}/me/2fa/totp?code=${code}`);
    }

    getActivities(user: User): Promise<BdApiResponse<Activity[]>> {
        return super.Get(`${BdServiceUsers._apiUrl}/${user.PartitionKey}/activitys`);
    }

    updateMyOptions(userOptions: UserBundleSettingsDefault): Promise<BdApiResponse<User>> {
        return super.Put(`${BdServiceUsers._apiUrl}/me/options`, userOptions);
    }

    getLazy(): Promise<BdApiResponse<any>> {
        return super.Get(`${BdServiceUsers._apiUrl}/me/lazy`);
    }

    getMe(): Promise<BdApiResponse<any>> {
        return super.Get(`${BdServiceUsers._apiUrl}/me?collapse[]=${this._usersArea.Briefs}`);
    }

    getUserBasic(userRowKey: string): Promise<BdApiResponse<any>> {
        return super.Get(`${BdServiceUsers._apiUrl}/${userRowKey}/basic`);
    }

    getMyNotifications(): Promise<BdApiResponse<UserNotification[]>> {
        return super.Get(`${BdServiceUsers._apiUrl}/me/notifications`);
    }

    readMyNotifications(readOptions: UserNotificationReadOptions): Promise<BdApiResponse<UserNotification[]>> {
        return super.Put(`${BdServiceUsers._apiUrl}/me/notifications/read`, readOptions);
    }

    getMyAnalystQueries(): Promise<BdApiResponse<AnalystQuery[]>> {
        return super.Get(`${BdServiceUsers._apiUrl}/me/analyst-querys`);
    }

    dismissNotifications(dismissOptions: UserNotificationDismissOptions): Promise<BdApiResponse<UserNotification[]>> {
        return super.Put(`${BdServiceUsers._apiUrl}/me/notifications/dismiss`, dismissOptions);
    }

    updatePersonal(userUpdatePersonalOptions: UserUpdatePersonalOptions): Promise<BdApiResponse<User>> {
        return super.Put(`${BdServiceUsers._apiUrl}/me/personal`, userUpdatePersonalOptions);
    }

    updateEmail(userUpdateEmailOptions: UserUpdateEmailOptions): Promise<BdApiResponse<User>> {
        return super.Put(`${BdServiceUsers._apiUrl}/me/email`, userUpdateEmailOptions);
    }

    async toggleTemplate(partitionKey: string, rowKey: string): Promise<OAuth2ApiResponse<any>> {
        return super.Put(`${BdServiceUsers._apiUrl}/me/toggle/template`, { PartitionKey: partitionKey, RowKey: rowKey });
    }

    async updateUserEmailByPartitionKey(user: UserUpdateEmailOptions): Promise<BdApiResponse<User>> {
        return super.Put(`${BdServiceUsers._apiUrl}/${user.PartitionKey}/email`, user);
    }

    async updateUserSettingGroupByPartitionKey(userRowKey: string, userSettingGroupUpdateOptions: UserUpdateSettingGroupOptions): Promise<OAuth2ApiResponse<any>> {
        return super.Put(`${BdServiceUsers._apiUrl}/${userRowKey}/group-setting`, userSettingGroupUpdateOptions);
    }

    async loadUserLazy(userRowKey: string): Promise<BdApiResponse<User>> {
        return super.Get(`${BdServiceUsers._apiUrl}/${userRowKey}/lazy`);
    }

    async subscriptionCapacity(user: User): Promise<BdApiResponse<any>> {
        return super.Get(`${BdServiceUsers._apiUrl}/${user.PartitionKey}/subscription/capacity`);
    }
}
