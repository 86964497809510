import { BdDropDown, BdGrid, BdMenuItem, BdTable, useBdToast } from "bundledocs.common.client/modules/bd-components";
import { FormatHelper } from "bundledocs.common.client/modules/bd-helpers";
import { BdEnums } from "bundledocs.common.client/modules/bd-models";
import { BdIconOptions, BdToastFailureMessage } from "bundledocs.common.client/src";
import { BdTableColumn } from "bundledocs.common.client/src/components/bd-data/bd-table";
import { BdIconDisableAuthorization } from "bundledocs.common.client/modules/bd-graphics";
import { MenuItemType } from "bundledocs.common.client/src/constants/bd-enums";
import React, { useEffect, useState } from "react";
import {
    BdNotificationBean
} from "../../../models/providers/bean/bd-notification-bean/bd-notification-bean";
import BundledocsServicesProvider from "../../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../../services/portal-api/bd-bundledocs-portal-api";
import bdStyle from "./bd-notification-search-list.module.css";

export interface BdNotificationsSearchListProps extends React.HTMLAttributes<HTMLElement> {
    bdNotifications: BdNotificationBean[];
    onNotificationDelete: (accounts: BdNotificationBean) => void;
    createdDate: Date;
}

/**
 * Components that show a list of notifications that receives in  the props
 * @param param0
 */
export default function BdNotificationsSearchList({
    bdNotifications,
    onNotificationDelete,
    createdDate = new Date(),
    ...otherProps
}: BdNotificationsSearchListProps): JSX.Element {
    const _bdEnums = new BdEnums();
    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    const { toastSuccess, toastError } = useBdToast();
    const [notifications, setNotifications] = useState<BdNotificationBean[]>(bdNotifications);

    const _columns: BdTableColumn[] = [
        { header: "Title", field: "Title", typeof: String },
        { header: "Body", field: "Body", typeof: String },
        { header: "Action Text", field: "ActionText", typeof: String },
        { header: "Action Url", field: "ActionUrl", typeof: String },
        { header: "Category", field: "CategoryName", typeof: String },
        { header: "Time To Live", field: "TimeToLiveString", typeof: String },
    ];

    // Define a function to set CategoryName based on Category code
    function _setCategoryNames(notifications: BdNotificationBean[]): BdNotificationBean[] {
        return notifications.map(notification => {
            const category = _bdEnums.GlobalNotificationCategory.find(cat => cat.code === notification.Category as number);
            if (category) {
                return {
                    ...notification,
                    CategoryName: category.description,
                    TimeToLiveString: FormatHelper.dateToString(new Date(notification.TimeToLive))
                };
            } else {
                return notification; // If category not found, return notification as it is
            }
        });
    }

    /** set notifications to the list */
    useEffect(() => {
        const updatedNotifications = _setCategoryNames(bdNotifications);
        setNotifications(updatedNotifications);
    }, []);

    /** delete global notification */
    const _handleDeleteNotification = async (notification: BdNotificationBean): Promise<void> => {

        let handleResult = true;

        try {
            //delete notification
            const deletedNotification = await _bundledocsApiInstance.Notifications.DeleteNotification(notification.RowKey);

            //show confirmation
            toastSuccess("Global notification deleted successfully!");

            onNotificationDelete?.(deletedNotification);

            handleResult = true;
        }
        catch (error: any) {
            toastError(error as BdToastFailureMessage);
            handleResult = false;
        }

    };

    //options menu
    const _getNotificationActions = (notification: BdNotificationBean) => {
        return (
            <BdDropDown bdTitle={<BdIconOptions size={18} style={{ marginBottom: "0.2rem" }} />}
                bdShowCaret={false} bdId={"notificationAction_" + notification.RowKey}>
                <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => _handleDeleteNotification(notification)}
                    bdIcon={<BdIconDisableAuthorization />} bdText="Delete notification" bdConfirmMessage={`Are you sure you want to delete the Notification ${notification.Title}? Some users might have read this notification.`} />
            </BdDropDown>
        );
    };

    return (
        <BdGrid {...otherProps} className={bdStyle.Container} >
            <div className={bdStyle.TableContainer}>
                <BdTable {...otherProps} bdColumns={_columns} bdItems={notifications} onActions={_getNotificationActions} bdShowActionsOnLeft={false} />
            </div>

        </BdGrid>
    );
}

