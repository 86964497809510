import { SubscriptionCapacity } from "bundledocs.common.client/modules/bd-models";
import { BdServiceBase } from "bundledocs.common.client/modules/bd-services";
import { UserAdminDetailsBean } from "../../models/providers/bean/user-portal-bean/bd-user-admin-bean";
import { UserPortalBean } from "../../models/providers/bean/user-portal-bean/user-portal-bean";
import { UserFeatureOptions } from "../../models/providers/user-context/user-feature-options";
import { UserRoleOptions } from "../../models/providers/user-context/user-role-options";
import { UserSubscriptionOptions } from "../../models/providers/user-context/user-subscription-options";
import { UserSubscriptionToStripeTrialOptions } from "../../models/providers/user-context/user-subscription-to-stripe-trial-options";

/**
 * User Service
 * */
export default class BdServiceUser extends BdServiceBase {
    private static ApiUrl = "/api/v1/users";

    /**
     * Returns user or list of users
     * @param searchField: user field to search for (i.e. email, emailgroup, datetime)
     */
    Search(searchField: string): Promise<UserPortalBean[]> {
        const fields = "rowkey,created,email,emailgroup,userrole,subscriptiontype,stripesubscriberplandescription,stripesubscriberplankey,subscriptionuserlimit,subscriptionuserlimitadjust,subscriptionuserlimitedit,subscriptioncaselimit,subscriptiondocumentlimit,subscriptionstatus,accountnumber,countrycode,company,livebundles,livedocuments,subscriptioncurrencycode,stripesubscriberkey,featurestatusadjust,featurestatusedit,lastactive,subscriptioncreated,subscriptionstatus,renewaldate,subscriptioncurrencycode,renewalfee,domain,browseruseragent,stripesubscriberKey";
        return super.Get(BdServiceUser.ApiUrl + "?$select=" + fields + searchField);
    }

    /**
     * Updates a user role
     * @param userRowKey: row key for user being updated
     * @param userRoleOptions: user role options
     */
    UpdateUserRole(userRowKey: string, userRoleOptions: UserRoleOptions): Promise<UserPortalBean> {
        return super.Put(`${BdServiceUser.ApiUrl}/${userRowKey}/role`, userRoleOptions);
    }

    /**
    * Updates a user feature status
    * @param userRowKey: row key for user being updated
    * @param userFeatureStatusAdjust: user feature status for adjust
    * @param userFeatureStatusEdit: user feature status for text editing
    */
    UpdateUserFeatureStatus(userRowKey: string, userFeatureOptions: UserFeatureOptions): Promise<UserPortalBean> {
        return super.Put(`${BdServiceUser.ApiUrl}/${userRowKey}/feature`, userFeatureOptions);
    }

    /**
     * Updates a user subscription
     * @param userRowKey: row key for user being updated
     * @param userSubscriptionOptions: user subscription options
     */
    UpdateUserSubscription(userRowKey: string, userSubscriptionOptions: UserSubscriptionOptions): Promise<UserSubscriptionOptions> {
        return super.Put(`${BdServiceUser.ApiUrl}/${userRowKey}/subscription`, userSubscriptionOptions);
    }

    /**
     * Returns the Subscription Capacity from a user.
     * @param userRowKey: user row key which will be used to return the subscription capacity.
     */
    GetUserSubscriptionCapacity(userRowKey: string): Promise<SubscriptionCapacity> {
        return super.Get(`${BdServiceUser.ApiUrl}/${userRowKey}/subscription/capacity`);
    }

    /**
     * Updates a user subscription to a Stripe trial
     * @param userRowKey: row key for user being updated
     * @param userSubscriptionToStripeTrialOptions: user subscription options
     */
    UpdateUserSubscriptionToStripeTrial(userRowKey: string, userSubscriptionToStripeTrialOptions: UserSubscriptionToStripeTrialOptions): Promise<UserPortalBean> {
        return super.Post(`${BdServiceUser.ApiUrl}/${userRowKey}/subscription/stripe/trial`, userSubscriptionToStripeTrialOptions);
    }

    /**
     * Updates a user subscription to a managed trial
     * @param userRowKey: row key for user being updated     
     */
    UpdateUserSubscriptionToManagedTrial(userRowKey: string, userSubscriptionOptions: UserSubscriptionOptions): Promise<UserSubscriptionOptions> {
        return super.Post(`${BdServiceUser.ApiUrl}/${userRowKey}/subscription/managed/trial`, userSubscriptionOptions);
    }

    /**
     * Returns the stripe account url, creating an account if needed
     * @param userRowKey: row key for user being updated
     * @param userSubscriptionOptions: user subscription options
     */
    async GetStripeAccountUrl(user: UserPortalBean): Promise<string> {
        let subscriberKey: string = user.StripeSubscriberKey;

        if (!subscriberKey) {
            const userResult: UserPortalBean = await super.Post(`${BdServiceUser.ApiUrl}/${user.RowKey}/subscription/stripe/account`);
            subscriberKey = userResult.StripeSubscriberKey;
        }

        return Promise.resolve("https://dashboard.stripe.com/customers/" + subscriberKey);
    }

    RemoveUserTOTP(userRowKey: string): Promise<UserPortalBean> {
        return super.Delete(`${BdServiceUser.ApiUrl}/${userRowKey}/totp`);
    }

    /**
     * Returns list of admin and owners for the user.
    * @param userRowKey: user row key which will be used.
    */
    GetUserAdminDetails(userRowKey: string): Promise<UserAdminDetailsBean[]> {
        return super.Get(`${BdServiceUser.ApiUrl}/${userRowKey}/admins`);
    }
}
