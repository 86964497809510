import { Button, Form, FormGroup, InputGroup } from "react-bootstrap";
import { useBdForm } from "./bd-form";
import { IFormFieldProps } from "./bd-form-constants";
import { BdFormErrorMessage } from "./bd-form-error-message";

/* Bootstrap 5 version */

/** text-box const definitions */
export const enum BdTextBoxType {
    Input = "input",
    Text = "text",
    Password = "password",
    Number = "number",
    Hidden = "hidden",
}

/** props */
export interface ITextBoxProps extends IFormFieldProps, React.HTMLAttributes<HTMLElement> {
    bdLabel?: string;
    bdTextBoxType?: BdTextBoxType;
    bdPlaceholder?: string;
    bdIcon?: JSX.Element;
    bdMin?: number;
    bdMax?: number;
    bdReadOnly?: boolean;
    bdShowTextarea?: boolean;
    bdRows?: number;
    onRightActionButtonClick?: (...args: any) => void;
    bdButtonIcon?: JSX.Element;
    onChange?: (...args: any) => void;
}

/**
 * Textbox consisting of standard Bundledocs parameters, with validation
 * @param props
  */
const BdTextBox = ({
    //specific properties
    bdLabel = "",
    bdTextBoxType = BdTextBoxType.Input,
    bdPlaceholder = "",
    bdIcon = undefined,
    bdMin,
    bdMax,
    bdReadOnly = false,
    bdShowTextarea = false,
    bdRows = 0,
    //form field properties
    name = "",
    bdValue,
    bdValidations,
    bdShowError = true,
    //other properties
    style,
    onRightActionButtonClick,
    bdButtonIcon,
    onChange,
    ...otherProperties
}: ITextBoxProps) => {
    //BdForm data if exists
    const { bdErrorMessage, bdTouched, register } = useBdForm(name, bdValue, bdValidations);

    const isInForm: boolean = !!register && !!name;
    const autoComplete: string = bdTextBoxType === BdTextBoxType.Password ? "new-password" : "off";

    const formProps = isInForm
        ? {
            isValid: bdTouched && !bdErrorMessage,
            isInvalid: bdTouched && bdErrorMessage,
            ...register?.(name)
        }
        : { value: bdValue };

    const _textAreaContent = (
        <Form.Control
            {...formProps}
            type={bdTextBoxType}
            min={bdMin}
            max={bdMax}
            placeholder={bdPlaceholder}
            autoComplete={autoComplete}
            readOnly={bdReadOnly}
            onChange={onChange}
            as="textarea"
            rows={bdRows}
            {...otherProperties}
        />
    );

    const _textBoxContent = (
        <Form.Control
            {...formProps}
            type={bdTextBoxType}
            min={bdMin}
            max={bdMax}
            placeholder={bdPlaceholder}
            autoComplete={autoComplete}
            readOnly={bdReadOnly}
            onChange={onChange}
            {...otherProperties}
        />
    );

    /** Main content */
    return (
        <FormGroup id={name} style={style}>
            {bdLabel && <Form.Label>{bdLabel}</Form.Label>}
            <InputGroup hasValidation={isInForm}>
                {bdIcon && <InputGroup.Text>{bdIcon}</InputGroup.Text>}
                {bdShowTextarea ? _textAreaContent : _textBoxContent}
                {bdButtonIcon && (
                    <Button variant="primary" onClick={onRightActionButtonClick}>{bdButtonIcon}</Button>
                )}
                <BdFormErrorMessage bdShowError={bdShowError} bdErrorMessage={bdErrorMessage} />
            </InputGroup>
        </FormGroup>
    );
};

export default BdTextBox;
