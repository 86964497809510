import { WorkData, WorkResponse } from "../../../modules/bd-models";
import { BdServiceBase } from "../bd-service-base";
import IBdWorkerService from "./interfaces/IBdWorkerService";

/**
 * Worker Service
 * */
export class BdWorkerService extends BdServiceBase implements IBdWorkerService {
    /**
     * Create work request - corresponding controller action must be a POST
     */
    public async CreateWorkRequest(url: string, createOptions?: any): Promise<WorkData> {
        return super.Post(url, createOptions);
    }

    /**
     * Watch work - corresponding controller action must be a GET
     */
    public async WatchWorkEvents<T>(url: string, successHandler?: (success?: T) => void, cancelHandler?: (cancel: boolean) => void, waitTime?: number) {
        const actionInterval = setInterval(async () => {
            const workResponse: WorkResponse<T> = await super.Get(url) as WorkResponse<T>;

            if (workResponse.IsComplete) {
                clearInterval(actionInterval);
                if (successHandler) {
                    successHandler(workResponse.Result);
                }
            }

            if (workResponse.IsCancelled) {
                clearInterval(actionInterval);
                if (cancelHandler) {
                    cancelHandler(true);
                }
            }
        }, waitTime ?? 3000);
    }

    /**
    * Cancel work request - corresponding controller action must be a DELETE
    */
    public async CancelWorkRequest(url: string) {
        await super.Delete(url);
    }
}
