import { Navbar } from "react-bootstrap";
import svgCollapseLogo from "../../assets/img/bundledocs-collapse-logo.svg";
import svgLogo from "../../assets/img/bundledocs-logo.svg";
import { BdTheme } from "./bd-layout-constants";
import BdLayoutHelper from "./bd-layout-helper";

export interface BdNavbarProps {
    bdExtended?: boolean,
    bdTheme?: BdTheme
}

export default function BdNavbar({
    bdExtended,
    bdTheme
}: BdNavbarProps): JSX.Element {
    return (
        <Navbar bg={BdLayoutHelper.BootstrapThemeVariant(bdTheme)}>
            <Navbar.Brand href="#" >
                <img src={bdExtended ? svgLogo : svgCollapseLogo} alt="Bundledocs Logo" className="d-inline-block align-top" />
            </Navbar.Brand>
        </Navbar>
    );
}
