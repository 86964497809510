import { UserGroupMember } from "../group-context/UserGroupMember";

export enum AnalystCacheStatus {
    NO_STATUS = 0,
    IN_QUEUE = 100,
    IN_PROGRESS = 200,
    COMPLETE = 300,
    READY_TO_DOWNLOAD = 301,
    FAILED = 400,
    CANCELLATION_REQUESTED = 500,
    CANCELLED = 600,
}
export function IsAnalystDatasetValid(dataset: AnalystDataset) {
    try {
        return new Date(dataset.Created).getFullYear() > 2000;
    }
    catch {
        return false;
    }
}
export enum AnalystDatasetType {
    GROUP = 10
}

export type AnalystDatasetResponseType = UserGroupMember[]; //add more types as needed: UserGroupMember[] | NewType1 | NewType2

export interface AnalystDataset {
    Response: AnalystDatasetResponseType;
    Created: string;
    Type: AnalystDatasetType;
    CurrentStatus: AnalystCacheStatus;
    PartitionKey: string;
    RowKey: string;
    ForeignKey: string;
}
