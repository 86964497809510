import { Activity, Brief, BriefCollaboratorCreateOptions, BriefDocument, BriefUpdateOptions, OAuth2ApiResponse, UserGroup } from "../../../modules/bd-models";
import BdEventsHelper, { BdDocumentEventTypes } from "../../helpers/bd-events-helper";
import { OAuth2ApiMessage } from "../../models/providers/api/OAuth2ApiMessage";
import { BriefStatisticsSubscriptionOptions } from "../../models/providers/domain/brief-context/BriefStatisticsSubscription";
import { BdApiResponse, BdServiceBase, BdServiceConfig } from "../bd-service-base";
import IBdServiceBundles from "./interfaces/IBdServiceBundles";
import IBdServiceEvents from "./interfaces/IBdServiceEvents";

/**
 * Brief Service
 * */
export class BdServiceBundles extends BdServiceBase implements IBdServiceBundles {
    private bdServiceEvents: IBdServiceEvents;

    constructor(configuration: BdServiceConfig, bdServiceEvents: IBdServiceEvents) {
        super(configuration);
        this.bdServiceEvents = bdServiceEvents;
    }

    private static ApiUrl = "/api/v1/bundles";
    
    GetBundles(options?: { skip?: number, top?: number }): Promise<BdApiResponse<Brief[]>> {
        return super.Get(`${BdServiceBundles.ApiUrl}?$skip=${options?.skip ?? 0}&$top=${options?.top ?? 0}`);
    }

    GetActivities(brief: Brief): Promise<BdApiResponse<Activity[]>> {
        return super.Get(`${BdServiceBundles.ApiUrl}/${brief.PartitionKey}/${brief.RowKey}/activitys`);
    }

    async Create(brief: Brief): Promise<BdApiResponse<Brief>> {
        return super.Post(`${BdServiceBundles.ApiUrl}`, brief);
    }

    async GetCollaborators(brief: Brief): Promise<OAuth2ApiResponse<BriefCollaboratorCreateOptions[]>> {
        return super.Get(`${BdServiceBundles.ApiUrl}/${brief.PartitionKey}/${brief.RowKey}/collaborators`);
    }

    async CreateCollaborator(brief: Brief, collaboratorOptionsBean: BriefCollaboratorCreateOptions): Promise<OAuth2ApiResponse<any>> {
        return super.Post(`${BdServiceBundles.ApiUrl}/${brief.PartitionKey}/${brief.RowKey}/collaborators`, collaboratorOptionsBean);
    }

    async DeleteCollaborator(brief: Brief, partitionKey: string): Promise<OAuth2ApiResponse<any>> {
        return super.Delete(`${BdServiceBundles.ApiUrl}/${brief.PartitionKey}/${brief.RowKey}/collaborators/${partitionKey}`);
    }

    async LoadBundle(partitionKey: string, rowKey: string): Promise<OAuth2ApiResponse<any>> {
        return super.Get(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/?collapse[]=brief.briefdocuments`);
    }

    async GenerateBundle(partitionKey: string, rowKey: string, bundle: any): Promise<OAuth2ApiResponse<any>> {
        //TODO Sprint 9: Remove ApplyBatch
        return super.Put(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/generate`, { ...bundle, ApplyBatch: true });
    }

    async CreateMigration(briefPartitionKey: string, briefRowKey: string, email: string, expiryDate: Date): Promise<OAuth2ApiResponse<any>> {
        return super.Post(`${BdServiceBundles.ApiUrl}/${briefPartitionKey}/${briefRowKey}/migrations`, { Email: email, ExpiryDate: expiryDate });
    }

    async LoadMigration(partitionKey: string, rowKey: string): Promise<OAuth2ApiResponse<any>> {
        return super.Get(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/migrations`);
    }

    async DeleteMigration(briefPartitionKey: string, briefRowKey: string, migrationRowKey: string): Promise<OAuth2ApiResponse<any>> {
        return super.Delete(`${BdServiceBundles.ApiUrl}/${briefPartitionKey}/${briefRowKey}/migrations/${migrationRowKey}`);
    }

    async CreateActivitySubscription(partitionKey: string, rowKey: string, briefStatisticsSubscriptionOptions: BriefStatisticsSubscriptionOptions): Promise<OAuth2ApiResponse<any>> {
        return super.Post(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/activity-subscriptions`, briefStatisticsSubscriptionOptions);
    }

    async GetActivitySubscription(partitionKey: string, rowKey: string): Promise<OAuth2ApiResponse<any>> {
        return super.Get(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/activity-subscriptions`);
    }

    async DeleteActivitySubscription(partitionKey: string, rowKey: string, subscriptionRowKey: string): Promise<OAuth2ApiResponse<any>> {
        return super.Delete(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/activity-subscriptions/${subscriptionRowKey}`);
    }

    async UpdateActivitySubscription(partitionKey: string, rowKey: string, subscriptionRowKey: string, briefStatisticsSubscriptionOptions: BriefStatisticsSubscriptionOptions): Promise<OAuth2ApiResponse<any>> {
        return super.Put(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/activity-subscriptions/${subscriptionRowKey}`, briefStatisticsSubscriptionOptions);
    }

    async DeleteBundle(partitionKey: string, rowKey: string): Promise<OAuth2ApiResponse<any>> {
        return super.Delete(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}`);
    }

    async CopyBundle(partitionKey: string, rowKey: string): Promise<OAuth2ApiResponse<any>> {
        return super.Post(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/copy`);
    }

    async UpdateBundle(partitionKey: string, rowKey: string, brief: BriefUpdateOptions): Promise<OAuth2ApiResponse<any>> {
        return super.Put(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/`, brief);
    }

    async SealBundle(partitionKey: string, rowKey: string): Promise<OAuth2ApiResponse<any>> {
        return super.Post(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/seal`);
    }

    async CreateVisibleToGroup(partitionKey: string, rowKey: string, userGroup: UserGroup): Promise<OAuth2ApiResponse<any>> {
        return super.Post(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/visibletogroups`, userGroup);
    }

    async DeleteVisibleToGroup(partitionKey: string, rowKey: string, userGroupRowKey: string): Promise<OAuth2ApiResponse<any>> {
        return super.Delete(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/visibletogroups/${userGroupRowKey}`);
    }

    async GetBundleStamps(partitionKey: string, rowKey: string): Promise<BdApiResponse<BriefDocument[]>> {
        return super.Get(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/stamps`);
    }

    async UploadStampImage(partitionKey: string, rowKey: string, file: File,
        beginHandler?: (begin?: any) => void,
        successHandler?: (success?: any) => void,
        failureHandler?: (failure?: any) => void
    ): Promise<BdApiResponse<BriefDocument>> {
        const uploadResponse: BdApiResponse<BriefDocument> = await super.Upload(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/stamps/static`, file);

        if (uploadResponse.data[0]) {
            if (this.bdServiceEvents && (beginHandler || successHandler || failureHandler)) {
                BdEventsHelper.startDocumentEventsListener(this.bdServiceEvents, uploadResponse.data[0].ForeignKey!, uploadResponse.data[0].FileUploadBatch!,
                    BdDocumentEventTypes.PROCESS, beginHandler, successHandler, failureHandler
                );
            }
        }

        return uploadResponse;
    }

    async DeleteStampImage(bundlePartitionKey: string, bundleRowKey: string, stampPartitionKey: string, stampRowKey: string): Promise<BdApiResponse<BriefDocument>> {
        return super.Delete(`${BdServiceBundles.ApiUrl}/${bundlePartitionKey}/${bundleRowKey}/stamps/${stampPartitionKey}/${stampRowKey}`);
    }

    async GetStampTemplates(partitionKey: string, rowKey: string): Promise<BdApiResponse<BriefDocument[]>> {
        return super.Get(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/stamps/templates`);
    }

    async UploadStampTemplate(partitionKey: string, rowKey: string, file: File,
        beginHandler?: (begin?: any) => void,
        successHandler?: (success?: any) => void,
        failureHandler?: (failure?: any) => void
    ): Promise<BdApiResponse<BriefDocument>> {
        const uploadResponse: BdApiResponse<BriefDocument> = await super.Upload(`${BdServiceBundles.ApiUrl}/${partitionKey}/${rowKey}/stamps/templates`, file);

        if (uploadResponse.data[0]) {
            if (this.bdServiceEvents && (beginHandler || successHandler || failureHandler)) {
                BdEventsHelper.startDocumentEventsListener(this.bdServiceEvents, uploadResponse.data[0].ForeignKey!, uploadResponse.data[0].FileUploadBatch!,
                    BdDocumentEventTypes.PROCESS, beginHandler, successHandler, failureHandler
                );
            }
        }

        return uploadResponse;
    }

    async DeleteStampTemplate(bundlePartitionKey: string, bundleRowKey: string, stampPartitionKey: string, stampRowKey: string): Promise<BdApiResponse<BriefDocument>> {
        return super.Delete(`${BdServiceBundles.ApiUrl}/${bundlePartitionKey}/${bundleRowKey}/stamps/templates/${stampPartitionKey}/${stampRowKey}`);
    }

    async CancelGenerate(bundlePartitionKey: string, bundleRowKey: string, batch: string | null = null): Promise<OAuth2ApiResponse<OAuth2ApiMessage>> {
        return super.Delete(`${BdServiceBundles.ApiUrl}/${bundlePartitionKey}/${bundleRowKey}/generate/${batch ?? ""}`);
    }
}
