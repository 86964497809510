import { BdServiceBase, BdServiceConfig } from "../bd-service-base";
import IBdServiceSignalR from "./interfaces/IBdServiceSignalR";

/**
 * Application Service
 * */
export class BdServiceSignalR extends BdServiceBase implements IBdServiceSignalR {
    private static ApiUrl = "/api/v1/hubs";

    constructor(configuration: BdServiceConfig) {
        super(configuration);
    }

    StartHub(hubId: string, hubName: string, connectionId: string): Promise<void> {
        return super.Post(`${BdServiceSignalR.ApiUrl}/${hubId}/${hubName}/connections/${connectionId}`);
    }

    JoinHub(hubId: string, hubName: string, partitionKey: string, rowKey: string): Promise<void> {
        return super.Post(`${BdServiceSignalR.ApiUrl}/${hubId}/${hubName}/${partitionKey}/${rowKey}`);
    }

    LeaveHub(hubId: string, hubName: string, partitionKey: string, rowKey: string): Promise<void> {
        return super.Delete(`${BdServiceSignalR.ApiUrl}/${hubId}/${hubName}/${partitionKey}/${rowKey}`);
    }

    GetBearerProofToken(): Promise<any> {
        return super.Post(`${BdServiceSignalR.ApiUrl}/token`);
    }
}
