import { BdEnumArray } from "bundledocs.common.client/modules/bd-models";

export const UserRolesPortal: BdEnumArray = [
    { name: "APPLICATION_USER", code: 0, description: "Application User", icon: "fa fa-users text-default" },
    { name: "FEDERATED_USER", code: 3, description: "Federated User", icon: "fa fa-users text-default" },
    { name: "APPLICATION_DEVELOPER", code: 2, description: "Application Developer", icon: "fa fa-users text-default" }
];

export const UserSubscriptionType: BdEnumArray = [
    { name: "FREE", code: 0, description: "0 : FREE", icon: "fa fa-users text-default" },
    { name: "GROUP", code: 6, description: "6 : GROUP", icon: "fa fa-users text-default" },
    { name: "MANAGED", code: 100, description: "100 : MANAGED", icon: "fa fa-users text-default" },
    { name: "MANAGED_GROUP", code: 200, description: "200 : MANAGED_GROUP", icon: "fa fa-users text-default" },
    { name: "BASIC_V3", code: 1001, description: "1001 : BASIC_V3", icon: "fa fa-users text-default" },
    { name: "PROFESSIONAL_V3", code: 2001, description: "2001 : PROFESSIONAL_V3", icon: "fa fa-users text-default" },
    { name: "ULTIMATE_V3", code: 3001, description: "3001 : ULTIMATE_V3", icon: "fa fa-users text-default" },
    { name: "TRIAL_EXPIRED", code: 9, description: "9 : TRIAL_EXPIRED", icon: "fa fa-users text-default" },
    { name: "(legacy) BASIC", code: 1, description: "1 : (legacy) BASIC", icon: "fa fa-users text-default" },
    { name: "(legacy) PROFESSIONAL", code: 2, description: "2 : (legacy) PROFESSIONAL", icon: "fa fa-users text-default" },
    { name: "(legacy) ULTIMATE", code: 3, description: "3 : (legacy) ULTIMATE", icon: "fa fa-users text-default" },
    { name: "(legacy) PERFORMANCE", code: 5, description: "5 : (legacy) PERFORMANCE", icon: "fa fa-users text-default" },
    { name: "(legacy) CORPORATE", code: 7, description: "7 : (legacy) CORPORATE", icon: "fa fa-users text-default" },
    { name: "(legacy) CORPORATE_GROUP", code: 8, description: "8 : (legacy) CORPORATE_GROUP", icon: "fa fa-users text-default" },
    { name: "(legacy) BASIC_V2", code: 1000, description: "1000 : (legacy) BASIC_V2", icon: "fa fa-users text-default" },
    { name: "(legacy) PROFESSIONAL_V2", code: 2000, description: "2000 : (legacy) PROFESSIONAL_V2", icon: "fa fa-users text-default" },
    { name: "(legacy) ULTIMATE_V2", code: 3000, description: "3000 : (legacy) ULTIMATE_V2", icon: "fa fa-users text-default" }
];

export const UserSubscriptionStatus: BdEnumArray = [
    { name: "ACTIVE", code: 0, description: "0 : ACTIVE", icon: "fa fa-users text-default" },
    { name: "INACTIVE", code: 1, description: "1 : INACTIVE", icon: "fa fa-users text-default" },
    { name: "SUSPENDED", code: 2, description: "2 : SUSPENDED", icon: "fa fa-users text-default" },
    { name: "EXPIRED", code: 3, description: "3 : EXPIRED", icon: "fa fa-users text-default" },
    { name: "LOCKED", code: 4, description: "4 : LOCKED", icon: "fa fa-users text-default" }
];

export const UserFeatureStatus: BdEnumArray = [
    { name: "DISABLED", code: 0, description: "DISABLED", icon: "fa fa-users text-default" },
    { name: "DISABLED_ALLOW_ENABLE", code: 100, description: "DISABLED_ALLOW_ENABLE", icon: "fa fa-users text-default" },
    { name: "ENABLED_ALLOW_DISABLE", code: 200, description: "ENABLED_ALLOW_DISABLE", icon: "fa fa-users text-default" },
    { name: "ENABLED", code: 300, description: "ENABLED", icon: "fa fa-users text-default" }
];

export const Currency: BdEnumArray = [
    { name: "EUR", code: "EUR", description: "EUR", icon: "fa fa-users text-default" },
    { name: "GBP", code: "GBP", description: "GBP", icon: "fa fa-users text-default" },
    { name: "USD", code: "USD", description: "USD", icon: "fa fa-users text-default" },
    { name: "AUD", code: "AUD", description: "AUD", icon: "fa fa-users text-default" }
];

export const Country: BdEnumArray = [
    { name: "IE", code: "IE", description: "IE", icon: "fa fa-users text-default" },
    { name: "GB", code: "GB", description: "GB", icon: "fa fa-users text-default" },
    { name: "US", code: "US", description: "US", icon: "fa fa-users text-default" },
    { name: "AU", code: "AU", description: "AU", icon: "fa fa-users text-default" }
];

export const Category: BdEnumArray = [
    { name: "GEN", code: "30", description: "General", icon: "fa fa-users text-default" },
    { name: "FEAT", code: "40", description: "Feature", icon: "fa fa-users text-default" }
];

export const AccountSubscriptionType: BdEnumArray = [
    { name: "FREE", code: 0, description: "0 : FREE", icon: "fa fa-users text-default" },
    { name: "PROFESSIONAL", code: 2, description: "2 : PROFESSIONAL", icon: "fa fa-users text-default" },
    { name: "MANAGED", code: 100, description: "100 : MANAGED", icon: "fa fa-users text-default" },
];
