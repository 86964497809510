import { BdCol, BdGrid, BdRow, BdTile, useBdToast } from "bundledocs.common.client/modules/bd-components";
import { WorkData } from "bundledocs.common.client/modules/bd-models";
import React, { useRef, useState } from "react";
import { DashboardReportBean } from "../../models/providers/bean/bd-reports/dashboard-report-bean";
import BundledocsServicesProvider from "../../providers/bundledocs-services-provider";
import { BdBundledocsPortalApi } from "../../services/portal-api/bd-bundledocs-portal-api";
import ManagedUsersReport from "./managed-users-report.svg";
import UserCapacityReport from "./user-capacity-report.svg";

export type BdReportsListProps = React.HTMLAttributes<HTMLElement>

/**
 * Component that shows all dashboard reports 
 * @param param0
 */
export default function BdReportsList({
    ...otherProps
}: BdReportsListProps): JSX.Element {
    //toast functions
    const { toastSuccess, toastError } = useBdToast();
    const [loadingUserCapacity, setLoadingUserCapacity] = useState<boolean>(false);
    const [loadingManagedUsers, setLoadingManagedUsers] = useState<boolean>(false);

    const workData = useRef<WorkData>();

    const _bundledocsApiInstance: BdBundledocsPortalApi = BundledocsServicesProvider.BundledocsPortalApiInstance();

    const _handleDownloadReport = (report: DashboardReportBean) => {
        // download the file from the response and allow the user to open it
        const url = report.ReportDataUrl;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", report.ReportName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    /** get user capacity report data */
    const _handleUserCapacityReportClick = async () => {
        try {
            setLoadingUserCapacity(true);

            //initiate report generation
            workData.current = await _bundledocsApiInstance.Reports.CreateUserCapacityReport();

            //watch for completion of report generation, when ready: download the file and allow the user to open it
            await _bundledocsApiInstance.Reports.WatchReportEvents(workData?.current.WorkId,
                (successResponse: DashboardReportBean) => {
                    _handleDownloadReport(successResponse);
                    toastSuccess("Download of User Capacity report successful");
                    setLoadingUserCapacity(false);
                    return true;
                },
                (cancel: boolean) => {
                    setLoadingUserCapacity(false);
                    toastSuccess("Download of User Capacity report cancelled");
                    return true;
                }
            );
        } catch (error) {
            toastError("Error generating User Capacity report");
            return false;
        }
    };

    const _handleUserCapacityReportCancel = async () => {
        if (workData.current) {
            await _bundledocsApiInstance.Reports.CancelReport(workData.current.WorkId);
        }
    };


    /** get managed users report data */
    const _handleManagedUsersReportClick = async () => {
        try {
            setLoadingManagedUsers(true);

            //initiate report generation
            workData.current = await _bundledocsApiInstance.Reports.CreateManagedUsersReport();

            //watch for completion of report generation, when ready: download the file and allow the user to open it
            await _bundledocsApiInstance.Reports.WatchReportEvents(workData?.current.WorkId,
                (successResponse: DashboardReportBean) => {
                    _handleDownloadReport(successResponse);
                    toastSuccess("Download of Managed Users report successful");
                    setLoadingManagedUsers(false);
                    return true;
                },
                (cancel: boolean) => {
                    setLoadingManagedUsers(false);
                    return true;
                }
            );
        } catch (error) {
            toastError("Error generating Managed Users report");
            return false;
        }
    };

    const _handleManagedUsersReportCancel = async () => {
        if (workData.current) {
            await _bundledocsApiInstance.Reports.CancelReport(workData.current.WorkId);
        }
    };

    return (
        <BdGrid {...otherProps}>
            <BdRow>
                <BdCol xl={3} lg={4} md={12} sm={12}>
                    <BdTile bdSource={UserCapacityReport} bdTitle="Users Capacity Report" onClick={_handleUserCapacityReportClick} bdWidth="17rem" bdFontSize="1rem" bdLoading={loadingUserCapacity} bdCancel={_handleUserCapacityReportCancel}></BdTile>
                </BdCol>
                <BdCol xl={3} lg={4} md={12} sm={12}>
                    <BdTile bdSource={ManagedUsersReport} bdTitle="Managed Users Report" onClick={_handleManagedUsersReportClick} bdWidth="17rem" bdFontSize="1rem" bdLoading={loadingManagedUsers} bdCancel={_handleManagedUsersReportCancel}></BdTile>
                </BdCol>
            </BdRow>
        </BdGrid>
    );
}
