import * as React from "react";
import {
    BsArrowRepeat, BsBell, BsBookmark, BsBookmarksFill, BsCircleFill, BsClipboard, BsClock, BsClockFill, BsClockHistory,
    BsCloudFill,
    BsDownload,
    BsEmojiFrown,
    BsEnvelope,
    BsFileEarmarkArrowUp,
    BsFileEarmarkBreak,
    BsFileEarmarkLock2,
    BsFileEarmarkPlus, BsFileEarmarkPlusFill, BsFilePerson, BsFillCaretDownFill, BsFillCheckCircleFill, BsFillFileLock2Fill,
    BsFillFileLockFill,
    BsFillSkipEndFill,
    BsFillSkipStartFill, BsFillXCircleFill, BsFolderPlus,
    BsGear,
    BsGrid,
    BsImage,
    BsInfoCircle, BsListColumnsReverse, BsListTask,
    BsLock,
    BsPencil,
    BsPencilFill,
    BsPerson, BsPersonBadgeFill, BsPersonFillAdd,
    BsPersonFillCheck,
    BsPersonFillDash,
    BsPersonFillDown,
    BsPersonFillExclamation,
    BsPersonFillGear,
    BsPersonFillSlash,
    BsPersonFillUp, BsPlay,
    BsReceipt,
    BsReception4,
    BsSearch,
    BsSendCheckFill,
    BsSlashCircle, BsSlashCircleFill, BsSortAlphaDown,
    BsSortAlphaDownAlt,
    BsSortDown,
    BsSortDownAlt,
    BsSortNumericDown,
    BsSortNumericDownAlt,
    BsStripe,
    BsTagsFill,
    BsThreeDotsVertical,
    BsTrash,
    BsTrashFill,
    BsTypeStrikethrough,
    BsUnlock,
    BsXCircle,
    BsXLg
} from "react-icons/bs";
import { FaEnvelope, FaHandshake, FaLocationArrow, FaPaperPlane, FaRegCircle, FaRegDotCircle, FaStripe, FaUserEdit, FaUsers, FaUser } from "react-icons/fa";
import { FcAdvertising, FcComboChart, FcIdea, FcInvite } from "react-icons/fc";
import { FiChevronsLeft, FiChevronsRight, FiExternalLink } from "react-icons/fi";
import { GoChevronDown, GoChevronRight } from "react-icons/go";
import { ImArrowDown, ImArrowUp, ImFileWord, ImPlus, ImSpinner8 } from "react-icons/im";
import { IoArrowRedoSharp, IoCalendarOutline, IoClose, IoDocumentsOutline, IoSaveOutline, IoShieldHalfSharp, IoCopyOutline } from "react-icons/io5";
import { IconBaseProps } from "react-icons/lib";
import {
    MdAdminPanelSettings, MdBorderColor,
    MdCheck, MdCheckBoxOutlineBlank,
    MdContentPasteGo, MdEmail, MdExpandLess, MdExpandMore, MdHighlight,
    MdHistory,
    MdLabel,
    MdNoteAlt,
    MdOutlineAddChart,
    MdOutlineEditNote,
    MdOutlineRemoveModerator,
    MdPictureAsPdf,
    MdShield
} from "react-icons/md";
import { TbBrandStripe, TbInfinity, TbInfinityOff, TbReportAnalytics } from "react-icons/tb";
import { VscEye, VscMerge } from "react-icons/vsc";

export type BdIconProps = IconBaseProps;

/**
 * Icons wrapper. All icons used in new projects should be defined here
 * @param param0
 */
export const BdIconUser = ({ ...props }: BdIconProps) => { return <BsPerson {...props} />; };
export const BdIconUserFeatureEdit = ({ ...props }: BdIconProps) => { return <FaUserEdit  {...props} />; };
export const BdIconPassword = ({ ...props }: BdIconProps) => { return <BsLock {...props} />; };
export const BdIconSearch = ({ ...props }: BdIconProps) => { return <BsSearch {...props} />; };
export const BdIconDisappointed = ({ ...props }: BdIconProps) => { return <BsEmojiFrown {...props} />; };
export const BdIconUploadFiles = ({ ...props }: BdIconProps) => { return <BsFileEarmarkArrowUp {...props} />; };
export const BdIconAdd = ({ ...props }: BdIconProps) => { return <ImPlus {...props} />; };
export const BdIconEdit = ({ ...props }: BdIconProps) => { return <BsPencil {...props} />; };
export const BdIconDelete = ({ ...props }: BdIconProps) => { return <BsTrash {...props} />; };
export const BdIconRefresh = ({ ...props }: BdIconProps) => { return <BsArrowRepeat {...props} />; };
export const BdIconSortTextAsc = ({ ...props }: BdIconProps) => { return <BsSortAlphaDown {...props} />; };
export const BdIconSortTextDesc = ({ ...props }: BdIconProps) => { return <BsSortAlphaDownAlt {...props} />; };
export const BdIconSortNumberAsc = ({ ...props }: BdIconProps) => { return <BsSortNumericDown {...props} />; };
export const BdIconSortNumberDesc = ({ ...props }: BdIconProps) => { return <BsSortNumericDownAlt {...props} />; };
export const BdIconSortAsc = ({ ...props }: BdIconProps) => { return <BsSortDown {...props} />; };
export const BdIconSortDesc = ({ ...props }: BdIconProps) => { return <BsSortDownAlt {...props} />; };
export const BdIconOptions = ({ ...props }: BdIconProps) => { return <BsThreeDotsVertical {...props} />; };
export const BdIconInfo = ({ ...props }: BdIconProps) => { return <BsInfoCircle {...props} />; };
export const BdIconPrevStart = ({ ...props }: BdIconProps) => { return <BsFillSkipStartFill {...props} />; };
export const BdIconNextEnd = ({ ...props }: BdIconProps) => { return <BsFillSkipEndFill {...props} />; };
export const BdIconCancel = ({ ...props }: BdIconProps) => { return <BsXCircle {...props} />; };
export const BdIconDocumentInclude = ({ ...props }: BdIconProps) => { return <BsFolderPlus {...props} />; };
export const BdIconDocumentStrikeOut = ({ ...props }: BdIconProps) => { return <BsTypeStrikethrough {...props} />; };
export const BdIconDocumentHide = ({ ...props }: BdIconProps) => { return <BsSlashCircle {...props} />; };
export const BdIconDocumentLateInsert = ({ ...props }: BdIconProps) => { return <BsClockHistory {...props} />; };
export const BdIconDocumentSimpleInsert = ({ ...props }: BdIconProps) => { return <BsFileEarmarkPlus {...props} />; };
export const BdIconDocumentBookmark = ({ ...props }: BdIconProps) => { return <BsBookmark {...props} />; };
export const BdIconDocumentHideFill = ({ ...props }: BdIconProps) => { return <BsSlashCircleFill {...props} />; };
export const BdIconDocumentLateInsertFill = ({ ...props }: BdIconProps) => { return <BsClockFill {...props} />; };
export const BdIconDocumentSimpleInsertFill = ({ ...props }: BdIconProps) => { return <BsFileEarmarkPlusFill {...props} />; };
export const BdIconDocumentBookmarkFill = ({ ...props }: BdIconProps) => { return <BsBookmarksFill {...props} />; };
export const BdIconDocumentLock = ({ ...props }: BdIconProps) => { return <BsFileEarmarkLock2 {...props} />; };
export const BdIconDocumentSplitPoint = ({ ...props }: BdIconProps) => { return <BsFileEarmarkBreak {...props} />; };
export const BdIconDocumentRemovePrevious = ({ ...props }: BdIconProps) => { return <BsReception4 {...props} />; };
export const BdIconDocumentReset = ({ ...props }: BdIconProps) => { return <BsArrowRepeat {...props} />; };
export const BdIconItemSelected = ({ ...props }: BdIconProps) => { return <FaRegDotCircle {...props} />; };
export const BdIconItemUnselected = ({ ...props }: BdIconProps) => { return <FaRegCircle {...props} />; };
export const BdIconActionMerge = ({ ...props }: BdIconProps) => { return <VscMerge {...props} />; };
export const BdIconActionWordAlt = ({ ...props }: BdIconProps) => { return <ImFileWord {...props} />; };
export const BdIconGrid = ({ ...props }: BdIconProps) => { return <BsGrid {...props} />; };
export const BdIconList = ({ ...props }: BdIconProps) => { return <BsListTask {...props} />; };
export const BdIconForward = ({ ...props }: BdIconProps) => { return <BsEnvelope {...props} />; };
export const BdIconSettings = ({ ...props }: BdIconProps) => { return <BsGear {...props} />; };
export const BdIconCaretDown = ({ ...props }: BdIconProps) => { return <BsFillCaretDownFill {...props} />; };
export const BdIconUnlock = ({ ...props }: BdIconProps) => { return <BsUnlock {...props} />; };
export const BdIconEditGlyph = ({ ...props }: BdIconProps) => { return <i className="glyphicon glyphicon-edit" />; };
export const BdIconActionCreate = ({ ...props }: BdIconProps) => { return <ImArrowUp {...props} />; };
export const BdIconActionConvert = ({ ...props }: BdIconProps) => { return <MdPictureAsPdf {...props} />; };
export const BdIconActionUpdate = ({ ...props }: BdIconProps) => { return <BsPencilFill {...props} />; };
export const BdIconActionDelete = ({ ...props }: BdIconProps) => { return <BsTrashFill {...props} />; };
export const BdIconActionRead = ({ ...props }: BdIconProps) => { return <ImArrowDown {...props} />; };
export const BdIconActivity = ({ ...props }: BdIconProps) => { return <MdHistory {...props} />; };
export const BdIconStripeTrial = ({ ...props }: BdIconProps) => { return <FaStripe {...props} />; };
export const BdIconStripeProfile = ({ ...props }: BdIconProps) => { return <FiExternalLink {...props} />; };
export const BdIconWordAlt = ({ ...props }: BdIconProps) => { return <ImFileWord {...props} />; };
export const BdIconReceipt = ({ ...props }: BdIconProps) => { return <BsReceipt {...props} />; };
export const BdIconDisableAuthorization = ({ ...props }: BdIconProps) => { return <MdOutlineRemoveModerator {...props} />; };
export const BdIconReferenceDocument = ({ ...props }: BdIconProps) => { return <IoArrowRedoSharp {...props} />; };
export const BdIconEditNote = ({ ...props }: BdIconProps) => { return <MdOutlineEditNote {...props} />; };
export const BdIconCalendar = ({ ...props }: BdIconProps) => { return <IoCalendarOutline  {...props} />; };
export const BdIconPanelExpand = ({ ...props }: BdIconProps) => { return <GoChevronRight {...props} />; };
export const BdIconPanelCollapse = ({ ...props }: BdIconProps) => { return <GoChevronDown {...props} />; };
export const BdIconSidebarExpand = ({ ...props }: BdIconProps) => { return <FiChevronsRight {...props} />; };
export const BdIconSidebarCollapse = ({ ...props }: BdIconProps) => { return <FiChevronsLeft {...props} />; };
export const BdIconPersonFillUp = ({ ...props }: BdIconProps) => { return <BsPersonFillUp {...props} />; };
export const BdIconPersonFillDown = ({ ...props }: BdIconProps) => { return <BsPersonFillDown {...props} />; };
export const BdIconPersonFillCheck = ({ ...props }: BdIconProps) => { return <BsPersonFillCheck {...props} />; };
export const BdIconPersonBadgeFill = ({ ...props }: BdIconProps) => { return <BsPersonBadgeFill {...props} />; };
export const BdIconFillFileLockFill = ({ ...props }: BdIconProps) => { return <BsFillFileLockFill {...props} />; };
export const BdIconFillFileLock2Fill = ({ ...props }: BdIconProps) => { return <BsFillFileLock2Fill {...props} />; };
export const BdIconPersonFillSlash = ({ ...props }: BdIconProps) => { return <BsPersonFillSlash {...props} />; };
export const BdIconPersonFillGear = ({ ...props }: BdIconProps) => { return <BsPersonFillGear {...props} />; };
export const BdIconPersonFillExclamation = ({ ...props }: BdIconProps) => { return <BsPersonFillExclamation {...props} />; };
export const BdIconPersonFillAdd = ({ ...props }: BdIconProps) => { return <BsPersonFillAdd {...props} />; };
export const BdIconPersonFillDash = ({ ...props }: BdIconProps) => { return <BsPersonFillDash {...props} />; };
export const BdIconSendCheckFill = ({ ...props }: BdIconProps) => { return <BsSendCheckFill {...props} />; };
export const BdIconIoList = ({ ...props }: BdIconProps) => { return <MdOutlineAddChart {...props} />; };
export const BdIconImage = ({ ...props }: BdIconProps) => { return <BsImage {...props} />; };
export const BdIconCloud = ({ ...props }: BdIconProps) => { return <BsCloudFill {...props} />; };
export const BdIconRevoke = ({ ...props }: BdIconProps) => { return <BsXLg {...props} />; };
export const BdIconStripe = ({ ...props }: BdIconProps) => { return <BsStripe {...props} />; };
export const BdIconSpinner = ({ style, ...props }: BdIconProps) => { return <ImSpinner8 style={{ ...style, animation: "spin 2s infinite linear" }} {...props} />; };
export const BdIconAccountNumbers = ({ ...props }: BdIconProps) => { return <BsListColumnsReverse {...props} />; };
export const BdIconClock = ({ ...props }: BdIconProps) => { return <BsClock {...props} />; };
export const BdIconEmail = ({ ...props }: BdIconProps) => { return <MdEmail {...props} />; };
export const BdIconClose = ({ ...props }: BdIconProps) => { return <IoClose {...props} />; };
export const BdIconInfinity = ({ ...props }: BdIconProps) => { return <TbInfinity {...props} />; };
export const BdIconInfinityOff = ({ ...props }: BdIconProps) => { return <TbInfinityOff {...props} />; };
export const BdIconRedact = ({ ...props }: BdIconProps) => { return <MdBorderColor {...props} />; };
export const BdIconHighlight = ({ ...props }: BdIconProps) => { return <MdHighlight {...props} />; };
export const BdIconLabel = ({ ...props }: BdIconProps) => { return <MdLabel {...props} />; };
export const BdIconNote = ({ ...props }: BdIconProps) => { return <MdNoteAlt {...props} />; };
export const BdIconPrivateNote = ({ ...props }: BdIconProps) => { return <MdShield {...props} />; };
export const BdIconReports = ({ ...props }: BdIconProps) => { return <TbReportAnalytics {...props} />; };
export const BdIconSeal = ({ ...props }: BdIconProps) => { return <IoShieldHalfSharp {...props} />; };
export const BdIconCheck = ({ ...props }: BdIconProps) => { return <MdCheck {...props} />; };
export const BdIconFollow = ({ ...props }: BdIconProps) => { return <VscEye {...props} />; };
export const BdIconNotification = ({ ...props }: BdIconProps) => { return <BsBell {...props} />; };
export const BdIconStateNew = ({ ...props }: BdIconProps) => { return <BsCircleFill {...props} />; };
export const BdIconStateSuccess = ({ ...props }: BdIconProps) => { return <BsFillCheckCircleFill {...props} />; };
export const BdIconStateError = ({ ...props }: BdIconProps) => { return <BsFillXCircleFill {...props} />; };
export const BdIconRun = ({ ...props }: BdIconProps) => { return <BsPlay {...props} />; };
export const BdIconDownload = ({ ...props }: BdIconProps) => { return <BsDownload {...props} />; };
export const BdIconSave = ({ ...props }: BdIconProps) => { return <IoSaveOutline {...props} />; };
export const BdAdminIcon = ({ ...props }: BdIconProps) => { return <BsFilePerson {...props} />; };
export const BdCopyClipboardIcon = ({ ...props }: BdIconProps) => { return <BsClipboard {...props} />; };
export const BdIconColorAnalyst = ({ ...props }: BdIconProps) => { return <FcComboChart {...props} />; };
export const BdIconStripePlan = ({ ...props }: BdIconProps) => { return <TbBrandStripe {...props} />; };
export const BdIconSystemTags = ({ ...props }: BdIconProps) => { return <BsTagsFill {...props} />; };
export const BdIconLiveBundles = ({ ...props }: BdIconProps) => { return <IoDocumentsOutline  {...props} />; };
export const BdIconGeneralNotification = ({ ...props }: BdIconProps) => { return <FcIdea {...props} />; };
export const BdIconFeatureNotification = ({ ...props }: BdIconProps) => { return <FcAdvertising {...props} />; };
export const BdIconExpand = ({ ...props }: BdIconProps) => { return <MdExpandMore {...props} />; };
export const BdIconCollapse = ({ ...props }: BdIconProps) => { return <MdExpandLess {...props} />; };
export const BdIconInvited = ({ ...props }: BdIconProps) => { return <FcInvite {...props} />; };
export const BdIconAdmin = ({ ...props }: BdIconProps) => { return <MdAdminPanelSettings {...props} />; };
export const BdIconCheckBox = ({ ...props }: BdIconProps) => { return <MdCheckBoxOutlineBlank {...props} />; };
export const BdIconContentPaste = ({ ...props }: BdIconProps) => { return <MdContentPasteGo  {...props} />; };
export const BdIconUsers = ({ ...props }: BdIconProps) => { return <FaUsers  {...props} />; };
export const BdIconInvite = ({ ...props }: BdIconProps) => { return <FaPaperPlane  {...props} />; };
export const BdIconMigrate = ({ ...props }: BdIconProps) => { return <FaLocationArrow  {...props} />; };
export const BdIconCollaborators = ({ ...props }: BdIconProps) => { return <FaHandshake  {...props} />; };
export const BdIconInvitations = ({ ...props }: BdIconProps) => { return <FaEnvelope  {...props} />; };
export const BdIconUserFilled = ({ ...props }: BdIconProps) => { return <FaUser  {...props} />; };
export const BdIconCopy = ({ ...props }: BdIconProps) => { return <IoCopyOutline {...props} />; };
