import { ApplicationSetting } from "../../models/providers/domain/application-context/ApplicationSetting";
import { BdApiResponse, BdServiceBase, BdServiceConfig } from "../bd-service-base";
import IBdServiceApplications from "./interfaces/IBdServiceApplications";

/**
 * Application Service
 * */
export class BdServiceApplications extends BdServiceBase implements IBdServiceApplications {
    constructor(configuration: BdServiceConfig) {
        super(configuration);
    }
    private static ApiUrl = "/api/v1/apps";
    getApps(): Promise<BdApiResponse<ApplicationSetting>> {
        return super.Get(`${BdServiceApplications.ApiUrl}/`);
    }

    createApp(appSetting: ApplicationSetting): Promise<BdApiResponse<ApplicationSetting>> {
        return super.Post(`${BdServiceApplications.ApiUrl}`, appSetting);
    }

    editApp(partitionKey: string, rowKey: string, app: ApplicationSetting): Promise<BdApiResponse<ApplicationSetting>> {
        return super.Put(`${BdServiceApplications.ApiUrl}/${partitionKey}/${rowKey}`, app);
    }

    deleteApp(partitionKey: string, rowKey: string): Promise<BdApiResponse<boolean>> {
        return super.Delete(`${BdServiceApplications.ApiUrl}/${partitionKey}/${rowKey}`);
    }

    revokeApp(partitionKey: string, rowKey: string, app: ApplicationSetting): Promise<ApplicationSetting> {        
        return super.Put(`${BdServiceApplications.ApiUrl}/${partitionKey}/${rowKey}/revoke`, app);
    }
}
