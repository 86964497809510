import React from "react";
import { BdCopyClipboardIcon } from "../bd-graphics/bd-icons";
import BdButtonStyle from "./buttons.module.scss";

/**
 * Copy text content to clipboard
 * */
export interface BdCopyClipboardButtonProps extends React.HTMLAttributes<HTMLElement> {
    bdText: string
}

const BdCopyClipboardButton = ({
    bdText = ""
}: BdCopyClipboardButtonProps) => {
    const _copyToClipboardButton = (content: React.MouseEvent) => {
        try {
            navigator.clipboard.writeText(bdText);
        } catch (error) {
            //ignore
        }
    };

    return (
        <>
            <div className={BdButtonStyle.bdCopyClipboard}>
                <span>{bdText}</span>
                <BdCopyClipboardIcon
                    className={BdButtonStyle.bdCopyClipboardIcon}
                    onClick={_copyToClipboardButton} />
            </div>
        </>
    );
};

export default BdCopyClipboardButton;
