import { BdServiceBase } from "bundledocs.common.client/modules/bd-services";
import {
    BdEnterpriseLicenseBean
} from "../../models/providers/bean/bd-enterprise-license-bean/bd-enterprise-license-bean";
import { EnterpriseLicenseOptions } from "../../models/providers/enterprise-license-context/enterprise-license-options";

/**
 * Enterprise License Service
 * */
export default class BdServiceEnterpriseLicense extends BdServiceBase {
    private static ApiUrl = "/api/v1/enterprise-licenses";

    /**
     * Returns list of licences
     * @param searchField: filtering property (i.e. email, emailgroup, datetime)
     */
    Search(searchField: string): Promise<BdEnterpriseLicenseBean[]> {
        const fields = "partitionkey,rowkey,created,email,licensekey,encryptpassphrase,renewaldate,maxbriefs,maxusers,organization";

        return super.Get(BdServiceEnterpriseLicense.ApiUrl + "?$select=" + fields + searchField);
    }

    /**
     * Resend license by email or emails
     * @param partitionKey: partition key for enterprise license
     * @param rowKey: row key for Bundledocs.Data.Service.Portal license
     * @param licenseOptions: enterprise license options object
     */
    ResendEnterpriseLicense(partitionKey: string, rowKey: string, licenseOptions: EnterpriseLicenseOptions): Promise<BdEnterpriseLicenseBean> {
        return super.Post(`${BdServiceEnterpriseLicense.ApiUrl}/${partitionKey}/${rowKey}/resend-license`, licenseOptions);
    }

    /**
     * Renew license
     * @param partitionKey: partition key for enterprise license
     * @param rowKey: row key for Bundledocs.Data.Service.Portal license
     * @param licenseOptions: enterprise license options object
     */
    RenewEnterpriseLicense(partitionKey: string, rowKey: string, licenseOptions: EnterpriseLicenseOptions): Promise<BdEnterpriseLicenseBean> {
        return super.Post(`${BdServiceEnterpriseLicense.ApiUrl}/${partitionKey}/${rowKey}/renew-license`, licenseOptions);
    }

    /**
     * Create new license     
     * @param licenseOptions: enterprise license options object
     */
    CreateEnterpriseLicense(licenseOptions: EnterpriseLicenseOptions): Promise<BdEnterpriseLicenseBean> {
        return super.Post(`${BdServiceEnterpriseLicense.ApiUrl}`, licenseOptions);
    }
}
