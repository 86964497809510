/**
 * Class with common methods related with enum formatting
 * */

import StringHelper from "./string-helper";

export default class EnumHelper {

    //convert enum to a friendly name - for all enum types
    static getFriendlyName<E, K extends string>(
        enumDef: { [key in K]: E },
        enumValue: E
    ): string {
        //retrieve enum value as a string
        const enumString: string = (enumValue as unknown as K).toString();

        let resultString: string = enumString;

        if (enumString && enumString in enumDef) {
            //retrieve enum text and remove underscore
            const enumText = enumDef[enumString as K] as E;
            resultString = (enumText as unknown as K).replace("_", " ");
        }

        //convert formatted enum to title case string
        return StringHelper.toTitleCase(resultString);
    }
}
