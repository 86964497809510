import {BdDropDown, BdMenuItem, BdTable, useBdModal} from "bundledocs.common.client/modules/bd-components";
import { BdTableColumn } from "bundledocs.common.client/src/components/bd-data/bd-table";
import React, {useState} from "react";
import {
    BdEnterpriseLicenseBean
} from "../../../models/providers/bean/bd-enterprise-license-bean/bd-enterprise-license-bean";
import {BdIconEditNote, BdIconOptions} from "bundledocs.common.client/src";
import {MenuItemType} from "bundledocs.common.client/src/constants/bd-enums";
import { BdIconReceipt } from "bundledocs.common.client/modules/bd-graphics";
import {BdEnterpriseLicenseResendModal} from "../bd-enterprise-license-actions/bd-enteprise-license-resend";
import {BdEnterpriseLicenseRenewModal} from "../bd-enterprise-license-actions/bd-enteprise-license-renew";

export interface BdEnterpriseLicenseSearchListProps extends React.HTMLAttributes<HTMLElement> {
    bdEnterpriseLicences: BdEnterpriseLicenseBean[];
    renewalMaxDate: Date;
}

/**
 * Components that show a list of licenses that receives in  the props
 * @param param0
 */
export default function BdEnterpriseLicenseSearchList({
    bdEnterpriseLicences,
    renewalMaxDate = new Date(),
    ...otherProps
}: BdEnterpriseLicenseSearchListProps): JSX.Element {
    const [selectedEnterpriseLicense, setSelectedEnterpriseLicense] = useState<BdEnterpriseLicenseBean>({} as BdEnterpriseLicenseBean);
    const [licenses, setLicense] = useState<BdEnterpriseLicenseBean[]>(bdEnterpriseLicences);

    const _columns: BdTableColumn[] = [
        { header: "Created", field: "Created", typeof: Date },
        { header: "Organization", field: "Organization", typeof: String },
        { header: "Max Users", field: "MaxUsers", typeof: Number },
        { header: "Max Briefs", field: "MaxBriefs", typeof: Number },
        { header: "Renewal Date", field: "RenewalDate", typeof: Date },
        { header: "Version Number", field: "PartitionKey", typeof: String },
        { header: "License Key", field: "Licensekey", typeof: String },
        { header: "Email", field: "Email", typeof: String },
    ];

    //modal managers
    const EnterpriseLicenseResendModal = useBdModal();
    const EnterpriseLicenseRenewModal = useBdModal();

    const _handleResendEnterpriseLicense = (license: BdEnterpriseLicenseBean): void => {
        setSelectedEnterpriseLicense(license);
        EnterpriseLicenseResendModal.Show();
    };

    const _handleRenewEnterpriseLicense = (license: BdEnterpriseLicenseBean): void => {
        setSelectedEnterpriseLicense(license);
        EnterpriseLicenseRenewModal.Show();
    };

    //update list of licenses in table
    const _onEnterpriseLicenseUpdated = (license: BdEnterpriseLicenseBean) => {
        // insert new value in front of list of licenses for correct displaying
        bdEnterpriseLicences.unshift(license);
        setLicense(bdEnterpriseLicences);
        setSelectedEnterpriseLicense({} as BdEnterpriseLicenseBean);
    };


    //options menu
    const _getLicenseActions = (license: BdEnterpriseLicenseBean) => {
        return (
            <BdDropDown bdTitle={<BdIconOptions size={18} style={{ marginBottom: "0.2rem" }} />}
                bdShowCaret={false} bdId={"licenseAction_" + license.RowKey}>
                <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => _handleResendEnterpriseLicense(license)}
                    bdIcon={<BdIconReceipt />} bdText="Resend enterprise license" />
                <BdMenuItem bdType={MenuItemType.Entry} onSelect={() => _handleRenewEnterpriseLicense(license)}
                    bdIcon={<BdIconEditNote />} bdText="Renew enterprise license" />
            </BdDropDown>
        );
    };

    return (
        <>
            <BdTable {...otherProps} bdColumns={_columns} bdItems={licenses}
                onActions={_getLicenseActions} />
            <BdEnterpriseLicenseResendModal {...EnterpriseLicenseResendModal.BdModalProps}
                bdLicense={selectedEnterpriseLicense}></BdEnterpriseLicenseResendModal>
            <BdEnterpriseLicenseRenewModal {...EnterpriseLicenseRenewModal.BdModalProps}
                onUpdate={_onEnterpriseLicenseUpdated}
                bdLicense={selectedEnterpriseLicense}
                renewalMaxDate={renewalMaxDate}></BdEnterpriseLicenseRenewModal>
        </>
    );
}
