import axios, { AxiosRequestConfig } from "axios";
import StringHelper from "../helpers/string-helper";

/**
 * Options to configure the requests
 **/
export interface BdServiceConfig {
    //base configuration
    BaseUrl?: string,
    //base configuration
    BlobsUrl?: string,
    //authentication
    AccessToken?: string,
    WithCredentials?: boolean,
    //error handler, run when a request fails
    requestErrorHandler?: (error: any) => void
}

export interface BdApiResponse<T> {
    data: T[]
}

/**
 * Services base class, it provides REST API requests
 * */
export abstract class BdServiceBase {
    //configuration
    private Configuration: BdServiceConfig = {
        BaseUrl: "",
        BlobsUrl: ""
    };

    constructor(configuration: BdServiceConfig) {
        this.Configuration = configuration;
    }

    //update the requests configuration
    public UpdadateConfiguration(configuration: BdServiceConfig) {
        this.Configuration = {
            ...this.Configuration,
            ...configuration
        };
    }

    //request configuration
    private GetConfig(responseType?: string): AxiosRequestConfig<any> {
        const config: AxiosRequestConfig<any> = {};

        if (this.Configuration.AccessToken) {
            config.headers = { Authorization: `Bearer ${this.Configuration.AccessToken}` };
        }

        if (this.Configuration.WithCredentials) {
            config.withCredentials = true;
        }

        if (responseType) {
            if (responseType === "blob") {
                config.responseType = "blob";
            }                   
        }

        return config;
    }   

    /**
    * Get request
    * @param url
    */
    protected async Get(url: string): Promise<any> {
        try {
            const response = await axios.get(this.Configuration.BaseUrl + url, this.GetConfig());
            return response.data;
        }
        catch (error) {
            this.HandleError(error);
        }
    }

    /**
     * Get blob request
     * @param url
     */
    protected async GetBlob(url: string): Promise<any> {
        try {
            const response = await axios.get(this.Configuration.BaseUrl + url, this.GetConfig("blob"));
            return response.data;
        }
        catch (error) {
            this.HandleError(error);
        }
    }

    /**
     * Post request
     * @param url
     * @param createOptions
     */
    protected async Post(url: string, createOptions?: any): Promise<any> {
        try {
            const response = await axios.post(this.Configuration.BaseUrl + url, createOptions, this.GetConfig());
            return response.data;
        }
        catch (error) {
            this.HandleError(error);
        }
    }

    /**
     * Post request
     * @param url
     * @param createOptions
     */
    protected async Upload(url: string, file: File, uploadOptions?: FormData): Promise<any> {
        try {
            if (!uploadOptions) {
                uploadOptions = new FormData();
            }
            uploadOptions.append("file", file);
            uploadOptions.append("FileUploadBatch", StringHelper.newGuid());
            uploadOptions.append("FileUploadKey", StringHelper.newGuid());
            uploadOptions.append("FileUploadDate", new Date(file.lastModified).toString());

            const _config = this.GetConfig();
            _config.headers!["Content-Type"] = "multipart/form-data";

            const response = await axios.post(this.Configuration.BlobsUrl + url, uploadOptions, this.GetConfig());
            
            return response.data;
        }
        catch (error) {
            this.HandleError(error);
        }
    }

    /**
     * Put request
     * @param url
     * @param updateOptions
     */
    protected async Put(url: string, updateOptions?: any): Promise<any> {
        try {
            const response = await axios.put(this.Configuration.BaseUrl + url, updateOptions, this.GetConfig());
            return response.data;
        }
        catch (error) {
            this.HandleError(error);
        }
    }

    /**
     * Put request
     * @param url
     * @param patchOptions
     */
    protected async Patch(url: string, patchOptions?: any): Promise<any> {
        try {
            const response = await axios.patch(this.Configuration.BaseUrl + url, patchOptions, this.GetConfig());
            return response.data;
        }
        catch (error) {
            this.HandleError(error);
        }
    }

    /**
     * Delete request
     * @param url
     */
    protected async Delete(url: string): Promise<any> {
        try {
            const response = await axios.delete(this.Configuration.BaseUrl + url, this.GetConfig());
            return response.data;
        }
        catch (error) {
            this.HandleError(error);
        }
    }

    /**
     * Common error handling operations
     * @param error
     */
    private HandleError(error: any) {
        if (this.Configuration.requestErrorHandler) {
            this.Configuration.requestErrorHandler(error);
        }
        throw error;
    }
}
