import { useCallback, useEffect, useRef } from "react";
import { create } from "zustand";
import { ActionEvent } from "../../modules/bd-models";
import BdEventsHelper, { BdBriefEventTypes, BdDocumentEventTypes } from "../helpers/bd-events-helper";
import StringHelper from "../helpers/string-helper";
import IBdServiceEvents from "../services/core-api/interfaces/IBdServiceEvents";

interface IEventsManagerTimer {
    id: string;
    timer: NodeJS.Timer;
}

interface IEventsManagerStore {
    timers: IEventsManagerTimer[];
    addTimer: (eventTimer: IEventsManagerTimer) => void;
    removeTimer: (id: string) => void;
    subscribers: BdEventSubscriber[];
    addSubscriber: (subscriber: BdEventSubscriber) => void;
    removeSubscriber: (id: string) => void;
    removeSubscribersByListenerId: (listenerId: string) => void;
}

//Zustand for global state management
const useEventsManagerStore = create<IEventsManagerStore>((set: any) => ({
    timers: [],
    addTimer: (eventTimer: IEventsManagerTimer) => set((state: IEventsManagerStore) => {
        return (state.timers.some(t => t.id === eventTimer.id))
            ? state
            : { timers: [...state.timers, eventTimer] };
    }),
    removeTimer: (id: string) => set((state: IEventsManagerStore) => {
        return (!state.timers.some(t => t.id === id))
            ? state
            : { timers: state.timers.filter(t => t.id !== id) };
    }),
    subscribers: [],
    addSubscriber: (subscriber: BdEventSubscriber) => set((state: IEventsManagerStore) => ({ subscribers: [...state.subscribers, subscriber] })),
    removeSubscriber: (id: string) => set((state: IEventsManagerStore) => {
        return (state.subscribers.some(s => s.id === id))
            ? state
            : { subscribers: state.subscribers.filter(s => s.id !== id) };
    }),
    removeSubscribersByListenerId: (listenerId: string) => set((state: IEventsManagerStore) => {
        return (!state.subscribers.some(s => s.eventListenerId === listenerId))
            ? state
            : { subscribers: state.subscribers.filter(s => s.eventListenerId !== listenerId) };
    }),
}));

export interface useBdEventsManagerResult {
    startBriefEventsListener: (context: string, batch: string, type: BdBriefEventTypes,
        onBegin?: (begin?: ActionEvent) => void,
        onProgress?: (progress?: ActionEvent) => void,
        onSuccess?: (success?: ActionEvent) => void,
        onFailure?: (failure?: ActionEvent) => void
    ) => void;
    startBriefDocumentEventsListener: (context: string, batch: string, type: BdDocumentEventTypes,
        onBegin?: (begin?: ActionEvent) => void,
        onSuccess?: (success?: ActionEvent) => void,
        onFailure?: (failure?: ActionEvent) => void) => void;
}

export interface BdEventSubscriber {
    id: string;
    eventListenerId: string;
    onBegin?: (begin?: ActionEvent) => void,
    onProgress?: (progress?: ActionEvent) => void,
    onSuccess?: (success?: ActionEvent) => void,
    onFailure?: (failure?: ActionEvent) => void
}

export const useBdEventsManager = (bdServiceEvents?: IBdServiceEvents): useBdEventsManagerResult => {

    const eventTimers = useEventsManagerStore((state: IEventsManagerStore) => state.timers);
    const eventTimersRef = useRef<IEventsManagerTimer[]>(eventTimers);
    useEffect(() => {
        eventTimersRef.current = eventTimers;
    }, [eventTimers]);

    const subscribers = useEventsManagerStore((state: IEventsManagerStore) => state.subscribers);
    const subscribersRef = useRef<BdEventSubscriber[]>(subscribers);
    useEffect(() => {
        subscribersRef.current = subscribers;
    }, [subscribers]);

    const addSubscriber = useEventsManagerStore((state: IEventsManagerStore) => state.addSubscriber);
    const removeSubscriber = useEventsManagerStore((state: IEventsManagerStore) => state.removeSubscriber);
    const removeSubscribersByListenerId = useEventsManagerStore((state: IEventsManagerStore) => state.removeSubscribersByListenerId);
    const addTimer = useEventsManagerStore((state: IEventsManagerStore) => state.addTimer);
    const removeTimer = useEventsManagerStore((state: IEventsManagerStore) => state.removeTimer);

    //return an unique id based on a context and batch
    const _getEventListenerId = (context: string, batch: string) => `${context}---${batch ?? ""}`;

    /** ============== notify events ============== */
    const _notifySubscribersBegin = (context: string, batch: string, begin: ActionEvent) => {
        const eventListenerId = _getEventListenerId(context, batch);
        const subscribersToNotify = subscribersRef.current.filter(s => s.eventListenerId === eventListenerId && s.onBegin);
        if (subscribersToNotify?.length > 0) {
            for (let i = 0; i < subscribersToNotify.length; i++) {
                subscribersToNotify[i].onBegin?.(begin);
            }
        }
    };

    const _notifySubscribersProgress = (context: string, batch: string, progress: ActionEvent) => {
        const eventListenerId = _getEventListenerId(context, batch);
        const subscribersToNotify = subscribersRef.current.filter(s => s.eventListenerId === eventListenerId && s.onProgress);
        if (subscribersToNotify?.length > 0) {
            for (let i = 0; i < subscribersToNotify.length; i++) {
                subscribersToNotify[i].onProgress?.(progress);
            }
        }
    };

    const _notifySubscribersSuccess = (context: string, batch: string, success: ActionEvent) => {
        const eventListenerId = _getEventListenerId(context, batch);
        const subscribersToNotify = subscribersRef.current.filter(s => s.eventListenerId === eventListenerId && s.onSuccess);
        if (subscribersToNotify?.length > 0) {
            for (let i = 0; i < subscribersToNotify.length; i++) {
                subscribersToNotify[i].onSuccess?.(success);
            }
        }
        _stopListener(context, batch);
    };

    const _notifySubscribersFailure = (context: string, batch: string, failure: ActionEvent) => {
        const eventListenerId = _getEventListenerId(context, batch);
        const subscribersToNotify = subscribersRef.current.filter(s => s.eventListenerId === eventListenerId && s.onFailure);
        if (subscribersToNotify?.length > 0) {
            for (let i = 0; i < subscribersToNotify.length; i++) {
                subscribersToNotify[i].onFailure?.(failure);
            }
        }
        _stopListener(context, batch);
    };
    /** =========================================== */

    //stop pooling events for a context and a batch
    const _stopListener = useCallback((context: string, batch: string) => {
        const timer = eventTimersRef.current.find(t => t.id === _getEventListenerId(context, batch));
        if (timer) {
            clearInterval(timer.timer);
            removeTimer(timer.id);
            removeSubscribersByListenerId(timer.id);
        }
    }, []);

    //add a subscriber to the subscriber list
    const _subscribe = useCallback((context: string, batch: string,
        onBegin?: (begin?: ActionEvent) => void,
        onProgress?: (progress?: ActionEvent) => void,
        onSuccess?: (success?: ActionEvent) => void,
        onFailure?: (failure?: ActionEvent) => void
    ) => {
        const subscriberId: string = StringHelper.newGuid();
        addSubscriber({
            id: subscriberId, eventListenerId: _getEventListenerId(context, batch),
            onBegin: onBegin, onProgress: onProgress, onSuccess: onSuccess, onFailure: onFailure
        });
        return subscriberId;
    }, [addSubscriber]);

    //start listening and/or subscribe to brief events
    const startBriefEventsListener = useCallback((context: string, batch: string, type: BdBriefEventTypes,
        onBegin?: (begin?: ActionEvent) => void,
        onProgress?: (progress?: ActionEvent) => void,
        onSuccess?: (success?: ActionEvent) => void,
        onFailure?: (failure?: ActionEvent) => void
    ) => {
        if (!bdServiceEvents) {
            return "";
        }

        const eventListenerId = _getEventListenerId(context, batch);

        if (onBegin || onProgress || onSuccess || onFailure) {
            _subscribe(context, batch, onBegin, onProgress, onSuccess, onFailure);
        }

        if (!eventTimersRef.current.find(t => t.id === eventListenerId)) {
            const timer = BdEventsHelper.startBriefActionListener(bdServiceEvents, context, batch, type,
                (begin) => _notifySubscribersBegin(context, batch, begin),
                (progress) => _notifySubscribersProgress(context, batch, progress),
                (success) => _notifySubscribersSuccess(context, batch, success),
                (failure) => _notifySubscribersFailure(context, batch, failure)
            );
            if (timer) {
                addTimer({ id: eventListenerId, timer: timer });
            }
        }
    }, [bdServiceEvents]);

    //start listening and/or subscribe to brief documents events
    const startBriefDocumentEventsListener = useCallback((context: string, batch: string, type: BdDocumentEventTypes,
        onBegin?: (begin?: ActionEvent) => void,
        onSuccess?: (success?: ActionEvent) => void,
        onFailure?: (failure?: ActionEvent) => void
    ) => {
        if (!bdServiceEvents) {
            return "";
        }

        const eventListenerId = _getEventListenerId(context, batch);

        let subscriberId = "";
        if (onBegin || onSuccess || onFailure) {
            subscriberId = _subscribe(context, batch, onBegin, undefined, onSuccess, onFailure);
        }

        if (!eventTimersRef.current.find(t => t.id === eventListenerId)) {
            try {
                const timer = BdEventsHelper.startDocumentEventsListener(bdServiceEvents!, context, batch, type,
                    (begin) => _notifySubscribersBegin(context, batch, begin),
                    (success) => _notifySubscribersSuccess(context, batch, success),
                    (failure) => _notifySubscribersFailure(context, batch, failure)
                );
                if (timer) {
                    addTimer({ id: eventListenerId, timer: timer });
                }
            }
            catch {
                if (subscriberId) {
                    removeSubscriber(subscriberId);
                    subscriberId = "";
                }
            }
        }

        return subscriberId;
    }, [bdServiceEvents]);

    return {
        startBriefEventsListener,
        startBriefDocumentEventsListener
    };
};
